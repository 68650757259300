import React, { useEffect, useState } from "react";
import "./Sendrequestjeweller.css";
import axios from "../../API";
import { Backdrop, CircularProgress } from "@mui/material";
import closeicon from "../../Assets/Images/closearrow.png";
import { toast } from "react-toastify";
import logo from "../../Assets/Logos/logo.png";

const Sendrequestjeweller = ({ setshow, setmainshow }) => {
  const [Loading, setLoading] = useState(false);
  const [load, setload] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [selectdata, setselectdata] = useState([]);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const getmystore = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/get-all-store",
        {
          Keyword: "",
        },
        {
          headers: {
            authkey: `Barear ${token.Token}`,
          },
        }
      );
      if (res.data.message === "Record Found.") {
        setstoredata(res.data.data);
      }
      setLoading(false);
      //   console.log(res);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getmystore();
  }, []);

  const data = JSON.parse(localStorage.getItem("redux"));

  const imgs = data[1];
  const name = data[0].ItemName;
  const Description = data[0].Description;
  const [newimg, setnewimg] = useState([]);

  useEffect(() => {
    setnewimg(imgs);
  }, []);

  const handlesubmit = async (e) => {
    e.preventDefault();
    let array = "";
    let storeid = "";
    selectdata.map((i) => {
      if (storeid === "") {
        storeid = storeid + i.Id;
      } else {
        storeid = storeid + "," + i.Id;
      }
    });
    if (storeid === "") {
      toast.error("Please select a store", {
        position: "top-right",
        theme: "colored",
      });
    } else {
      setload(true);
      try {
        const response = await axios.post(
          "/create-customer-workboard",
          {
            Type: "SendToStore",
            Title: name,
            Description: Description,
            Images: newimg.toString(),
            StoreId: storeid,
            Latitude: localStorage.getItem("lat"),
            Longitude: localStorage.getItem("lon"),
          },
          {
            headers: {
              Content_type: "application/json",
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        // console.log(response);
        if (response.data.message === "WorkBoard Request Send Successfully") {
          // setshow(false)
          setmainshow(false);
        }
        setload(false);
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } catch (error) {
        setload(false);
        console.log(error);
      }
    }
  };

  const handleadd = (item) => {
    if (!selectdata.includes(item)) {
      setselectdata((name) => [...name, item]);
    }
  };

  const handledelete = (indexx) => {
    setselectdata(
      selectdata.filter((item) => {
        return item.Id !== indexx;
      })
    );
  };

  return (
    <>
      <form onSubmit={handlesubmit} className="sendrequestdiv">
        <div className="searchjeweller">
          <input type="text" placeholder="search" />
        </div>
        <div className="mainstoregetdiv">
          {selectdata.map((item, indexx) => {
            const imageUrls = item.Images.split(",");
            const firstImageUrl = imageUrls[0];
            return (
              <div className="parentofstorecard2">
                <div onClick={() => handledelete(item.Id)}>
                  <img src={closeicon} alt="" />
                </div>
                <div className="storegetdiv" key={indexx}>
                  <div className="storeimgdiv">
                    <img src={firstImageUrl} alt="" />
                  </div>
                  <div className="storetext">
                    <h4>{item.Name}</h4>
                    <p>{item.Address}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="mainstoregetdiv">
          {Loading ? (
            <div className="">
              <CircularProgress />
            </div>
          ) : (
            <>
              {storedata.map((item, index) => {
                const imageUrls = item.Images.split(",");
                const firstImageUrl = imageUrls[0];
                return (
                  <div
                    className="storegetdiv"
                    key={index}
                    onClick={() => handleadd(item)}
                  >
                    <div className="storeimgdiv">
                      <img src={firstImageUrl} alt="" />
                    </div>
                    <div className="storetext">
                      <h4>{item.Name}</h4>
                      <p>{item.Address}</p>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
        <button className={"sendbtn"} type="submit">
          Send
        </button>
      </form>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Sendrequestjeweller;
