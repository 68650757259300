import React from "react";
import Container from "../../Components/Container/Container";
import "./MyWallet.css";
import mywalletimg from "../../Assets/Images/mywalletimg.png";
import searchicon from "../../Assets/Icons/search.png";
import handbracelet from "../../Assets/Images/handbracelet1.png";
import handbracelet2 from "../../Assets/Images/handbracelet2.png";
import handbracelet3 from "../../Assets/Images/handbracelet3.png";
import handbracelet4 from "../../Assets/Images/handbracelet4.png";

const MyWallet = () => {
  const data = [
    {
      img: handbracelet,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
    {
      img: handbracelet2,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
    {
      img: handbracelet3,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
    {
      img: handbracelet4,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
    {
      img: handbracelet,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
    {
      img: handbracelet,
      head: <h4>Hand <br /> Bracelete</h4>,
      date: "22 Jan 2023",
      price: "$ 345",
    },
  ];

  return (
    <>
    
        <div className="mywalletmaindiv">
          <h3>My Wallet</h3>
          <div className="mywalletdiv">
            <div className="mywalletleft">
              <div className="totalsellsdiv">
                <div className="totalsells">
                  <h3>$ 10K</h3>
                  <span>Total Sell</span>
                </div>
                <div className="mywalletline"></div>
                <div className="transfered">
                  <h3>$ 5,857</h3>
                  <span>Transfered</span>
                </div>
              </div>
              <button className="mywalletbtn">Transfer to Bank Account</button>
            </div>
            <div className="mywalletright">
              <img src={mywalletimg} alt="" />
            </div>
          </div>
          <div className="sellhistory">
            <h3>Sell History</h3>
            <img src={searchicon} alt="" />
          </div>
          <div className="mywallethistory">
            <h4>Products</h4>
            <h4>Date</h4>
            <h4>Price</h4>
          </div>
          <div className="mywalletmainhistory">
            {data.map((item, index) => {
              return (
                <div key={index}>
                  <div className="maindivhistory" >
                    <div className="headingwallet">
                      <img src={item.img} alt="" />
                      {item.head}
                    </div>
                    <span>{item.date}</span>
                    <span>{item.price}</span>
                  </div>
                  <div className="historyline"></div>
                </div>
              );
            })}
          </div>
        </div>
  
    </>
  );
};

export default MyWallet;
