import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import AddAddress from "../../Pages/AddAddress/AddAddress";

const StripeContainer2 = () => {
  // const publickey = process.env.REACT_APP_PUBLIC_KEY;
  const stripepromise = loadStripe(
    "pk_test_51KVyV0AJ9kDiPelhQATfYgez7QYawfNdfeZqusSDTgT3ay4yvkJe6xmSP0SfckSW2EOcIOXgFClgoqc28eVyEco600Zr5672lu"
  );
  return (
    <>
      <Elements stripe={stripepromise}>
        <AddAddress/>
      </Elements>
    </>
  );
};

export default StripeContainer2;
