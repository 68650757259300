import React, { useState } from "react";
import RangeSlider from "../../Slider/RangeSlider";
import Checkbox from "../Checkbox/Checkbox";
import "./FilterModel.css";
import Button from "../../Button/Button";
import { useEffect } from "react";
import { useFormik } from "formik";
import axios from "../../../API";
import { Backdrop, CircularProgress } from "@mui/material";

const FilterModel = ({ setopen }) => {
  const [loading, setlaoding] = useState(false);
  const [max, setMax] = useState(10000);
  const [values, setValues] = useState({
    priceRange: [10, max],
  });
  const data = JSON.parse(localStorage.getItem("enpralData"));
  // weight data..........
  const [selectedItems, setSelectedItems] = useState([]);
  const [filterdata, setFilterdata] = useState([
    { id: 1, weight: ["2 grams", "4 grams"], checked: false },
    { id: 2, weight: ["4 grams", "6 grams"], checked: false },
    { id: 3, weight: ["6 grams", "8 grams"], checked: false },
    { id: 4, weight: ["8 grams", "10 grams"], checked: false },
  ]);
  // MaterialType data..................
  const [selectedItems2, setSelectedItems2] = useState([]);
  const [filterdata2, setFilterdata2] = useState([
    { id: 1, MaterialType: "Gold", checked: false },
    { id: 2, MaterialType: "Diamond", checked: false },
  ]);
  // ProductType data....................
  const [selectedItems3, setSelectedItems3] = useState([]);
  const [filterdata3, setFilterdata3] = useState([
    { id: 1, ProductType: "Kada", checked: false },
    { id: 2, ProductType: "Bracelet", checked: false },
  ]);
  // styling data........................
  const [selectedItems4, setSelectedItems4] = useState([]);
  const [filterdata4, setFilterdata4] = useState([
    { id: 1, Styling: "Traditional", checked: false },
    { id: 2, Styling: "Fashion Wear", checked: false },
  ]);

  // all handlechanges.........................
  const handleCheckboxChange = (index) => {
    setFilterdata((prevData) => {
      const newData = prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      });
      return newData;
    });
  };
  const handleCheckboxChange2 = (index) => {
    setFilterdata2((prevData) => {
      const newData = prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      });
      return newData;
    });
  };
  const handleCheckboxChange3 = (index) => {
    setFilterdata3((prevData) => {
      const newData = prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      });
      return newData;
    });
  };
  const handleCheckboxChange4 = (index) => {
    setFilterdata4((prevData) => {
      const newData = prevData.map((item, i) => {
        if (i === index) {
          return {
            ...item,
            checked: !item.checked,
          };
        }
        return item;
      });
      return newData;
    });
  };
  // all handlechanges end..............................

  useEffect(() => {
    const selected = filterdata
      .filter((item) => item.checked)
      .map((item) => item.weight);
    setSelectedItems(selected);
  }, [filterdata]);

  useEffect(() => {
    const selected = filterdata2
      .filter((item) => item.checked)
      .map((item) => item.MaterialType);
    setSelectedItems2(selected);
  }, [filterdata2]);

  useEffect(() => {
    const selected = filterdata3
      .filter((item) => item.checked)
      .map((item) => item.ProductType);
    setSelectedItems3(selected);
  }, [filterdata3]);

  useEffect(() => {
    const selected = filterdata4
      .filter((item) => item.checked)
      .map((item) => item.Styling);
    setSelectedItems4(selected);
  }, [filterdata4]);

  const minWeights = selectedItems
    ?.filter((item) => typeof item === "string")
    ?.map((item) => {
      const weightParts = item.split(" - ");
      return weightParts[0];
    });

  const maxWeights = selectedItems
    ?.filter((item) => typeof item === "string")
    ?.map((item) => {
      const weightParts = item.split(" - ");
      return weightParts[1];
    });

  const onSubmit = async () => {
    setlaoding(true);
    try {
      const res = await axios.post(
        "/all-product-list",
        {
          CategoryId: "1",
          StoreId: "",
          PageId: "1",
          PerPage: "4",
          ByMaterial: selectedItems2.toString(),
          MinPrice: values?.priceRange[0],
          MaxPrice: values?.priceRange[1],
          ProductType: selectedItems3.toString(),
          Styling: selectedItems4,
          MinWeight: minWeights,
          MaxWeight: maxWeights,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(res);
      setopen(false);
      setlaoding(false);
    } catch (error) {
      console.log(error);
      setlaoding(false);
    }
  };

  const initialValues = {};

  const { handleSubmit } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="filtermodel">
        <RangeSlider setValues={setValues} values={values} max={max} />
        <div className="weight">
          <span>Weight Range</span>
          <div className="checkboxxx">
            {filterdata.map((curelem, index) => {
              return (
                <div className="checkhai" key={curelem.id}>
                  <Checkbox
                    checked={curelem.checked}
                    onChange={() => handleCheckboxChange(index)}
                  />
                  <p>
                    {curelem.weight[0]} - {curelem.weight[1]}
                  </p>
                </div>
              );
            })}
          </div>
          <span>Product Type</span>
          <div className="checkboxxx">
            {filterdata3.map((curelem, index) => {
              return (
                <div className="checkhai" key={curelem.id}>
                  <Checkbox
                    checked={curelem.checked}
                    onChange={() => handleCheckboxChange3(index)}
                  />
                  <p>{curelem.ProductType}</p>
                </div>
              );
            })}
          </div>
          <span>Material Type</span>
          <div className="checkboxxx">
            {filterdata2.map((curelem, index) => {
              return (
                <div className="checkhai" key={curelem.id}>
                  <Checkbox
                    checked={curelem.checked}
                    onChange={() => handleCheckboxChange2(index)}
                  />
                  <p>{curelem.MaterialType}</p>
                </div>
              );
            })}
          </div>
          <span>Styling</span>
          <div className="checkboxxx">
            {filterdata4.map((curelem, index) => {
              return (
                <div className="checkhai" key={curelem.id}>
                  <Checkbox
                    checked={curelem.checked}
                    onChange={() => handleCheckboxChange4(index)}
                  />
                  <p>{curelem.Styling}</p>
                </div>
              );
            })}
          </div>
          <Button className={"savebtn2"} type={"submit"}>
            Apply
          </Button>
        </div>
        {!loading && <div></div>}
        {loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </form>
    </>
  );
};

export default FilterModel;
