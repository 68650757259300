import axios from "../../API";
import React, { useEffect, useState } from "react";
import "./Favourites.css";
import { CircularProgress } from "@mui/material";

const Favourites = () => {
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const [message, setmessage] = useState("");
  const [prodata, setprodata] = useState([]);
  const [storesdata, setstoresdata] = useState([]);
  const [select, setselect] = useState(0);
  const [Loading, setLoading] = useState(false);

  const btns = [
    {
      id: 1,
      name: "Product",
    },
    {
      id: 2,
      name: "Store",
    },
  ];

  // get favourite products............
  const getProducts = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/my-favourite-products", {
        headers: {
          authkey: `Barear ${data.Token}`,
        },
      });
      // console.log(res)
      setLoading(false);
      if (res.data.message === "No Record Found.") {
        setmessage("You have No Favourite Product..");
      }
      setprodata(res.data.data);
    } catch (error) {
      // console.log(error)
      setLoading(false);
    }
  };
  // get favourite stores.............
  const getStores = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-favourite-store-list", {
        headers: {
          authkey: `Barear ${data.Token}`,
        },
      });
      // console.log(res)
      setLoading(false);
      if (res.data.message === "No Record Found.") {
        setmessage("You have No Favourite Product..");
      }
      setstoresdata(res.data.data);
    } catch (error) {
      // console.log(error)
      setLoading(false);
    }
  };

  useEffect(() => {
    getProducts();
    getStores();
  }, []);

  const handleclick = (id) => {};

  return (
    <>
      <div className="favourite">
        <h3>Favourites</h3>
        <div className="favdata">
          {btns.map((item, index) => {
            return (
              <button
                className={select === index ? "btnactive" : ""}
                key={item.id}
                onClick={() => setselect(index)}
              >
                {item.name}
              </button>
            );
          })}
        </div>
        {Loading ? (
          <div className="circilardiv">
            <CircularProgress />
          </div>
        ) : (
          <div className="favouriteconte">
            <h2>{message}</h2>
            <div className="myproductcarddiv">
              {select === 0 ? (
                <>
                  {prodata?.map((item, index) => {
                    return (
                      <div
                        className="myproductcard"
                        key={index}
                        onClick={() => handleclick(item)}
                      >
                        <div className="mystoreimgdiv">
                          <img src={item.Images} alt="" />
                        </div>
                        <div className="mystoreheading2">
                          <div className="mystoreloca">
                            <h4>{item.Title}</h4>
                            <span>
                              {item.Currency}
                              {item.Price}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <>
                  {storesdata?.map((item, index) => {
                    {
                      /* console.log(item) */
                    }
                    return (
                      <div
                        className="myproductcard"
                        key={index}
                        onClick={() => handleclick(item)}
                      >
                        <div className="mystoreimgdiv">
                          <img src={item.StoreImage} alt="" />
                        </div>
                        <div className="mystoreheading2">
                          <div className="mystoreloca">
                            <h4>{item.Title}</h4>
                            <span>{item.Distance} km</span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Favourites;
