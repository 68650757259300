import React, { useEffect, useState } from "react";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import "./Notification.css";
// import { Notificationdata } from "../../Components/Data/Notificationdata";
import Container from "../../Components/Container/Container";
import nullimg from "../../Assets/Icons/usernullimage.png";
import axios from "../../API";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Notification = () => {
  const [notidata, setnotidata] = useState([]);
  const [loading, setloading] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const getnotificationlist = async () => {
    setloading(true);
    try {
      const response = await axios.get("/notification-list", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      console.log(response);
      setnotidata(response.data.data);
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getnotificationlist();
  }, []);

  const navigate = useNavigate();
  const handleaccept = (item) => {
    navigate("/CompleteOrder", {
      state: {
        item,
      },
    });
  };

  const Rejectorder = async (item) => {
    // console.log(item)
    setloading(true);
    try {
      const res = await axios.post(
        "/reject-customize-order",
        {
          OrderId: item.ModuleId,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      if (res.data.status === "success") {
        toast.success(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else {
        toast.error(res.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setloading(false);
      // console.log(res)
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <>
      <NavbarSecond />
      <Container>
        <div className="notificationdivmain">
          <h3>Notification</h3>
          <div className="Notificationdiv">
            {notidata?.map((item, index) => {
              return (
                <div className="mainboxnoti" key={index}>
                  <div>
                    <div className="notiavtar">
                      <img
                        src={item.UserPic === null ? nullimg : item.UserPic}
                        alt=""
                      />
                    </div>
                    <div className="mainboxcontent">
                      <div>
                        <h4>{item.UserName}</h4>
                        <p>{item.Message}</p>
                      </div>
                      <span>{item.CreatAt}</span>
                      {item.Button_Show === "TRUE" && (
                        <div className="RAbtns">
                          <button onClick={() => Rejectorder(item)}>
                            Reject
                          </button>
                          <button onClick={() => handleaccept(item)}>
                            Accept
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container>
      {!loading && <div></div>}
      {loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Notification;
