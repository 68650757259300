import React from "react";
import Wrapper, { WrapperS } from "../../Components/Wrapper/Wrapper";
import "./MainPage.css";
import Container from '../../Components/Container/Container'
import loginlogo from '../../Assets/Logos/loginlogo.png'

const MainPage = ({img, children, heading, pra}) => {
  return (
    <div className="mainpagediv">
      <Wrapper>
      <Container>
        <div className="mainauth">
          <div className="left">
            <img src={img} alt="" />
          </div>
          <div className="right">
              <img src={loginlogo} alt="" />
              <div className="forgotp">
              <span>{heading}</span>
              <p>{pra}</p>
              </div>
             {children}
          </div>
        </div>
      </Container>
      </Wrapper>
    </div>
  );
};

export default MainPage;

export const MainPage2 = ({img, children, heading, pra}) => {
    return(
      <div className="mainpagediv">
      <WrapperS>
      <Container>
        <div className="mainauth2">
          <div className="left2">
            <img src={img} alt="" />
          </div>
          <div className="right2">
              <img src={loginlogo} alt="" />
              <div className="forgotp">
              <span>{heading}</span>
              <p>{pra}</p>
              </div>
             {children}
          </div>
        </div>
      </Container>
      </WrapperS>
    </div>
    )
}
