import React, { useEffect, useState } from "react";
import "./Mystore.css";
import editicon from "../../Assets/Icons/editiconblack.png";
import deleteicon from "../../Assets/Icons/deleteicon.png";
import locaicon from "../../Assets/Icons/locationicon.png";
import axios from "../../API";
import { CircularProgress, Skeleton } from "@mui/material";
import { toast } from "react-toastify";
import plusicon from "../../Assets/Icons/plusicon.png";
import CreateStore from "./CreateStore";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import checked from "../../Assets/Icons/checked.png";
import { useFormik } from "formik";
import EditStore from "./EditStore";

const Mystore = () => {
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const [loadd, setloadd] = useState(false);
  const [editshow, seteditshow] = useState(false);
  const [load, setload] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [editstoredata, seteditstoredata] = useState(null);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const initialValues = {
    Defaultstore: "",
  };

  const { values, handleChange, handleBlur } = useFormik({
    initialValues: initialValues,
  });
  // getstore API..................
  const getmystore = async () => {
    setload(true);
    // setLoading(true);
    try {
      const res = await axios.get("/get-my-store", {
        headers: {
          authkey: `Barear ${token.Token}`,
        },
      });
      if (res.data.message === "Record Found.") {
        setstoredata(res.data.data);
      }
      setLoading(false);
      setload(false);
      if (res.data.message === "No Record Found.") {
        // seterror("You have No Store Yet")
        // setshow(true)
        setLoading(false);
      } else {
        // setshow(false)
      }
      // console.log(res, "hhjkjh");
    } catch (error) {
      console.log(error);
      // setLoading(false);
      setload(false);
    }
  };
  useEffect(() => {
    getmystore();
  }, []);

  // change default store api

  const Changedefaultstore = async (item) => {
    // setloadd(true);
    setLoading2(true);
    try {
      const resposne = await axios.post(
        "/change-default-store",
        {
          StoreId: item.Id,
        },
        {
          headers: {
            authkey: `Barear ${token.Token}`,
          },
        }
      );
      setLoading2(false);
      if (resposne.data.message === "Store Changed Successfully") {
        getmystore();
        toast.success(resposne.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setloadd(false);
      // console.log(resposne);
    } catch (error) {
      console.log(error);
      setloadd(false);
      setLoading2(false);
    }
  };

  // Delete store API...........
  const handledelete = async (item, index) => {
    try {
      const response = await axios.post(
        "/delete-store",
        {
          StoreId: item.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      if (response.data.message === "Store Deleted Successfully") {
        setstoredata(storedata.filter((i) => i !== index));
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const defaultItem = storedata.find((_item) => _item.IsDefault === "Yes");

  const handleedit = (item) => {
    seteditstoredata(item);
    seteditshow(true);
  };

  return (
    <>
      {editshow ? (
        <EditStore seteditshow={seteditshow} editstoredata={editstoredata} />
      ) : (
        <>
          {show ? (
            <CreateStore setshow={setshow} />
          ) : (
            <div className="sidebarmystore">
              <div className="mystoredivtop">
                <h3>My Stores</h3>
                <div onClick={() => setshow(true)}>
                  <img src={plusicon} alt="" />
                  <span>Create Store</span>
                </div>
              </div>
              {load ? (
                <div className="manloderdiv">
                  <CircularProgress />
                </div>
              ) : (
                <div
                  className="mystorecarddiv"
                  style={{
                    height: `${window.innerHeight - 50}px`,
                    overflow: "hidden",
                  }}
                >
                  {storedata.map((item, index) => {
                    return (
                      <div key={index}>
                        {Loading ? (
                          <div className="loadindiv">
                            <Skeleton variant="rounded" />
                          </div>
                        ) : (
                          <div className="mystorecard">
                            <div className="mystoreimgdiv">
                              <img src={item.Images} alt="" />
                            </div>
                            <div className="mystoreheading">
                              <div className="mystoreloca">
                                <h4>{item.Name}</h4>
                                <span>
                                  {" "}
                                  <img src={locaicon} alt="" />{" "}
                                  {item.Address.slice(0, 30)}...
                                </span>
                              </div>
                              <div className="myproductedit">
                                <img
                                  onClick={() => handleedit(item)}
                                  src={editicon}
                                  alt=""
                                />
                                {storedata.length > 1 && (
                                  <img
                                    src={deleteicon}
                                    alt=""
                                    onClick={() => handledelete(item, index)}
                                  />
                                )}
                              </div>
                            </div>
                            <button
                              disabled={item.IsDefault === "Yes" && "disable"}
                              onClick={() => Changedefaultstore(item)}
                              style={{
                                color:
                                  item.IsDefault === "Yes" ? "#e6b437" : "red",
                              }}
                            >
                              {item.IsDefault === "No" && Loading2 ? (
                                <CircularProgress className="circle" />
                              ) : (
                                item.IsDefault === "Yes" && (
                                  <img src={checked} alt="" />
                                )
                              )}{" "}
                              {item.IsDefault === "Yes"
                                ? "Default Store"
                                : "Make Default store"}
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Mystore;
