import React from "react";
import MainPage from "../MainPage";
import "./Forgot.css";
import forgotimg from "../../../Assets/Images/forgotimg.png";
import Input from "../../../Components/Custom/Inputs/Input";
import messageicon from "../../../Assets/Icons/messageicon.png";
import Button from "../../../Components/Button/Button";
import Backbutton from "../../../Components/Button/Backbutton";
import { forgotvalidation } from "../../../Schema";
import { useFormik } from "formik";
import axios from "../../../API";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { useState } from "react";

const Forgot = () => {
  const [Loading, setLoading] = useState(false);
  const initialValues ={
    Email: ''
  }

   const onSubmit  = async() =>{
    setLoading(true)
     try {
      const res = await axios.post("/forgot-password", {
        Email: values.Email
      })
      // console.log(res)
      toast.success(res.data.message, {
        position: "top-right",
        theme: "colored",
      });
      setLoading(false)
     } catch (error) {
        console.log(error)
        setLoading(false)
     }
   }



  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
  useFormik({
    initialValues: initialValues,
    validationSchema: forgotvalidation,
    onSubmit,
  });


  const p = (
    <span>
      Please enter the email address you ‘d <br />
      like to your password reset\n <br />
      information sent to.
    </span>
  );
  return (
    <>
      <MainPage img={forgotimg} heading="Forgot Password?" pra={p}>
        <form onSubmit={handleSubmit} className="forgotpassdiv">
        <div className="emaildiv">
          <Input
            type={"email"}
            placeholder="Email"
            img={messageicon}
            name="Email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          {errors.Email && touched.Email ? (
            <p className='form-validation'>{errors.Email}</p>
          ): null}
        </div>
          <Button type={"submit"} className={"btn3"}>Submit</Button>
        </form>
        <Backbutton />
      </MainPage>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Forgot;
