import React, { useState } from "react";
import "./Slider.css";
import { Range } from "react-range";
import { useEffect } from "react";
// import { Slider } from "@mui/material";

const RangeSlider = ({values, setValues, max}) => {
  const [filteredProducts, setFilteredProducts] = useState([]);
 

  const products = [
    { id: 1, name: "Product A", price: 10 },
    { id: 2, name: "Product B", price: 20 },
    { id: 3, name: "Product C", price: 30 },
    { id: 4, name: "Product D", price: 40 },
    { id: 5, name: "Product E", price: 50 },
  ];

  useEffect(() => {
    // Filter the products based on the selected price range
    const filteredProducts = products.filter(
      (product) =>
        product.price >= values.priceRange[0] &&
        product.price <= values.priceRange[1]
    );
    setFilteredProducts(filteredProducts);
  }, [values.priceRange]);

  useEffect(() => {
    if (max) {
      setValues((prevValues) => ({
        ...prevValues,
        priceRange: [prevValues.priceRange[0], max],
      }));
    }
  }, [max]);



  return (
    <>
      <div className="raing-de">
        <div className="precerange">
          <span>Price</span>
          <div>
            <span>${values.priceRange[0]}</span> -
            <span>${values.priceRange[1]}</span>
          </div>
        </div>
        <div className="range-set-divv mt-3">
          <Range
            values={values.priceRange}
            allowOverlap={false}
            min={10}
            max={max}
            // allowOverlap={true}
            step={1}
            onChange={(newValues) =>
              setValues({ ...values, priceRange: newValues })
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "3px",
                  width: "100%",
                  backgroundColor: "#ccc",
                }}
              >
                {children}
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    left: `${(values.priceRange[0] / max) * 100}%`,
                    width: `${
                      ((values.priceRange[1] - values.priceRange[0]) / max) *
                      100
                    }%`,
                    backgroundColor: "#E6B437 ", // Custom color for selected range
                  }}
                />
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "16px",
                  width: "16px",
                  backgroundColor: "#FFF",
                  border: "2px solid #E6B437",
                  borderRadius: "50%",
                  zIndex: "100"
                  // boxShadow: "0px 0px 3px rgba(0, 0, 0, 0.5)",
                }}
              />
            )}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default RangeSlider;
