import React, { useState } from "react";
import closeicon from "../../../Assets/Images/closearrow.png";
import Button from "../../Button/Button";
import { motion, AnimatePresence } from "framer-motion";
import Input, { Input2 } from "../Inputs/Input";
import "./SendOfferModel.css";
import axios from "../../../API";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";

const SendOfferModel = ({ onClick, setshow, getjewellerworkboard }) => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    WorkBoardId: localStorage.getItem("workid"),
    Message: "",
    Price: "",
  };

  const onSubmit = async () => {
    if (values.Message === "") {
      toast.error("Message field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price === "") {
      toast.error("Price field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price !== "" && values.Message !== "") {
      setload(true);
      try {
        const response = await axios.post(
          "/send-wordboard-offer-by-jeweller",
          {
            WorkBoardId: values.WorkBoardId,
            Message: values.Message,
            Price: values.Price,
          },
          {
            headers: {
              "constent-type": "application/json",
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        setload(false);
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: "top-right",
            theme: "colored",
          });
        }

        if (response.data.message === "Offer Send Successfully.") {
          // navigate("/Profile/Workboard")
          setshow(false);
          getjewellerworkboard();
        }
        // console.log(response)
      } catch (error) {
        console.log(error);
        setload(false);
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      // validationSchema:
      onSubmit,
    });

  return (
    <>
      <AnimatePresence>
        <form onSubmit={handleSubmit} className="modelssss">
          <motion.div
            key={"model"}
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="Pcustomizemodel"
          >
            <div className="overflowscroll">
              <div className="closebtndiv">
                <div className="closebtn" onClick={onClick}>
                  <img src={closeicon} alt="" />
                </div>
              </div>
              <div className="modelcontent">
                <h2>Send Offer</h2>
                <div className="mainmodelc">
                  <div className="aboutjewellery">
                    <span>Message</span>
                    <textarea
                      placeholder="Write here"
                      name="Message"
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="15"
                    ></textarea>
                  </div>
                  <div className="uploadimg">
                    <span>Offer Price</span>
                    <Input2
                      placeholder={"$"}
                      name="Price"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="sendbtnsss">
                    <Button
                      type={"button"}
                      className={"cancelbtn"}
                      onClick={onClick}
                    >
                      Cancel
                    </Button>
                    <Button type={"submit"} className={"savebtn"}>
                      Send Offer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </AnimatePresence>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default SendOfferModel;

export const SendOfferModeltwo = ({ onClick, setshow }) => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    WorkBoardId: localStorage.getItem("workid"),
    Message: "",
    Price: "",
  };

  const onSubmit = async () => {
    if (values.Message === "") {
      toast.error("Message field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price === "") {
      toast.error("Price field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price !== "" && values.Message !== "") {
      setload(true);
      try {
        const response = await axios.post(
          "/upgrade-workboard-offer",
          {
            WorkBoardId: values.WorkBoardId,
            Message: values.Message,
            Price: values.Price,
          },
          {
            headers: {
              "constent-type": "application/json",
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        setload(false);
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: "top-right",
            theme: "colored",
          });
        }

        if (response.data.message === "Offer Send Successfully.") {
          // navigate("/Profile/Workboard")
          setshow(false);
        }
        // console.log(response)
      } catch (error) {
        console.log(error);
        setload(false);
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <>
      <AnimatePresence>
        <form onSubmit={handleSubmit} className="modelssss">
          <motion.div
            key={"model"}
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="Pcustomizemodel"
          >
            <div className="overflowscroll">
              <div className="closebtndiv">
                <div className="closebtn" onClick={() => setshow(false)}>
                  <img src={closeicon} alt="" />
                </div>
              </div>
              <div className="modelcontent">
                <h2>Send Offer</h2>
                <div className="mainmodelc">
                  <div className="aboutjewellery">
                    <span>Message</span>
                    <textarea
                      placeholder="Write here"
                      name="Message"
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="15"
                    ></textarea>
                  </div>
                  <div className="uploadimg">
                    <span>Offer Price</span>
                    <Input2
                      placeholder={"$"}
                      name="Price"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="sendbtnsss">
                    <Button
                      type={"button"}
                      className={"cancelbtn"}
                      onClick={onClick}
                    >
                      Cancel
                    </Button>
                    <Button type={"submit"} className={"savebtn"}>
                      Send Offer
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </AnimatePresence>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};
export const SendCustomOrderModel = ({
  onClick,
  Setcustommodel,
  workboardid,
}) => {
  const navigate = useNavigate();
  const [load, setload] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    // WorkBoardId: "",
    Message: "",
    Price: "",
  };

  const onSubmit = async () => {
    if (values.Message === "") {
      toast.error("Message field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price === "") {
      toast.error("Price field is reqiured", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Price !== "" && values.Message !== "") {
      setload(true);
      try {
        const response = await axios.post(
          "/add-customize-order",
          {
            WorkBoardId: workboardid,
            Description: values.Message,
            Price: values.Price,
          },
          {
            headers: {
              "constent-type": "application/json",
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        setload(false);
        if (response.data.status === "success") {
          toast.success(response.data.message, {
            position: "top-right",
            theme: "colored",
          });
        }

        if (response.data.message === "Order Successfully.") {
          Setcustommodel(false);
        }
        // console.log(response)
      } catch (error) {
        console.log(error);
        setload(false);
      }
    }
  };
  // console.log(workboardid)
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <>
      <AnimatePresence>
        <form onSubmit={handleSubmit} className="modelssss">
          <motion.div
            key={"model"}
            initial={{ y: -100, opacity: 0 }}
            whileInView={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0, y: -100 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="Pcustomizemodel"
          >
            <div className="overflowscroll">
              <div className="closebtndiv">
                <div className="closebtn" onClick={() => Setcustommodel(false)}>
                  <img src={closeicon} alt="" />
                </div>
              </div>
              <div className="modelcontent">
                <h2>Send Custom Order</h2>
                <div className="mainmodelc">
                  <div className="aboutjewellery">
                    <span>Message</span>
                    <textarea
                      placeholder="Write here"
                      name="Message"
                      onChange={handleChange}
                      id=""
                      cols="30"
                      rows="15"
                    ></textarea>
                  </div>
                  <div className="uploadimg">
                    <span>Offer Price</span>
                    <Input2
                      placeholder={"$"}
                      name="Price"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="sendbtnsss">
                    <Button
                      type={"button"}
                      className={"cancelbtn"}
                      onClick={onClick}
                    >
                      Cancel
                    </Button>
                    <Button type={"submit"} className={"savebtn"}>
                      Send Order
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </form>
      </AnimatePresence>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};
