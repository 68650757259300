import React from "react";
import { Backbtn } from "../../Components/Button/Backbutton";
import Container from "../../Components/Container/Container";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import "./Successful.css";
import successimg from "../../Assets/Icons/successfultick.png";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import Footer from "../../Components/Footer/Footer";

const JewellerSuccessful = () => {
  const navigate = useNavigate();

  return (
    <>
      <NavbarSecond />
      <Container>
        <div className="succesfulmain">
          <Backbtn onClick={() => navigate("/JewellerHome")} />
          <div className="successcontent">
            <img src={successimg} alt="" />
            <div>
              <h4>Successfully Added!</h4>
              <p>
                Lorem ipsum dolor sit amet consectetur. Turpissed <br />{" "}
                fermentum praesent tellus.
              </p>
            </div>
            <Button className={"btn5"} onClick={()=> navigate("/Addproduct")}>Add Another Product</Button>
          </div>
        </div>
      </Container>
      <Footer/>
    </>
  );
};

export default JewellerSuccessful;
