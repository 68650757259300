import React from "react";
import "./Input.css";
import PhoneInput from "react-phone-number-input";

const Input = ({
  type,
  onChange,
  img,
  placeholder,
  name,
  img2,
  onClick,
  onBlur,
  className,
}) => {
  return (
    <div className="inputdiv">
      <img src={img} alt="" />
      <input
        className={className}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        onBlur={onBlur}
      />
      <img src={img2} alt="" onClick={onClick} />
    </div>
  );
};

export default Input;

export const Input2 = ({
  type,
  onChange,
  value,
  required,
  img,
  placeholder,
  name,
  img2,
  onClick,
  onBlur,
  className,
}) => {
  return (
    <div className="inputdiv2">
      <img src={img} alt="" />
      <input
        value={value}
        required={required}
        className={className}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        onBlur={onBlur}
      />
      <img src={img2} alt="" onClick={onClick} />
    </div>
  );
};
export const Phoneinput = ({
  type,
  onChange,
  value,
  style,
  required,
  img,
  placeholder,
  name,
  img2,
  onClick,
  onBlur,
  className,
}) => {
  return (
    <div className="inputdiv2" style={style}>
      <img src={img} alt="" />
      <PhoneInput
        defaultCountry={"US"}
        onChange={onChange}
        className={className}
        onBlur={onBlur}
        value={value}
        placeholder="Mobile"
      />
      {/* <img src={img2} alt="" onClick={onClick} /> */}
    </div>
  );
};
export const Input3 = ({
  type,
  onChange,
  img,
  placeholder,
  name,
  onBlur,
  className,
  value,
  id,
  style,
}) => {
  return (
    <div className="inputdiv3" style={style}>
      <input
        autoComplete="off"
        id={id}
        value={value}
        className={className}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        onBlur={onBlur}
      />
    </div>
  );
};
