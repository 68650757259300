import React, { useEffect, useRef, useState } from "react";
import "./Slider.css";
import prev from "../../Assets/Icons/prevbtn.png";
import next from "../../Assets/Icons/nextbtn.png";
import next2 from "../../Assets/Icons/sliderarrow2.png";
import prev2 from "../../Assets/Icons/sliderarrow3.png";
import slideimg from "../../Assets/Images/firstslider.jpg";
import Button from "../Button/Button";
import Container from "../Container/Container";
import diamondring from "../../Assets/Images/diamondring.png";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "../../API";
import { CircularProgress } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Autoplay } from "swiper";

export const Slider = () => {
  // const [current, setcurrent] = useState(0);

  const data = [
    {
      id: 1,
      pra: (
        <p>
          Enpral Inc is proud to introduce the first ever platform with a
          utility patent pending, that would bring the global jewelry industry
          together into a single hub.
        </p>
      ),
    },
    {
      id: 2,
      pra: (
        <p>
          As of writing this article in the first quarter of 2023, amet talks of
          an upcoming global economic recession have become prevalent.
        </p>
      ),
    },
    {
      id: 3,
      pra: (
        <p>
          One industry expected to see growth and is forecast amet to grow to a
          $515 Billion USD industry by 2030 is the nunc aliquam jewelry
          industry.
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="slider">
        {/* <div className="prev" onClick={prevslide}>
          <img src={prev} alt="" />
        </div> */}
        <div className="slidercontent">
          <div className="sliderimg">
            <img src={slideimg} alt="" />
            <Container>
              <motion.div
                initial={{ x: -40, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 0.5 }}
                viewport={{ once: true, amount: 0.5 }}
                className="contentofslide"
              >
                <h1>
                  Revolutionizing
                  <br /> the jewellry
                  <br />
                  industry
                </h1>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  loop={true}
                  modules={[Autoplay]}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  className="khaaini"
                >
                  {data.map((item) => {
                    return (
                      <div className="kuchbhi2" key={item.id}>
                        <SwiperSlide key={item.id}>
                          <div>{item.pra}</div>
                        </SwiperSlide>
                      </div>
                    );
                  })}
                </Swiper>
                {/* <Button className={"btn2"}>Learn more</Button> */}
              </motion.div>
            </Container>
          </div>
        </div>
        {/* <div className="next" onClick={nextslide}>
          <img src={next} alt="" />
        </div> */}
      </div>
    </>
  );
};

export const Slidersecond = () => {
  const slideref = useRef(null);
  // const [current, setcurrent] = useState(0);
  const [storedata, setstoredata] = useState([]);
  const [loading, setloaading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const id = JSON.parse(localStorage.getItem("storeIDget"));
  const getstoredetail = async () => {
    setloaading(true);
    try {
      const response = await axios.post(
        "/get-store-detail",
        {
          StoreId: id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      setloaading(false);
      setstoredata([response.data.data.StoreData]);
    } catch (error) {
      console.log(error);
      setloaading(false);
    }
  };

  useEffect(() => {
    getstoredetail();
  }, []);

  return (
    <>
      {loading ? (
        <div className="manloderdiv">
          <CircularProgress />
        </div>
      ) : (
        <div className="slider2">
          <div
            className="prev2"
            onClick={() => slideref.current.swiper.slidePrev()}
          >
            <img src={prev2} alt="" />
          </div>
          <Container>
            <div className="slidercontent2">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                ref={slideref}
                loop={true}
                className="mySwiper"
              >
                {storedata.map((curelem, index) => {
                  return (
                    <div key={index}>
                      {curelem.StoreImage.map((__item, __index) => {
                        return (
                          <SwiperSlide key={__index}>
                            <div className="sliderimg2">
                              <img src={__item} alt="" />;
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </div>
                  );
                })}
              </Swiper>
            </div>
          </Container>
          <div
            className="next2"
            onClick={() => slideref.current.swiper.slideNext()}
          >
            <img src={next2} alt="" />
          </div>
        </div>
      )}
    </>
  );
};

// slider third.......................
export const SliderThird = () => {
  const slideref = useRef(null);
  // const [current, setcurrent] = useState(0);
  const [storedata, setstoredata] = useState([]);
  const [loading, setloaading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const route = useLocation();
  let maindata = route.state.id;
  const getstoredetail = async () => {
    setloaading(true);
    try {
      const response = await axios.post(
        "/get-store-detail",
        {
          StoreId: maindata.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      setloaading(false);
      setstoredata([response.data.data.StoreData]);
    } catch (error) {
      // console.log(error)
      setloaading(false);
    }
  };

  useEffect(() => {
    getstoredetail();
  }, []);

  return (
    <>
      {loading ? (
        <div className="manloderdiv">
          <CircularProgress />
        </div>
      ) : (
        <div className="slider2">
          <div
            className="prev2"
            onClick={() => slideref.current.swiper.slidePrev()}
          >
            <img src={prev2} alt="" />
          </div>
          <Container>
            <div className="slidercontent2">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                ref={slideref}
                loop={true}
                className="mySwiper"
              >
                {storedata.map((curelem, index) => {
                  return (
                    <div key={index}>
                      {curelem.StoreImage.map((__item, i) => {
                        return (
                          <SwiperSlide key={i}>
                            <div className="sliderimg2">
                              <img src={__item} alt="" />;
                            </div>
                          </SwiperSlide>
                        );
                      })}
                    </div>
                  );
                })}
              </Swiper>
            </div>
          </Container>
          <div
            className="next2"
            onClick={() => slideref.current.swiper.slideNext()}
          >
            <img src={next2} alt="" />
          </div>
        </div>
      )}
    </>
  );
};
