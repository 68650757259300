import axios from "../../API";
import React, { useEffect, useState } from "react";
import "./TermCondition.css";
import { Skeleton } from "@mui/material";

const TermCondition = () => {
  const [termdata, settermsdata] = useState([]);
  const [loading, setloading] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getterms = async () => {
    setloading(true);
    try {
      const res = await axios.get("/tems-condition", {
        headers: {
          authkey: `Berear ${data.Token}`,
        },
      });
      setloading(false);
      if (res.data.data) {
        settermsdata(res.data.data);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getterms();
  }, []);

  return (
    <>
      <div className="termscondition">
        <h3>Terms Conditions</h3>
        <div className="terms">
          {loading ? (
            <div>
              <Skeleton variant="text" width={200} height={20} />
              <Skeleton variant="text" width={100} height={20} />
            </div>
          ) : (
            <p dangerouslySetInnerHTML={{ __html: termdata.description }}></p>
          )}
        </div>
      </div>
    </>
  );
};

export default TermCondition;
