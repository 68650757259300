import React, { useState } from "react";
import "./Sidebar.css";
import user from "../../../Assets/Icons/usericon.png";
import message from "../../../Assets/Icons/messageico.png";
import workboard from "../../../Assets/Icons/workboardicon.png";
import savecard from "../../../Assets/Icons/cardiconyellow.png";
import myorder from "../../../Assets/Icons/ordericon.png";
import favourite from "../../../Assets/Icons/hearticon.png";
import lock from "../../../Assets/Icons/lockiconyellow.png";
import support from "../../../Assets/Icons/musicicon.png";
import terms from "../../../Assets/Icons/termsicon.png";
import { NavLink, Outlet } from "react-router-dom";
import NavbarSecond from "../../Navbar/NavbarSecond";
import Container from "../../Container/Container";
import ChangePassModel from "../Model/ChangePassModel";
import  wallet from '../../../Assets/Icons/wallet.png';
import store from '../../../Assets/Icons/store.png';
import manage from '../../../Assets/Icons/manage.png';
import sentoffer from '../../../Assets/Icons/sentoffer.png';
import affiliate from '../../../Assets/Icons/affiliate.png'
import location from '../../../Assets/Icons/Location_ic/location.png'
import Footer from "../../Footer/Footer";

const Sidebar = () => {
  const [show, setshow] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  // console.log(data)
  const usertype = data.UserType
  
  // customer data
  const routes = [
    {
      path: "/Profile/Myprofile",
      pathname: "My Profile",
      icon: user,
    },
    {
      path: "/Profile/Chat",
      pathname: "Messages",
      icon: message,
    },
    {
      path: "/Profile/Workboard",
      pathname: "Workboard",
      icon: workboard,
    },
    {
      path: "/Profile/SaveCards",
      pathname: "Save Cards",
      icon: savecard,
    },
    {
      path: "/Profile/Myorders",
      pathname: "My Orders",
      icon: myorder,
    },
    {
      path: "/Profile/Favourites",
      pathname: "Favourites",
      icon: favourite,
    },
    {
      path: "/Profile/Address",
      pathname: "My Address",
      icon: location,
    },
    {
      // path: "/Profile/ChangePassword",
      pathname: "Change Password",
      icon: lock,
    },
    {
      path: "/Profile/Support",
      pathname: "Support",
      icon: support,
    },
    {
      path: "/Profile/TermConditions",
      pathname: "Terms & Conditions",
      icon: terms,
    },
  ];
  // jewellerdata
  const routesjeweller = [
    {
      path: "/Profile/Myprofile",
      pathname: "My Profile",
      icon: user,
    },
    {
      path: "/Profile/Chat",
      pathname: "Messages",
      icon: message,
    },
    {
      path: "/Profile/Workboard",
      pathname: "Workboard",
      icon: workboard,
    },
    // {
    //   path: "/Profile/Wallet",
    //   pathname: "My Wallet",
    //   icon: wallet,
    // },
    {
      path: "/Profile/MyStore",
      pathname: "My Store",
      icon: store,
    },
    {
      path: "/Profile/Myorders",
      pathname: "My Orders",
      icon: myorder,
    },
    {
      path: "/Profile/ManageProduct",
      pathname: "Manage Products",
      icon: manage,
    },
    {
      path: "/Profile/SentOffer",
      pathname: "Sent Offer",
      icon: sentoffer,
    },
    {
      path: "/Profile/Affiliate",
      pathname: "Affiliate",
      icon: affiliate,
    },
    {
      // path: "/Profile/ChangePassword",
      pathname: "Change Password",
      icon: lock,
    },
    {
      path: "/Profile/Support",
      pathname: "Support",
      icon: support,
    },
    {
      path: "/Profile/TermConditions",
      pathname: "Terms & Conditions",
      icon: terms,
    },
  ];

  // affiliatedata
  const routesaffiliate = [
    {
      path: "/Profile/Myprofile",
      pathname: "My Profile",
      icon: user,
    },
    {
      path: "/Profile/Chat",
      pathname: "Messages",
      icon: message,
    },
    {
      path: "/Profile/SaveCards",
      pathname: "Save Cards",
      icon: savecard,
    },
    {
      path: "/Profile/Wallet",
      pathname: "My Wallet",
      icon: wallet,
    },
   
    {
      path: "/Profile/Support",
      pathname: "Support",
      icon: support,
    },
    {
      path: "/Profile/TermConditions",
      pathname: "Terms & Conditions",
      icon: terms,
    },
    {
      // path: "/Profile/ChangePassword",
      pathname: "Change Password",
      icon: lock,
    },
  ]
  const handleclick = (index) => {
    if (index === 8) {
      setshow(true);
    }
  };
  const handleclick2 = (index) => {
    if (index === 7) {
      setshow(true);
    }
  };
  const handleclick3 = (index) => {
    if (index === 6) {
      setshow(true);
    }
  };
  return (
    <>
      <NavbarSecond />
      <Container>
      {
          usertype === "Customer" ? 
          <div className="sidebarmaindiv">
          <div className="sidebardiv">
            {routes.map((item, index) => {
              return (
                <div key={index}>
                  <NavLink
                    to={item.path}
                    activeclassname={"active"}
                    onClick={() => handleclick2(index)}
                    style={{color: index === 7 ? "#232B37" : "" , backgroundColor: index === 7 ? "transparent" : ""}}
                  >
                    <img src={item.icon} alt="" />
                    <div className="sidenames">
                      <div>{item.pathname}</div>
                      <div className="sideline"></div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
          <main>{<Outlet />}</main>
        </div>
         : usertype === "Jeweller" ?  
         <div className="sidebarmaindiv">
          <div className="sidebardiv">
            {routesjeweller.map((item, index) => {
              return (
                <div key={index}>
                  <NavLink
                    to={item.path}
                    activeclassname={"active"}
                    onClick={() => handleclick(index)}
                    style={{color: index === 8 ? "#232B37" : "" , backgroundColor: index === 8 ? "transparent" : ""}}
                  >
                    <img src={item.icon} alt="" />
                    <div className="sidenames">
                      <div>{item.pathname}</div>
                      <div className="sideline"></div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
          <main>{<Outlet />}</main>
        </div>
         : usertype === "Affiliate" ? 
         <div className="sidebarmaindiv">
          <div className="sidebardiv">
            {routesaffiliate.map((item, index) => {
              return (
                <div key={index}>
                  <NavLink
                    to={item.path}
                    activeclassname={"active"}
                    onClick={() => handleclick3(index)}
                    style={{color: index === 6 ? "#232B37" : "" , backgroundColor: index === 6 ? "transparent" : ""}}
                  >
                    <img src={item.icon} alt="" />
                    <div className="sidenames">
                      <div>{item.pathname}</div>
                      <div className="sideline"></div>
                    </div>
                  </NavLink>
                </div>
              );
            })}
          </div>
          <main>{<Outlet />}</main>
        </div> : null
      }
      </Container>
      {show ? <ChangePassModel onClick={()=> setshow(false)} setshow={setshow} /> : null}
      <Footer/>
    </>
  );
};

export default Sidebar;
