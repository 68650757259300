import React, { useEffect, useRef, useState } from "react";
import "./MessageBox.css";
// import userimg from "../../Assets/Images/avtar.png";
import sendicon from "../../Assets/Icons/send.png";
import { useLocation } from "react-router-dom";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import { db } from "../../Firebase";
import usernull from "../../Assets/Icons/usernullimage.png";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  query,
  onSnapshot,
  setDoc,
  doc,
  orderBy,
} from "firebase/firestore";
import moment from "moment/moment";

const ProductMessagebox = () => {
  const [message, setmessage] = useState("");
  const [messagedata, setmessagedata] = useState([]);
  const divforscroll = useRef(null);
  let route = useLocation();
  const userid = route?.state?.value;
  let Maindata = route.state;
  const data = JSON.parse(localStorage.getItem("enpralData"));

  // console.log(Maindata, "maindata");
//   console.log({
//     productname: Maindata.productname,
//     productimages: Maindata.productimage,
//     productprice: Maindata.productprice,
//     productpricecurrency: Maindata.productpricecurrency,
//   });

  // getting chat messages...............................
  useEffect(() => {
    const q = query(
      collection(db, "chatrooms", Maindata.Roomid, "mychat"),
      orderBy("time", "asc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      orderBy("desc");
      const arr = [];
      snapshot.forEach((doc) => {
        arr.push(doc.data());
      });
      setmessagedata(arr);
    });
    return unsubscribe;
  }, [Maindata]);

  const OnsendMessages = async (e) => {
    var sentMsg = message;
    e.preventDefault();
    setmessage("");
    const messdata = {
      message: message,
      fromid: Maindata.Myid,
      time: Date.now() * 1000,
      seen: false,
    };

    const otherdata = {
      chatid: Maindata.Roomid,
      lastmessageid: data.Id,
      lastmessage: sentMsg,
      lastmessagetime: Date.now() * 1000,
      unreadcount: "0",
    };
    const mydata = {
      chatid: Maindata.Roomid,
      lastmessageid: data.Id,
      lastmessage: sentMsg,
      lastmessagetime: Date.now() * 1000,
      unreadcount: "1",
    };

    sendMessage(messdata);

    const otherproductdata = {
      productname: Maindata.productname,
      productimages: Maindata.productimage,
      productprice: Maindata.productprice,
      productpricecurrency: Maindata.productpricecurrency,
      updatedat: new Date(),
    };

    // for save last message in my
    const doref = doc(collection(db, "users"), data.Id);
    const ndoref = doc(collection(doref, "myproduct"), Maindata.productid);
    const kuchbhi = doc(collection(ndoref, "productchat"), Maindata.OppId);
    setDoc(kuchbhi, otherdata);

    // for save last message in ohter
    // const oppdoref = doc(collection(db, "users"), Maindata.OppId,);
    // const oppndoref= doc(collection(oppdoref, "mychat"),data.Id,);

    const opporef = doc(collection(db, "users"), Maindata.OppId);
    const oppdoref = doc(collection(opporef, "myproduct"), Maindata.productid);
    const oppkuchbhi = doc(collection(oppdoref, "productchat"), data.Id);
    setDoc(oppkuchbhi, mydata);

    setDoc(ndoref, otherproductdata);
    setDoc(oppdoref, otherproductdata);
  };

  const sendMessage = async (mess) => {
    const docRef = await addDoc(
      collection(db, "chatrooms", Maindata.Roomid, "mychat"),
      mess
    );
  };

  function convertLastTime(timestamp) {
    const currentTimestamp = moment().valueOf();
    const diffMilliseconds = currentTimestamp - timestamp / 1000;

    if (diffMilliseconds < 24 * 60 * 60 * 1000) {
      return moment(timestamp / 1000).format("hh:mm A");
    }
    if (
      diffMilliseconds >= 24 * 60 * 60 * 1000 &&
      diffMilliseconds < 2 * 24 * 60 * 60 * 1000
    ) {
      return "Yesterday";
    }
    if (diffMilliseconds < 7 * 24 * 60 * 60 * 1000) {
      const daysAgo = Math.floor(diffMilliseconds / (24 * 60 * 60 * 1000));
      return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
    }

    return moment(timestamp / 1000).format("MM/DD/YYYY");
  }

  return (
    <div className="chatnavparent">
      <NavbarSecond />
      <div className="boxmaindivpaarent2">
        <div>
          <form className="boxmaindiv2" style={{ width: "100%" }}>
            {Maindata ? (
              <div className="boxheader2">
                <img src={usernull} alt="" />
                <h3>{Maindata.OppName == null ? "User" : Maindata.OppName}</h3>
              </div>
            ) : (
              <div className="boxheader2">
                <img
                  src={
                    userid?.UserImages === null ? usernull : userid?.UserImages
                  }
                  alt=""
                />
                <h3>{userid?.UserName}</h3>
              </div>
            )}
            <div className="chatmessages2">
              {messagedata?.map((item) => {
                const formattedTime = convertLastTime(item.time);
                return (
                  <div key={uuidv4()}>
                    <p
                      className={item.fromid === data.Id ? "chatp2" : "chatp"}
                      style={{
                        alignSelf:
                          item.fromid === data.Id ? "flex-end" : "flex-start",
                      }}
                      ref={divforscroll}
                    >
                      {item.message}
                      {/* {splitTextIntoLines(item.message)} */}
                    </p>
                    <span
                      style={{
                        alignSelf:
                          item.fromid === data.Id ? "flex-end" : "flex-start",
                      }}
                    >
                      {formattedTime}
                      {/* {item.time} */}
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="sendmessage2">
              <textarea
                value={message}
                onChange={(e) => setmessage(e.target.value)}
                type="text"
                placeholder="Write here"
              />
              <button
                type="submit"
                disabled={message === "" ? "disable" : ""}
                onClick={OnsendMessages}
              >
                <img src={sendicon} alt="" />
              </button>
            </div>
          </form>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
  );
};

export default ProductMessagebox;

// const itemTimeMicroseconds = item.lastmessagetime;
// const itemTimeMilliseconds = Math.floor(
//   itemTimeMicroseconds / 1000
// );
// const createdTime = new Date(itemTimeMilliseconds);
//     { const formattedTime = createdTime.toLocaleTimeString("en-US", {
//                   hour: "numeric",
//                   minute: "numeric",
//                 });
