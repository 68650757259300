import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import Sidebar from "./Components/Custom/Sidebar/Sidebar";
import Forgot from "./Pages/AuthPages/Forgot/Forgot";
import Login from "./Pages/AuthPages/Login/Login";
import { lazy } from "react";
import { Suspense } from "react";
// import Register from "./Pages/AuthPages/Register/Register";
import Home from "./Pages/Home/Home";
import JewellerPages from "./Pages/JewellerPage/JewellerPages";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Chat from "./Pages/Message/Chat";
import MyOrder from "./Pages/MyOrders/MyOrder";
import MyProfile from "./Pages/MyProfile/MyProfile";
import ProductPage from "./Pages/ProductPage/ProductPage";
import Successful from "./Pages/Successful/Successful";
import Workboard from "./Pages/Workboard/Workboard";
import Favourites from "./Pages/Favourites/Favourites";
import Support from "./Pages/Support/Support";
import TermCondition from "./Pages/TermCondition/TermCondition";
import Notification from "./Pages/Notification/Notification";
import ViewOffer from "./Pages/ViewOffer/ViewOffer";
import JewellerHome from "./Pages/Home/JewellerHome";
import Addproduct from "./Pages/JewellerPage/Addproduct";
import StoreDetail from "./Pages/JewellerPage/StoreDetail";
import ProductDetail from "./Pages/JewellerPage/ProductDetail";
import JewellerProfile from "./Pages/JewellerPage/JewellerProfile";
import MyWallet from "./Pages/MyWallet/MyWallet";
import ManageProduct from "./Pages/ManageProduct/ManageProduct";
import Mystore from "./Pages/Mystore/Mystore";
import "firebase/messaging";
import Affiliate from "./Pages/Affiliate/Affiliate";
import WorkBoard from "./Pages/JewellerPage/WorkBoard";
import SentOffer from "./Pages/SentOffer/SentOffer";
// import { initializeApp } from "firebase/app";
// import {getMessaging, getToken, onMessage} from "firebase/messaging";
// import { messaging } from "./Firebase";
// import {  onMessage } from "firebase/messaging";
import AffiliateHome from "./Pages/Affiliate/AffiliateHome";
import AffiliateProfile from "./Pages/Affiliate/AffiliateProfile";
import { ToastContainer } from "react-toastify";
import JewellerSuccessful from "./Pages/Successful/JewellerSuccessful";
// import axios from "axios";
import { useEffect } from "react";
import ScrollToTop from "./ScrollTotop";
import StripeContainer from "./Components/StripeContainer/StripeContainer";
import NewAddress from "./Pages/AddAddress/NewAddress";
import StripeContainer2 from "./Components/StripeContainer/StripeContainer2";
import MyOrdersJewellers from "./Pages/JewellerPage/MyOrdersJewellers";
import KnowMore from "./Pages/KnowMore/KnowMore";
import HowWork from "./Pages/HowWork/HowWork";
import { Backdrop, CircularProgress } from "@mui/material";
import StripeContainer3 from "./Components/StripeContainer/StripeContainer3";
import Messagesecond from "./Pages/Message/Messagesecond";
import ProductMessagebox from "./Pages/Message/ProductMessagebox";
import Editproduct from "./Pages/JewellerPage/Editproduct";
import Search from "./Pages/Search/Search";
import EditStore from "./Pages/Mystore/EditStore";
import Viewallproduct from "./Pages/ViewAllproduct/Viewallproduct";
import "react-phone-input-2/lib/style.css";

const Register = lazy(() => import("./Pages/AuthPages/Register/Register"));

// import { RequestForToken } from "./Firebase";

function App() {
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const usertype = data?.UserType;

  // onMessage(messaging, (payload) => {
  // });

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      localStorage.setItem("lat", position.coords.latitude);
      localStorage.setItem("lon", position.coords.longitude);
    });
  }, []);

  // useEffect(()=> {
  //   if(data && usertype === "Jeweller"){
  //       navigate("/JewellerHome")
  //     }else if(data && usertype === "Customer"){
  //     navigate("/Home")
  //   }else if(data && usertype === "Affiliate"){
  //     navigate("/Affiliate")
  //   }
  // },[])
  // const [scrollPosition, setScrollPosition] = useState(0);
  // const handleScroll = () => {
  //     const position = window.pageYOffset;
  //     setScrollPosition(position);
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll, { passive: true });

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    // setScrollPosition(0)
  }, [pathname]);

  // console.log(pathname)

  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/KnowMore" element={<KnowMore />} />
        <Route path="/HowWork" element={<HowWork />} />
        {/* <Route path="/" element={data &&  <Navigate replace to={usertype === "Customer" ?  "/Home" : usertype === "Jeweller" ? "/JewellerHome" : usertype === "Affiliate" ? "AffiliateHome" : "/"} />} /> */}

        {usertype === "Customer" && <Route path="/Home" element={<Home />} />}
        {usertype === "Jeweller" && (
          <Route path="/JewellerHome" element={<JewellerHome />} />
        )}
        {usertype === "Affiliate" && (
          <Route path="/AffiliateHome" element={<AffiliateHome />} />
        )}

        <Route path="/Addproduct" element={<Addproduct />} />
        <Route path="/Login" element={<Login />} />
        <Route path="/ForgotPassword" element={<Forgot />} />
        <Route
          path="/Register"
          element={
            <Suspense
              fallback={
                <div className="backdrop">
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open={true}
                    // onClick={handleClose}
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                </div>
              }
            >
              <Register />
            </Suspense>
          }
        />
        <Route path="/Jeweller" element={<JewellerPages />} />
        <Route path="/Product" element={<ProductPage />} />
        <Route path="/Order" element={<StripeContainer2 />} />
        <Route path="/CompleteOrder" element={<StripeContainer3 />} />
        <Route path="/Successful" element={<Successful />} />
        <Route path="/AddSuccessful" element={<JewellerSuccessful />} />
        <Route path="/Notification" element={<Notification />} />
        <Route path="/Viewoffer" element={<ViewOffer />} />
        <Route path="/StoreDetail" element={<StoreDetail />} />
        <Route path="/ProductDetail" element={<ProductDetail />} />
        <Route path="/Editproduct" element={<Editproduct />} />
        <Route path="/Editstore" element={<EditStore />} />
        <Route path="/Myprofile" element={<JewellerProfile />} />
        <Route path="/Mywallet" element={<MyWallet />} />
        <Route path="/chat" element={<Messagesecond />} />
        <Route path="/chat2" element={<ProductMessagebox />} />
        <Route path="/search" element={<Search />} />
        <Route path="/ManageProduct" element={<ManageProduct />} />
        <Route path="/viewall" element={<Viewallproduct />} />

        {/* my profile routes */}
        <Route path="/Profile" element={<Sidebar />}>
          {usertype === "Customer" ? (
            <Route path="/Profile/Myprofile" element={<MyProfile />} />
          ) : usertype === "Jeweller" ? (
            <Route path="/Profile/Myprofile" element={<JewellerProfile />} />
          ) : usertype == "Affiliate" ? (
            <Route path="/Profile/Myprofile" element={<AffiliateProfile />} />
          ) : null}
          <Route path="/Profile/Chat" element={<Chat />} />
          {usertype === "Customer" ? (
            <Route path="/Profile/Workboard" element={<Workboard />} />
          ) : usertype === "Jeweller" ? (
            <Route path="/Profile/Workboard" element={<WorkBoard />} />
          ) : null}
          <Route path="/Profile/Sentoffer" element={<SentOffer />} />
          <Route path="/Profile/SaveCards" element={<StripeContainer />} />
          <Route
            path="/Profile/Myorders"
            element={
              usertype === "Customer" ? (
                <MyOrder />
              ) : usertype === "Jeweller" ? (
                <MyOrdersJewellers />
              ) : null
            }
          />
          {/* <Route path="/Profile/JewellerOrders" element={<MyOrdersJewellers />} /> */}
          <Route path="/Profile/Favourites" element={<Favourites />} />
          <Route path="/Profile/MyStore" element={<Mystore />} />
          <Route path="/Profile/Address" element={<NewAddress />} />
          <Route path="/Profile/Wallet" element={<MyWallet />} />
          <Route path="/Profile/ManageProduct" element={<ManageProduct />} />
          <Route path="/Profile/Affiliate" element={<Affiliate />} />
          <Route path="/Profile/Support" element={<Support />} />
          <Route path="/Profile/TermConditions" element={<TermCondition />} />
        </Route>
      </Routes>
      <ToastContainer />
      {/* <Footer /> */}
    </>
  );
}

export default App;
