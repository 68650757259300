import React, { useState } from "react";
import "./Addproduct.css";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import Container from "../../Components/Container/Container";
import { Back } from "../../Components/Button/Backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import {
  CategoryDrop,
  MaterialType,
  Storeget,
  Styletype,
} from "../../Components/Custom/Inputs/JInput";
import plusicon from "../../Assets/Icons/plusicon.png";
import Button from "../../Components/Button/Button";
import axios from "../../API";
import closeicon from "../../Assets/Images/closearrow.png";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Skeleton } from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Footer from "../../Components/Footer/Footer";
import { useEffect } from "react";

const Editproduct = ({ editproductdata, seteditproduct }) => {
  const navigate = useNavigate();
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState("");
  const [fields2, setFields2] = useState([{ id: 0 }]);
  const [Loading, setLoading] = useState(false);
  const [click, setclick] = useState("");
  const [productdata, setproductdata] = useState([]);
  const [Load, setload] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  // const route = useLocation();
  // const productid = route.state.item;
  //   console.log(productid);
  let myimage = myImgArr?.map((i) => {
    return i;
  });
  // create gellery AP.....................
  async function creategellery(image) {
    setLoading(true);
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.message === "Image Added Successfully") {
        setLoading(false);
      }
      setmyImgArr((names) => [...names, res.data.data]);
    } catch (error) {
      // console.log("thsiis useeffet error", error.response.data);
      setLoading(false);
    }
  }

  //   get product detail.....................
  const getProductdetail = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/my-products-detail",
        {
          // ProdId: productid && productid.Id,
          ProdId: editproductdata && editproductdata.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      setLoading(false);
      // console.log(response);
      setproductdata(response.data.data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getProductdetail();
  }, [editproductdata]);

  const initialValues = {
    Productname: "",
    Description: "",
    price: "",
    Title: "",
    store: "",
    Answer: "",
    Material: "",
    Weight: "",
    StyleType: "",
    Category: "" || localStorage.getItem("categuryID"),
    img: images.img,
  };

  //   console.log(productdata);
  //   set valuess...........................
  useEffect(() => {
    setFieldValue("Title", productdata?.Title);
    setFieldValue("Category", productdata?.Category);
    setFieldValue("Description", productdata?.Description);
    setFieldValue("Weight", productdata?.Weight);
    setFieldValue("Style", productdata?.Style);
    setFieldValue("price", productdata?.Price);
    setclick(productdata?.IsCustomize);
    setFieldValue("store", productdata?.StoreId);
    setFieldValue("Material", productdata?.Material);
    setmyImgArr(productdata.Images);
    setFields2(productdata.Specification);
  }, [productdata]);

  // console.log(productdata)

  //   add product API...................
  const onSubmit = async () => {
    setload(true);
    // console.log(editproductdata.Id)
    try {
      const response = await axios.post(
        "/update-product",
        {
          Title: values.Title,
          Category: values.Category,
          Description: values.Description,
          MaterialType: values.Material,
          IsCustomize: click,
          ProductSpecifications: fields2,
          Price: values.price,
          ProdId: editproductdata.Id,
          ProductIntore: values.store,
          ProductImages: myimage.toString(),
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      setload(false);
      if (response.data.status === "failed") {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      if (response.data.message === "Product Added Successfully") {
        navigate("/AddSuccessful");
      }
      // console.log(response)
    } catch (error) {
      // console.log(error)
      setload(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: "",
    onSubmit,
  });

  const handledelete = async (index) => {
    try {
      const response = await axios.post("/delete-gallery", {
        img: myImgArr.map((i) => {
          return i;
        }),
      });

      // console.log(response)
      if (response.data.message === "Image Deleted Successfully") {
        setmyImgArr(myImgArr.filter((image, i) => i !== index));
        // console.log(image)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onOptionChange = (e) => {
    setclick(e.target.value);
  };

  const handleChangeinput2 = (event, i) => {
    const { name, value } = event.target;
    setFields2((preval) => {
      let temp = [...preval];
      temp[i] = { ...temp[i], [name]: value };
      return temp;
    });
    // setFields2(updatedFields2);
  };

  const handleadd = () => {
    setFields2((preval) => [...preval, { id: fields2.length }]);
  };

  const handleremove = (itemremove) => {
    // console.log({itemremove});
    setFields2((prev) => prev.filter((item, _i) => item.id + 1 != itemremove));
  };

  localStorage.setItem("categuryID", values.Category);
  // localStorage.setItem("selectstore", values.Selectstore);

  return (
    <>
      {/* <NavbarSecond /> */}
      {/* <Container> */}
      <div className="addproductdiv">
        <Back onClick={() => seteditproduct(false)} />
        <div className="addproductcntnent">
          <form onSubmit={handleSubmit} className="addproductcontent">
            <h3>Edit Product</h3>
            <div className="addinputs">
              <div className="addinputmain">
                <span>Product Name</span>
                <Input3
                  onChange={handleChange}
                  name={"Productname"}
                  placeholder={"Product Name"}
                  value={values.Title}
                />
              </div>
              <div className="addinputmain">
                <span>Price</span>
                <Input3
                  onChange={handleChange}
                  name={"price"}
                  type={"number"}
                  value={values.price}
                  placeholder={"Price"}
                />
              </div>
              <div className="addinputmain">
                <span>Weight</span>
                <Input3
                  onChange={handleChange}
                  name={"Weight"}
                  type={"text"}
                  placeholder={"Weight"}
                  value={values.Weight}
                />
              </div>
              <div className="addinputmain">
                <span>Category</span>
                <CategoryDrop
                  style={{ Color: "black" }}
                  onChange={handleChange}
                  name={"Category"}
                  value={values.Category}
                />
              </div>
              <div className="addinputmain">
                <span>Store</span>
                <Storeget
                  style={{ Color: "black" }}
                  onChange={handleChange}
                  name={"store"}
                  setFieldValue={setFieldValue}
                  //  setstoredata={setstoredata}
                  //  storedata={storedata}
                />
              </div>
              <div className="addinputmain">
                <span>MaterialType</span>
                <MaterialType
                  style={{ Color: "black" }}
                  onChange={handleChange}
                  name={"Material"}
                  value={values.Material}
                />
              </div>
              <div className="addinputmain">
                <span>StyleType</span>
                <Styletype
                  onChange={handleChange}
                  name={"Styletype"}
                  value={values.StyleType}
                />
              </div>
            </div>
            <div className="addinputmain">
              <span>IsCustomize</span>
              <div className="radiobtns">
                <label htmlFor="radiobtn">
                  <input
                    type="radio"
                    id="radiobtn"
                    className="radiobtn"
                    onChange={onOptionChange}
                    checked={click === "YES"}
                    //   checked={productdata.IsCustomize === "YES" ? true : false}
                    value={"YES"}
                    name="radio"
                  />
                  <span>Yes</span>
                </label>
                <label htmlFor="radiobtn2">
                  <input
                    type="radio"
                    id="radiobtn2"
                    className="radiobtn"
                    onChange={onOptionChange}
                    value={"NO"}
                    checked={click === "NO"}
                    //   checked={productdata.IsCustomize === "NO" ? true : false}
                    name="radio"
                  />
                  <span>No</span>
                </label>
              </div>
            </div>
            <div className="addinputmain">
              <span>Description</span>
              <textarea
                name="Description"
                id=""
                cols="30"
                rows="10"
                placeholder="Write here"
                value={values.Description}
                onChange={handleChange}
              ></textarea>
            </div>
            <h4>Specification</h4>
            <div className="addinputs">
              <div className="addinputschild">
                {fields2?.map((item, i) => {
                  return (
                    <div key={i}>
                      <div className="addinputmain">
                        <span>Spec Name</span>
                        <Input3
                          onChange={(event) => handleChangeinput2(event, i)}
                          name={"Title"}
                          placeholder={"Title"}
                          id={i}
                          value={item.Title}
                        />
                      </div>
                      <div className="addinputmain">
                        <span>Detail</span>
                        <Input3
                          onChange={(event) => handleChangeinput2(event, i)}
                          name={"Answer"}
                          placeholder={"Answer"}
                          id={i}
                          value={item.Desc}
                        />
                        {i !== 0 && (
                          <button
                            type="button"
                            className="removebtn"
                            onClick={() => handleremove(i + 1)}
                          >
                            remove
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="addmore" onClick={handleadd}>
              <img src={plusicon} alt="" />
              <span>Add More Specs</span>
            </div>
            <div className="uploaddivaddproduct">
              <span>Upload Photos</span>
              <div className="imagessprev">
                <div className={"inputfile2"}>
                  <label htmlFor="file">
                    <input
                      onChange={(e) => {
                        setImages({
                          img: URL.createObjectURL(e.target.files[0]),
                        });
                        creategellery(e.target.files[0]);
                      }}
                      id="file"
                      type="file"
                      name="image"
                      accept="image/gif,image/jpeg,image/jpg,image/png"
                      multiple
                      data-original-title="upload photos"
                      // onChange={handleImageChange}
                    />
                    <img src={plusicon} alt="" />
                  </label>
                </div>
                <div className="previewdiv">
                  {myImgArr?.map((url, index) => {
                    return Loading ? (
                      <Skeleton
                        variant="rounded"
                        width={79}
                        height={79}
                        key={index}
                      />
                    ) : (
                      <div className="previewwww" key={index}>
                        <img key={index} src={url ? url : null} alt="" />
                        <div onClick={() => handledelete(index)}>
                          <img src={closeicon} alt="" />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="addproductbtn">
              <Button type={"submit"} className={"btn5"}>
                Edit Product
              </Button>
            </div>
          </form>
        </div>
      </div>
      {/* </Container> */}
      {!Load && <div></div>}
      {Load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
      {/* <Footer /> */}
    </>
  );
};

export default Editproduct;
