import React, { useEffect, useState } from "react";
import "./WorkBoard.css";
import SendOfferModel from "../../Components/Custom/Model/SendOfferModel";
import axios from "../../API";
import message from "../../Assets/Icons/chatsicon.png";
import { toast } from "react-toastify";
import usernull from "../../Assets/Icons/usernullimage.png";
import { CircularProgress } from "@mui/material";
import { motion } from "framer-motion";
import { v4 as uuidv4 } from "uuid";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { useNavigate } from "react-router-dom";
import { generateChatId } from "../AuthPages/Login/Login";

const WorkBoard = () => {
  const [show, setshow] = useState(false);
  const [workboarddata, setworkboarddata] = useState([]);
  const [loading, setloading] = useState(false);
  const [userId, setuserId] = useState("");
  const [IsShowData, setIsShowData] = useState(false);
  const [roomId, setroomId] = useState("");
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const getjewellerworkboard = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-jeweller-workboard-list", {
        headers: {
          "content-type": "application/json",
          authkey: `Bearer ${token.Token}`,
        },
      });
      if (response.data.data) {
        setworkboarddata(response.data.data);
      }
      // console.log(response);
      setloading(false);
      if (response.data.message === "No Record Found.") {
        toast.success("No Workboard Found", {
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error) {
      console.log("error.....", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getjewellerworkboard();
  }, [show]);

  const handleclick = (item) => {
    setshow(true);
    localStorage.setItem("workid", item.WorkBoardId);
  };

  // console.log(workboarddata)

  const chatid = generateChatId(token.Id, userId);

  useEffect(() => {
    // console.log("kuch")
    if (IsShowData === true) {
      const q = doc(collection(db, chatid));
      getDoc(q).then((snapshot) => {
        if (snapshot._document !== null) {
          // console.log("first")
          setroomId(snapshot.data().roomId);
        } else {
          // setroomId(uuidv4());
        }
      });
    }
  }, [IsShowData]);

  // useEffect(() => {
  //   const q = doc(
  //     collection(db, token.Id),
  //     "y12wA4WBWRH8alBtwbh68fX11StcEH2uON5MXE408I1sXEYuiE2WDdrr00n6"
  //   );
  //   getDoc(q).then((snapshot) => {
  //     // console.log(snapshot.exists);
  //     if (snapshot.exists) {
  //       setRoomAvail(true);
  //       setroomId(snapshot.data().roomId);
  //     } else {
  //       setRoomAvail(false);
  //       setroomId(uuidv4());
  //     }
  //   });
  // }, []);

  // console.log(userid)
  // const handlechat = (item) => {
  //   navigate("/chat", {
  //     state: {
  //       Myname: token.FullName,
  //       Myid: token.Id,
  //       OppName: item.UserName,
  //       OppId: item.UserId,
  //       Roomid: roomId,
  //       RoomAvail: false,
  //     },
  //   });
  // };

  const handlechat = (item) => {
    //  console.log(item)
    if (!userId) {
      setuserId(item.UserId);
    } else {
      navigate("/chat", {
        state: {
          Myname: token.FullName,
          Myid: token.Id,
          OppName: item.UserName,
          OppId: item.UserId,
          Oppimg: item.UserImages,
          Roomid: chatid,
          RoomAvail: false,
        },
      });
    }
  };

  return (
    <>
      <div className="jewellerworkboard">
        <h3>Workboard</h3>
        {loading ? (
          <div className="manloderdiv">
            <CircularProgress />
          </div>
        ) : (
          <div
            className={
              workboarddata.length === 5
                ? "jewellerworkboarddiv"
                : "jewellerworkboarddiv2"
            }
            style={{
              height: `${window.innerHeight - 150}px`,
              overflow: "hidden",
            }}
          >
            {workboarddata &&
              workboarddata.map((item, index) => {
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                    className="jewellerworkboardcard"
                    key={index}
                  >
                    <h4>{item.Title}</h4>
                    <p>{item.Description}</p>
                    <div className="workboardimgdiv">
                      {item.Images &&
                        item.Images.map((_item, index) => {
                          return <img key={index} src={_item} alt="" />;
                        })}
                    </div>
                    <span>Customer</span>
                    <div className="sendoffernamediv">
                      <div className="sendoffername">
                        <div className="sendofferimgdiv">
                          <img
                            src={
                              item.UserImages === null
                                ? usernull
                                : item.UserImages
                            }
                            alt=""
                          />
                          <span>{item.UserName}</span>
                        </div>
                        <div onClick={() => handlechat(item)}>
                          <img src={message} alt="" />
                        </div>
                      </div>
                      <div onClick={() => handleclick(item)}>
                        <button
                          className="sendofferbtn"
                          disabled={item.IsOfferSend === "YES" && "disable"}
                        >
                          {item.IsOfferSend === "YES"
                            ? "You already Send offer on this"
                            : "Send Offer"}
                        </button>
                      </div>
                    </div>
                  </motion.div>
                );
              })}
          </div>
        )}
      </div>
      {show ? (
        <SendOfferModel
          onClick={() => setshow(false)}
          setshow={setshow}
          getjewellerworkboard={getjewellerworkboard}
        />
      ) : null}
    </>
  );
};

export default WorkBoard;
