import React, { useEffect, useState } from "react";
import "./JewellerHome.css";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import Container from "../../Components/Container/Container";
import emptyproduct from "../../Assets/Images/emptyproductimg.png";
import Button from "../../Components/Button/Button";
import loca from "../../Assets/Icons/locationicon.png";
import { useNavigate } from "react-router-dom";
import axios from "../../API";
import Footer from "../../Components/Footer/Footer";
import { CircularProgress, Skeleton } from "@mui/material";
import { toast } from "react-toastify";

const JewellerHome = () => {
  // const [error, seterror] = useState("")
  const [session, setsession] = useState(false);
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [productdata, setproductdata] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("enpralData"));

  // console.log(token);
  // get jeweller dashboard.............
  const getmystore = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-jeweller-dashboard", {
        headers: {
          authkey: `Barear ${token?.Token}`,
        },
      });
      if (res.data.message === "Record Found.") {
        setstoredata(res.data.data.StoreData);
        setproductdata(res.data.data.ProductData);
      }
      setLoading(false);
      if (res.data.message === "No Record Found.") {
        setLoading(false);
      }
      if (res.data.data.ProductData.length === 0) {
        setshow(true);
      }

      console.log(res);
    } catch (error) {
      // console.log(error)
      setLoading(false);
      if (error.response.status === 401) {
        setsession(true);
        toast.error("Authorised failed please login", {
          position: "top-right",
          theme: "colored",
        });
        // localStorage.removeItem("enpralData");
        // navigate("/Login");
      }
    }
  };

  useEffect(() => {
    getmystore();
  }, []);

  // navigate to productDetail page
  const handleclick = (id) => {
    localStorage.setItem("proID", JSON.stringify(id.Id));
    navigate("/ProductDetail");
  };

  // navigate to store detail page
  const handlestoreclick = (id) => {
    localStorage.setItem("storeIDget", JSON.stringify(id.Id));
    navigate("/StoreDetail");
  };

  return (
    <>
      <NavbarSecond />
      <Container>
        <div className="mystorediv">
          <div className="defaultstore">
            <h3>My Stores</h3>
            {/* <div className="storeall">
            <span>Store</span>
              <img src={downarrow} alt="" />
            </div> */}
          </div>
          {Loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <div className="mystoreboxdiv2">
              {storedata.map((item, index) => {
                {
                  /* console.log(item.Images) */
                }
                return Loading ? (
                  <div className="loadindiv">
                    <Skeleton variant="rounded" />
                  </div>
                ) : (
                  <div
                    className="mystorebox"
                    key={index}
                    onClick={() => handlestoreclick(item)}
                  >
                    <div className="mystoreimgdiv">
                      <img src={item.Images} alt="" />
                    </div>
                    <div className="storenames">
                      <h4>{item.Name}</h4>
                      <div>
                        <img src={loca} alt="" />
                        <span>{item.Address.slice(0, 40)}..</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
          <h3 className="myproduct">My Products</h3>
          {Loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : show ? (
            <div className="emptyproduct">
              <img src={emptyproduct} alt="" />
              <Button
                className={"btn5"}
                onClick={() => navigate("/Addproduct")}
              >
                Upload Products
              </Button>
            </div>
          ) : (
            <div className="cardsss">
              {productdata.map((curelem, index) => {
                return Loading ? (
                  <div className="loadindiv" key={index}>
                    <Skeleton variant="rounded" />
                  </div>
                ) : (
                  <div
                    key={index}
                    className="productcard"
                    onClick={() => handleclick(curelem)}
                  >
                    <div className="imagesdiv">
                      <img src={curelem.Images} alt="productimage" />
                    </div>
                    <div className="titlediv">
                      <span>{curelem.Title}</span>
                      <div className="loco2">
                        <span>
                          {curelem.Currency}
                          {curelem.Price}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default JewellerHome;
