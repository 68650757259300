import React, { useEffect, useState } from "react";
import "./ReviewOrder.css";
import axios from "../../API";
// import ringimg from "../../Assets/Images/engagementring.png";
// import cancel from "../../Assets/Icons/cancelcrossicon.png";

const ReviewOrder2 = ({ step, setstep, orderdataa }) => {
  const [Chargedata, setchargedata] = useState([]);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  //   console.log(orderdata)

  const Getcharge = async () => {
    try {
      const response = await axios.get("/get-charge-data", {
        headers: {
          authkey: `Bearer ${token.Token}`,
        },
      });
      setchargedata(response.data.data);
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };
  // Api call useEffect...........
  useEffect(() => {
    Getcharge();
  }, []);

  const price = +orderdataa.Price;
  const taxRate = +Chargedata.ServiceTax;
  const priceWithTax = price + (price / 100) * taxRate;
  const finalprice = priceWithTax + +Chargedata.ApplicationCharge;

  return (
    <>
      <div
        className={
          step === 1
            ? "addressboxdiv2notactive"
            : step === 3
            ? "addressboxdivnotactive"
            : "addressboxdiv2"
        }
      >
        <div className="deleaddress">
          <div>2</div>
          <span>Review Order</span>
        </div>
        <div className="mainaddress">
          <div className="reviewdiv">
            <div className="reviewfirstdiv">
              <div className="engagement2">
                <h4>Item</h4>
                <div className="engagement">
                  <img src={orderdataa.Images} alt="" />
                  <h4>{orderdataa?.Title}</h4>
                </div>
              </div>
            </div>
            <div className="reviewline"></div>
            <div className="reviewfirstdiv2">
              <div className="engagement">
                <h4>Price:</h4>
                <div className="">
                  <h5>${orderdataa.Price}</h5>
                </div>
              </div>
              <div className="engagement">
                <h4>Fees:</h4>
                <div className="canceldiv">
                  <h4>${Chargedata.ApplicationCharge}</h4>
                </div>
              </div>
              <div className="engagement">
                <h4>Tax:</h4>
                <div className="canceldiv">
                  <h4>{taxRate}%</h4>
                </div>
              </div>
              <div className="engagement">
                <h4>Sub Total:</h4>
                <div className="canceldiv">
                  <h4>${finalprice}</h4>
                  {/* <img src={cancel} alt="" /> */}
                </div>
              </div>
            </div>
            <div className="reviewline"></div>
            <div className="nextbtn">
              <h3>
                Total: <span>${finalprice}</span>
              </h3>
              <button onClick={() => setstep(3)}>Next</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewOrder2;
