import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import EditProfile from "../EditProfile/EditProfile";
import girlimg from "../../Assets/Images/logingirlimg.png";
import cameraicon from "../../Assets/Icons/cameraicon.png";
import { useEffect } from "react";
import axios from "../../API";
import Affieditprofile from "./Affieditprofile";
import { Skeleton } from "@mui/material";

const AffiliateProfile = () => {
  const [show, setshow] = useState(false);
  const [load, setlaod] = useState(true);
  const [profiledata, setprofiledata] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const getprofile = async () => {
    try {
      const res = await axios.post(
        "/get-user-profile-by-id",
        {
          UserId: data.Id,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(res);
      setprofiledata(res.data.data);
      localStorage.setItem("profile", JSON.stringify(res.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setlaod(false);
    }, 3000);
  }, []);

  return (
    <>
      {!show ? (
        <div className="myprofile">
          <h3>My Profile</h3>
          <div className="myprofilecontent">
            {load ? (
              <Skeleton variant="circular" width={180} height={150} />
            ) : (
              <div className="profilediv">
                <div className="profileavtar">
                  <img src={profiledata.ProfilePic} alt="" />
                </div>
              </div>
            )}

            <div className="profilenames">
              <div className="namess">
                <span>Name</span>
                {load ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  <h4>{profiledata.Name}</h4>
                )}
              </div>
              <div className="namess">
                <span>Email</span>
                {load ? (
                  <Skeleton variant="text" width={170} />
                ) : (
                  <h4>{profiledata.Email}</h4>
                )}
              </div>
              <div className="namess">
                <span>Phone</span>
                {load ? (
                  <Skeleton variant="text" width={150} />
                ) : (
                  <h4>
                    {profiledata.CountryCode} {profiledata.MobileNumber}
                  </h4>
                )}
              </div>
            </div>
          </div>
          <Button className={"btn2"} onClick={() => setshow(true)}>
            Edit Profile
          </Button>
        </div>
      ) : (
        <Affieditprofile
          onClick={() => setshow(false)}
          setshow={setshow}
          getprofile={getprofile}
          profiledata={profiledata}
        />
      )}
    </>
  );
};

export default AffiliateProfile;
