import React, { useState } from "react";
import "./PaginationSlider.css";
import { useEffect } from "react";

const PaginationSlider = ({ productdata, handleprev, handlenext }) => {
  const [current, setcurrent] = useState(0);

  const [productdatas, setProductdata] = useState({
    TotalPage: 0,
  });

  const pagee = Array.from(
    { length: productdata && productdata?.TotalPage },
    (_, index) => ({ num: (index + 1).toString() })
  );

  // const pagee = [
  //   {
  //     num: "1",
  //   },
  //   {
  //     num: "2",
  //   },
  //   {
  //     num: "3",
  //   },
  //   {
  //     num: "4",
  //   },
  // ];
  const length = pagee.length;
  const nextslide = () => {
    setcurrent(current === length - 1 ? 0 : current + 1);
    handlenext();
  };
  const prevslide = () => {
    setcurrent(current === 0 ? length - 1 : current - 1);
    handleprev();
  };

  useEffect(() => {
    setTimeout(() => {
      setProductdata((prevData) => ({
        ...prevData,
        TotalPage: productdata?.TotalPage,
      }));
    }, 1000);
  }, []);

  // console.log(productdatas.TotalPage);
  const handleclick = () => {};
  // console.log(productdata?.TotalPage)

  return (
    <>
      <div className="pagination">
        <button onClick={prevslide}>Prew</button>
        {pagee.map((i, index) => {
          return (
            <div
              key={index}
              className={index === current ? "numbersactive" : "numbers"}
            >
              {i.num}
            </div>
          );
        })}
        <button onClick={nextslide}>Next</button>
      </div>
    </>
  );
};

export default PaginationSlider;
