import React, { useState } from "react";
import "./MyProfile.css";
import nullimg from "../../Assets/Icons/usernullimage.png";
import cameraicon from "../../Assets/Icons/cameraicon.png";
import Button from "../../Components/Button/Button";
import EditProfile from "../EditProfile/EditProfile";
import axios from "../../API";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";

const MyProfile = () => {
  const [show, setshow] = useState(false);
  const [image, setimage] = useState();
  const [load, setlaod] = useState(false);
  const [profiledata, setprofiledata] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const getprofile = async () => {
    setlaod(true);
    try {
      const res = await axios.post(
        "/get-user-profile-by-id",
        {
          UserId: data.Id,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(res)
      setlaod(false);
      setprofiledata(res.data.data);
      localStorage.setItem("profile", JSON.stringify(res.data.data));
    } catch (error) {
      console.log(error);
      setlaod(false);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  return (
    <>
      {!show ? (
        <div className="myprofile">
          <h3>My Profile</h3>
          <div className="myprofilecontent">
            <div className="profilediv">
              {load ? (
                <Skeleton variant="circular" width={150} height={150} />
              ) : (
                <div className="profileavtar">
                  <img
                    src={
                      profiledata.ProfilePic !== null
                        ? profiledata.ProfilePic
                        : nullimg
                    }
                    alt=""
                  />
                </div>
              )}

              {/* <input type="file"  id="avtar" accept="image/png, image/jpeg" onChange={(e) => setimage(e.target.files[0])}/>
          <label htmlFor="avtar">
            <img src={cameraicon} alt="" />
          </label> */}
            </div>
            <div className="profilenames">
              <div className="namess">
                <span>Name</span>
                {load ? (
                  <Skeleton variant="text" width={100} />
                ) : (
                  <h4>{profiledata.Name}</h4>
                )}
              </div>
              <div className="namess">
                <span>Email</span>
                {load ? (
                  <Skeleton variant="text" width={170} />
                ) : (
                  <h4>{profiledata.Email}</h4>
                )}
              </div>
              <div className="namess">
                <span>Phone</span>
                {load ? (
                  <Skeleton variant="text" width={150} />
                ) : (
                  <h4>
                    {profiledata.CountryCode} {profiledata.MobileNumber}
                  </h4>
                )}
              </div>
            </div>
          </div>
          <Button className={"btn2"} onClick={() => setshow(true)}>
            Edit Profile
          </Button>
        </div>
      ) : (
        <EditProfile
          profiledata={profiledata}
          setshow={setshow}
          getprofile={getprofile}
          onClick={() => setshow(false)}
        />
      )}
    </>
  );
};

export default MyProfile;
