import React, { useState } from "react";
import "./Addnewaddress.css";
import rightarrow from "../../Assets/Icons/backrightbtn.png";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Dropinput,
  Dropinput2,
  Dropinput3,
} from "../../Components/Custom/Inputs/JInput";
import { useFormik } from "formik";
import axios from "../../API";
import { Backdrop, CircularProgress } from "@mui/material";
import { Addaddressschema } from "../../Schema";

const AddnewAddress = ({ setshow, Getmyaddresss }) => {
  const [lat, setlat] = useState(null);
  const [address, setAddress] = useState("");

  const [laoding, setloading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    Name: "",
    Number: "",
    Address: "",
    Country: "",
    State: "",
    City: "",
    Zipcode: "",
    Landmark: "",
  };
  const handleSelect = async (value, { description }) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setlat(latLng);
    setAddress(value);
  };

  const onSubmit = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/create-address",
        {
          Name: values.Name,
          HouseNo: values.Number,
          Address: values.Address,
          City: values.City,
          State: values.State,
          Country: values.Country,
          Zipcode: values.Zipcode,
          Landmark: address,
          Latitude: lat.lat,
          Longitude: lat.lng,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Address Added Successfully") {
        setshow(false);
        Getmyaddresss();
      }
      setloading(false);
      // console.log(response)
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Addaddressschema,
    onSubmit,
  });

  const handleaddresschange = (value) => {
    setAddress(value);
    setFieldValue("Location", value);
  };

  return (
    <>
      <form className="inputmainparent" onSubmit={handleSubmit}>
        <img src={rightarrow} alt="" onClick={() => setshow(false)} />
        <div className="addinputparent">
          <div className="addinputtext">
            <div className="addinputmain">
              <span>Name</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Name"}
                placeholder={"Name"}
              />
              {errors.Name && touched.Name ? (
                <p className="form-validation">{errors.Name}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>House Number</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Number"}
                type={"number"}
                placeholder={"House Number"}
              />
              {errors.Number && touched.Number ? (
                <p className="form-validation">{errors.Number}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>Address</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Address"}
                placeholder={"Address"}
              />
              {errors.Address && touched.Address ? (
                <p className="form-validation">{errors.Address}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>Landmark</span>
              <PlacesAutocomplete
                value={address}
                onChange={handleaddresschange}
                onSelect={(value, suggestion) =>
                  handleSelect(value, suggestion)
                }
                searchOptions={{ types: ["address"] }}
                apiKey={"AIzaSyAHm5ZECK0-dkyjDrd4EtDrFoZCRzFKnaI"}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="">
                    <input
                      {...getInputProps({
                        placeholder: "Landmark",
                        className: "location-search-input",
                      })}
                      className="inputdiv3"
                      name="Landmark"
                    />
                    <div
                      className="autocomplete-dropdown-container"
                      style={{ width: "300px" }}
                    >
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                            style={{ width: "300px" }}
                            className="addressdropdown"
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              {errors.Landmark && touched.Landmark ? (
                <p className="form-validation">{errors.Landmark}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>Country</span>
              <Dropinput3 onChange={handleChange} name={"Country"} />
              {errors.Country && touched.Country ? (
                <p className="form-validation">{errors.Country}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>State</span>
              <Dropinput2
                onChange={handleChange}
                name={"State"}
                values={values}
              />
              {errors.State && touched.State ? (
                <p className="form-validation">{errors.State}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>City</span>
              <Dropinput
                onChange={handleChange}
                name={"City"}
                values={values}
              />
              {errors.City && touched.City ? (
                <p className="form-validation">{errors.City}</p>
              ) : null}
            </div>
            <div className="addinputmain">
              <span>Zipcode</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Zipcode"}
                type={"number"}
                placeholder={"Zipcode"}
              />
              {errors.Zipcode && touched.Zipcode ? (
                <p className="form-validation">{errors.Zipcode}</p>
              ) : null}
            </div>
          </div>
        </div>
        <button type="submit">Save</button>
      </form>
      {!laoding && <div></div>}
      {laoding && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default AddnewAddress;
