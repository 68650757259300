import React, { useEffect, useState } from "react";
import { Backbtn } from "../../Components/Button/Backbutton";
import Container from "../../Components/Container/Container";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import "./AddAddress.css";
import editicon from "../../Assets/Icons/editicon.png";
import Button from "../../Components/Button/Button";
import ReviewOrder from "../ReviewOrder/ReviewOrder";
import MakePayment from "../MakePayment/MakePayment";
import axios from "../../API";
import Footer from "../../Components/Footer/Footer";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ReviewOrder2 from "../ReviewOrder/ReviewOrder2";
import MakePayment2 from "../MakePayment/MakePayment2";

const Order = () => {
  const [step, setstep] = useState(1);
  const [address, setaddress] = useState([]);
  const [loading, setloading] = useState(false);
  const [orderdataa, setorderdata] = useState([]);
  const [addressid, setaddressid] = useState("");
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const navigate = useNavigate();
  let route = useLocation();
  const orderdata = route.state.item;
  // console.log(orderdata);

  const getaddress = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-my-address", {
        headers: {
          "content-type": "application/json",
          authkey: `Bearer ${token.Token}`,
        },
      });
      if (response.data.data) {
        setaddress(response.data.data);
      }
      // console.log(response);
      setloading(false);
      if (response.data.message === "No Record Found.") {
      }
    } catch (error) {
      console.log("error.....", error);
      setloading(false);
    }
  };

  const Getorderdetail = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/get-order-detail",
        {
          OrderId: orderdata.ModuleId,
        },
        {
          headers: {
            "content-type": "application/json",
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      if (response.data.data) {
        setorderdata(response.data.data);
      }
      // console.log(response);
      setloading(false);
      if (response.data.message === "No Record Found.") {
      }
    } catch (error) {
      console.log("error.....", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getaddress();
    Getorderdetail();
  }, []);

  const handlePrevClick = () => {
    setstep((prevStep) => Math.max(prevStep - 1, 1));
  };

  const handleclick = (item) => {
    navigate("/Profile/Address", {
      state: {
        item,
      },
    });
  };

  let h = 1;
  const handleaddres = (item) => {
    setstep(2);
    setaddressid(item);
  };

  // console.log(addressid)

  return (
    <>
      <NavbarSecond />
      <div className="addcontainer">
        <Container>
          <div className="addresscontent">
            <Backbtn onClick={() => navigate(-1)} />
            <div
              className={
                step === 1 ? "addressboxdiv" : "addressboxdivnotactive"
              }
            >
              <div className="deleaddress">
                <div>1</div>
                <span>Delivery Address</span>
              </div>
              {loading ? (
                <div className="circilardiv">
                  <CircularProgress />
                </div>
              ) : (
                <div className="mainaddress">
                  {address.map((item) => {
                    return (
                      <div className="addleftparent" key={item.Id}>
                        <div className="addresleft">
                          <div className="editaddres">
                            <span className="addreheading">Address</span>
                            <img
                              src={editicon}
                              alt=""
                              onClick={() => handleclick(item)}
                            />
                          </div>
                          <span className="kathryn">{item.Name}</span>
                          <div className="addrespra">
                            <p>
                              {item.HouseNo} <br /> {item.Location}{" "}
                              {item.Zipcode}
                            </p>
                          </div>
                          <span
                            className="deliver"
                            onClick={() => handleaddres(item)}
                          >
                            Deliver to this address
                          </span>
                        </div>
                        <div className="addresline"></div>
                      </div>
                    );
                  })}
                </div>
              )}
              <Button
                className={"addressaddbtn"}
                onClick={() =>
                  navigate("/Profile/Address", {
                    state: {
                      h,
                    },
                  })
                }
              >
                Add New Address
              </Button>
            </div>
            <ReviewOrder2
              setstep={setstep}
              step={step}
              orderdataa={orderdataa}
            />
            <MakePayment2
              setstep={setstep}
              step={step}
              addressid={addressid}
              orderdataa={orderdataa}
            />
            {step !== 1 && (
              <div className="prevnextbtnparent">
                <button className="prevnextbtn" onClick={handlePrevClick}>
                  prev
                </button>
              </div>
            )}
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Order;
