import React from 'react'
import './AffiliateHome.css'
import NavbarSecond from '../../Components/Navbar/NavbarSecond'
import Container from '../../Components/Container/Container'
import enrolicon from '../../Assets/Icons/enrollicon.png'
import dollericon from '../../Assets/Icons/dollericon.png'
import searchicon from '../../Assets/Icons/search.png'



const AffiliateHome = () => {
    return (
        <>
            <NavbarSecond />
            <Container>
                <div className="affiliatehomemain">
                    <div className="dollerss">
                        <div className="affiliateright">
                            <img src={enrolicon} alt="" />
                            <div>
                            <h3>0</h3>
                            <span>Enrolled</span>
                            </div>
                        </div>
                        <div className="affiliateright">
                            <img src={dollericon} alt="" />
                            <div>
                            <h3>0.00</h3>
                            <span>Total Earned</span>
                            </div>
                        </div>
                    </div>
                    <div className="storesyou">
                        <h4>Stores you enrolled</h4>
                        <img src={searchicon} alt="" />
                    </div>
                    <div className="enrollList">
                        <h1>You have no Enrolled</h1>
                    </div>
                </div>
            </Container>
        </>
    )
}

export default AffiliateHome