import React, { useEffect, useState } from "react";
import "./Workboard.css";
import plusicon from "../../Assets/Icons/plusicon.png";
import Button from "../../Components/Button/Button";
import axios from "../../API";
import CreateWorkboard from "./CreateWorkboard";
import { Backdrop, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Workboard = () => {
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [Cworkboarddata, setCworkboarddata] = useState([]);
  const [mainshow, setmainshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [Loading2, setLoading2] = useState(false);
  const navigate = useNavigate();

  // getworkboard API............
  const getcustomerworkboard = async () => {
    setLoading2(true);
    try {
      const response = await axios.post(
        "/get-customer-workboard-list",
        {
          PageId: "2",
          PerPage: "2",
          Keyword: "",
        },
        {
          headers: {
            "content-type": "application/json",
            authkey: `Bearer ${token.Token}`,
            // authkey: `877df1ccf249013da804cc38b30dd7c03b64b0f70c56cf08f22446a8999ee12a`,
          },
        }
      );
      if (response.data.data) {
        setCworkboarddata(response.data.data.WorkBoard);
      }
      setLoading2(false);
      // console.log(response);
    } catch (error) {
      console.log("error.....", error);
      setLoading2(false);
    }
  };

  useEffect(() => {
    getcustomerworkboard();
  }, []);

  // console.log(Cworkboarddata)

  // workboard detail API............
  const handleclick = async (item) => {
    setLoading(true);
    navigate("/Viewoffer", {
      state: {
        item,
      },
    });
  };

  const handnavigate = () => {};

  return (
    <>
      {!mainshow ? (
        <div className="workboarddiv">
          <div className="workboardheading">
            <h3>Workboard</h3>
            <div className="createcustom" onClick={() => setmainshow(true)}>
              <img src={plusicon} alt="" />
              <span>Create Custom Jewellery</span>
            </div>
          </div>
          <h4>Recent Requests</h4>
          {Loading2 ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <div
              className={
                Cworkboarddata.length > 3 ? "workdatadicv" : "workdatadicv2"
              }
            >
              {Cworkboarddata?.map((item, index) => {
                const { Images } = item;
                return (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                    className="mainboxrecent"
                    key={index}
                  >
                    <div className="leftsidecontentdiv">
                      <h4>{item.Title}</h4>
                      <p>{item.Description}</p>
                      <div className="imagesofbracelete">
                        {Images.map((_item, index) => {
                          return (
                            <div key={index}>
                              <img src={_item} alt="" />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className="rightsidecontentdiv">
                      <Button
                        className={"cancelbtn"}
                        onClick={() => handnavigate()}
                      >
                        Edit
                      </Button>
                      <Button
                        className={"savebtn"}
                        onClick={() => handleclick(item)}
                      >
                        View Offers
                      </Button>
                    </div>
                  </motion.div>
                );
              })}
            </div>
          )}
        </div>
      ) : (
        <CreateWorkboard mainshow={mainshow} setmainshow={setmainshow} />
      )}
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Workboard;
