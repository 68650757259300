import React, { Fragment, useEffect, useState } from "react";
import "./SentOffer.css";
import search from "../../Assets/Icons/search.png";
import sendoffer from "../../Assets/Icons/sendoffer.png";
import downarrow from "../../Assets/Icons/downarrow.png";
import message from "../../Assets/Icons/chatsicon.png";
import axios from "../../API";
import usernull from "../../Assets/Icons/usernullimage.png";
import {
  SendCustomOrderModel,
  SendOfferModeltwo,
} from "../../Components/Custom/Model/SendOfferModel";
import { Skeleton } from "@mui/material";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { collection, doc, getDoc } from "firebase/firestore";
import { db } from "../../Firebase";
import { generateChatId } from "../AuthPages/Login/Login";

const SentOffer = () => {
  const [show, setshow] = useState(false);
  const [show2, setshow2] = useState(false);
  const [SendcustomModel, Setcustommodel] = useState(false);
  const [workboardid, setworkboardid] = useState("");
  const [loading, setloading] = useState(true);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [sentdata, setsentdata] = useState([]);
  const [userId, setuserId] = useState("");
  const [IsShowData, setIsShowData] = useState(false);
  const [roomId, setroomId] = useState("");

  const getsentoffers = async () => {
    try {
      const response = await axios.get("/my-workboard-offer", {
        headers: {
          authkey: `Bearer ${token.Token}`,
          Accept: "application/json",
        },
      });
      // console.log(response)
      setsentdata(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(sentdata);

  useEffect(() => {
    getsentoffers();
  }, []);

  const handleclick = (item) => {
    setshow(true);
    localStorage.setItem("workid", item.WorkBoardId);
  };
  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);

  const handleCustomorder = (item) => {
    setworkboardid(item.WorkBoardId);
    Setcustommodel(true);
  };

  const navigate = useNavigate();

  const chatid = generateChatId(token.Id, userId);

  useEffect(() => {
    // console.log("kuch")
    if (IsShowData === true) {
      const q = doc(collection(db, chatid));
      getDoc(q).then((snapshot) => {
        if (snapshot._document !== null) {
          // console.log("first")
          setroomId(snapshot.data().roomId);
        } else {
          // setroomId(uuidv4());
        }
      });
    }
  }, [IsShowData]);

  const handlechat = (item) => {
    //  console.log(item)
    if (!userId) {
      setuserId(item.UserId);
    } else {
      navigate("/chat", {
        state: {
          Myname: token.FullName,
          Myid: token.Id,
          OppName: item.UserName,
          OppId: item.UserId,
          Oppimg: item.UserImages,
          Roomid: chatid,
          RoomAvail: false,
        },
      });
    }
  };

  return (
    <>
      <div className="sentofferdiv">
        <div className="sentofferheading">
          <h3>Sent Offers</h3>
          <div className="sentoffersearch">
            <img src={search} alt="" placeholder="Search Offer" />
            <input type="search" placeholder="search" />
          </div>
        </div>
        <div
          className={
            sentdata.length === 2 ? "sentoffercarddiv" : "sentoffercarddiv2"
          }
          style={{
            height: `${window.innerHeight - 150}px`,
            overflow: "scroll",
          }}
        >
          {sentdata.map((item, index) => {
            const imgss = item.Images;
            return (
              <Fragment key={index}>
                {loading ? (
                  <div className="skeleten">
                    <Skeleton variant="rounded" height={"300px"} />
                  </div>
                ) : (
                  <motion.div
                    initial={{ opacity: 0 }}
                    whileInView={{ opacity: 1 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ duration: 0.5 }}
                    className="sentoffercard"
                    key={index}
                  >
                    <div className="sentofferheadingdiv">
                      <div>
                        <div className="OfferStatus">
                          <h4>{item.Title}</h4>
                          <button
                            style={{
                              backgroundColor:
                                item.OfferStatus === "Accepted"
                                  ? "green"
                                  : "red",
                            }}
                          >
                            {item.OfferStatus}
                          </button>
                        </div>
                        <p>{item.Description}</p>
                      </div>
                      <div className="sentoffercustomer">
                        <span>Customer</span>
                        <div className="sentofferavtar">
                          <div>
                            <img
                              src={
                                item.UserImages === null
                                  ? usernull
                                  : item.UserImages
                              }
                              alt=""
                            />
                            <h4>{item.UserName}</h4>
                          </div>
                          <img
                            onClick={() => handlechat(item)}
                            src={message}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="sentofferimgdiv">
                      {imgss.map((item) => {
                        return <img src={item} alt="" />;
                      })}
                    </div>
                    <div className="youroffer">
                      <h4>Your Offer</h4>
                      <div onClick={() => handleclick(item)}>
                        <img src={sendoffer} alt="" />
                        <span>Send Another Offer</span>
                      </div>
                    </div>
                    <div className="yourofferdiv">
                      <div>
                        <div className="sendcorder">
                          <span>
                            Offer Price: <b>${item.Price}</b>
                          </span>
                          <button onClick={() => handleCustomorder(item)}>
                            Send Order
                          </button>
                        </div>
                        <span>{item.date}</span>
                      </div>
                      <p>{item.OfferDesc}</p>
                    </div>
                    {show2 ? (
                      <div className="yourofferdiv">
                        <div>
                          <span>
                            Offer Price: <b>{item.price}</b>
                          </span>
                          <span>{item.date}</span>
                        </div>
                        <p>{item.pra2}</p>
                      </div>
                    ) : null}
                    <div
                      className="viewmoreoffer"
                      onClick={() => setshow2(!show2)}
                    >
                      <span>View All Offers</span>
                      <img src={downarrow} alt="" />
                    </div>
                  </motion.div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
      {show ? <SendOfferModeltwo setshow={setshow} /> : null}
      {SendcustomModel ? (
        <SendCustomOrderModel
          Setcustommodel={Setcustommodel}
          workboardid={workboardid}
        />
      ) : null}
    </>
  );
};

export default SentOffer;
