import React, { useRef, useState, useEffect } from "react";
import "./JewellerPage.css";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import Container from "../../Components/Container/Container";
import { Back, Commonbtn, Downbtn } from "../../Components/Button/Backbutton";
import { useLocation, useNavigate } from "react-router-dom";
import { SliderThird } from "../../Components/Slider/Slider";
import likeicon from "../../Assets/Icons/likeicon.png";
import chaticon from "../../Assets/Icons/chatsicon.png";
import loco from "../../Assets/Icons/locationicon.png";
import { v4 as uuidv4 } from "uuid";

import likeed from "../../Assets/Icons/heart.png";
import filtericon from "../../Assets/Icons/filtericon.png";
import ratingimg from "../../Assets/Icons/simplestar.png";
import raingimg2 from "../../Assets/Icons/fillstar.png";
import FilterModel from "../../Components/Custom/Filter/FilterModel";
import Footer from "../../Components/Footer/Footer";
import usernull from "../../Assets/Icons/usernullimage.png";
import axios from "../../API";
import { toast } from "react-toastify";
import {
  collection,
  doc,
  getDoc,
  getFirestore,
  query,
} from "firebase/firestore";
import "firebase/firestore";

import { db } from "../../Firebase";
import { generateChatId } from "../AuthPages/Login/Login";

const JewellerPages = () => {
  const route = useLocation();
  const [read, setread] = useState(false);
  const [open, setopen] = useState(false);
  const [proData, setproductdata] = useState([]);
  const [storedata, setstoredata] = useState([]);
  const [loading, setloading] = useState(false);
  const [roomId, setroomId] = useState("");
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const navigate = useNavigate();
  const [IsShowData, setIsShowData] = useState(false);
  let maindata = route.state.id;
  const menuref = useRef();
  useEffect(() => {
    getstoredetail();
  }, []);

  // get store detail API.....................
  const getstoredetail = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/get-store-detail",
        {
          StoreId: maindata.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response);
      setloading(false);
      setstoredata([response.data.data.StoreData]);
      setIsShowData(true);
      setproductdata(response.data.data.ProductData);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // console.log(Roomid);
  const [RoomAvail, setRoomAvail] = useState(false);

  const chatid = generateChatId(token.Id, storedata[0]?.CreatedUserId);

  useEffect(() => {
    if (IsShowData === true) {
      const q = doc(collection(db, chatid));
      getDoc(q).then((snapshot) => {
        if (snapshot._document !== null) {
          // console.log("first")
          setRoomAvail(true);
          setroomId(snapshot.data().roomId);
        } else {
          setRoomAvail(false);
          setroomId(uuidv4());
        }
      });
    }
  }, [IsShowData]);
  // let Roomid =
  // console.log(Roomid);

  const handleclick = async (id) => {
    navigate("/Product", {
      state: {
        id,
      },
    });
  };

  const handlelike = async () => {
    try {
      const response = await axios.post(
        "/store-favourite-unfavourite",
        {
          StoreId: maindata.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      if (response.data.message) {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        // setlikedata(response.data.message)
        getstoredetail();
        if (response.data.message === "Favourite Successfully") {
          localStorage.setItem("likedislike", JSON.stringify(1));
        } else if (response.data.message === "Unfavourite Successfully.") {
          localStorage.setItem("likedislike", JSON.stringify(2));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlechat = () => {
    navigate("/chat", {
      state: {
        Myname: token.FullName,
        Myid: token.Id,
        OppName: storedata[0]?.CreatedUserName,
        OppId: storedata[0].CreatedUserId,
        Roomid: chatid,
        RoomAvail: false,
      },
    });
  };

  const viewall = (item) => {
    navigate("/viewall", {
      state: {
        item,
      },
    });
  };

  return (
    <>
      <NavbarSecond />
      <div className="backslider">
        <Container>
          <div className="jeweller">
            <Back onClick={() => navigate("/Home")} />
          </div>
        </Container>
        <SliderThird />
      </div>
      <Container>
        <div className="maincontnofjewellers">
          {storedata.map((item, index) => {
            return (
              <div key={index}>
                <div className="williams" key={index}>
                  <h2>{item.StoreName}</h2>
                  <div className="chatssbtn">
                    <button onClick={handlechat}>
                      <img src={chaticon} alt="" />
                      Chat
                    </button>
                    <div className="like" onClick={handlelike}>
                      <img
                        src={item.IsFavourite === "TRUE" ? likeed : likeicon}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
                <div className="location">
                  <img src={loco} alt="" />
                  <div className="mili">
                    <span>
                      <b>{maindata.Distance}</b> km away
                    </span>
                    <p>{item.StoreAddress}</p>
                  </div>
                </div>
                <div className="pragra">
                  <p>
                    Lorem ipsum dolor sit amet consectetur. Sit nunc id dolor
                    tristique aliquet. Morbi mattis tellus lectus odio id lorem
                    lorem nibh. Gravida molestie elementum posuere elementum
                    aliquet platea sed morbi mauris. Sit ullamcorper nunc
                    facilisis parturient nibh. Turpis elementum nullam ultrices
                    ullamcorper sollicitudin convallis ultrices tristique
                    feugiat. Sit et morbi tempus suscipit. Fringilla convallis
                    velit posuere id et.{" "}
                    {read ? (
                      <span>
                        Lorem ipsum dolor sit amet consectetur. Sit nunc id
                        dolor tristique aliquet. Morbi mattis tellus lectus odio
                        id lorem lorem nibh. Gravida molestie elementum posuere
                        elementum aliquet platea sed morbi mauris. Sit
                        ullamcorper nunc facilisis parturient nibh. Turpis
                        elementum nullam ultrices ullamcorper sollicitudin
                        convallis ultrices tristique feugiat. Sit et morbi
                        tempus suscipit. Fringilla convallis velit posuere id
                        et.
                      </span>
                    ) : null}
                  </p>
                  <Downbtn span={"Read More"} onClick={() => setread(!read)} />
                </div>
              </div>
            );
          })}

          <hr className="lineee" />
          <div className="productss" ref={menuref}>
            <h2>Products</h2>
            <button onClick={() => setopen(!open)}>
              <img src={filtericon} alt="" />
              Filter
            </button>
            {open ? (
              <>
                <FilterModel setopen={setopen} />
              </>
            ) : null}
          </div>
          {proData.map((curelem, i) => {
            const { CategoryName } = curelem;
            if (CategoryName === "Ring") {
              return (
                <Commonbtn
                  key={i}
                  text={"Rings"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}
          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Ring") {
                return ProductData.map((_item, __index) => {
                  return (
                    <div
                      key={_item.ProductId}
                      className="productcard"
                      onClick={() => handleclick(_item)}
                    >
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          {proData.map((curelem, i) => {
            {
              /* console.log('sdsdsdd',curelem) */
            }
            const { CategoryName } = curelem;
            if (CategoryName === "Gold Chain") {
              return (
                <Commonbtn
                  key={i}
                  text={"Gold Chain"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}
          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Gold Chain") {
                return ProductData.map((_item, __index) => {
                  return (
                    <div
                      key={_item.ProductId}
                      className="productcard"
                      onClick={() => handleclick(_item)}
                    >
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          {proData.map((curelem, i) => {
            const { CategoryName } = curelem;
            if (CategoryName === "Bracelet") {
              return (
                <Commonbtn
                  key={i}
                  text={"Bracelet"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}

          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Bracelet") {
                return ProductData.map((_item, __index) => {
                  return (
                    <div
                      key={_item.ProductId}
                      onClick={() => handleclick(_item)}
                      className="productcard"
                    >
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          <hr className="lineee2" />
          <div className="review">
            <h2>Review</h2>
            <div className="reviewss">
              {storedata.map((curelem, i) => {
                return (
                  <div key={i} className="reviewsschild">
                    {curelem.StoreReview.map((i, index) => {
                      const ratingCount = i.Rate;
                      const maxRating = 5;
                      const yellowStarCount = Math.min(ratingCount, maxRating);
                      const yellowStarRatingImages = Array.from(
                        { length: yellowStarCount },
                        (_, i) => <img key={i} src={raingimg2} alt="" />
                      );

                      const greyStarCount = maxRating - yellowStarCount;
                      const greyStarRatingImages = Array.from(
                        { length: greyStarCount },
                        (_, i) => (
                          <img
                            key={i + yellowStarCount}
                            src={ratingimg}
                            alt=""
                          />
                        )
                      );
                      const starRatingImages = [
                        ...yellowStarRatingImages,
                        ...greyStarRatingImages,
                      ];
                      return (
                        <div key={index} className="detailes">
                          <div className="namedats">
                            <div className="datesss">
                              <img
                                src={
                                  i.CreatedUserPic === null
                                    ? usernull
                                    : i.CreatedUserPic
                                }
                                alt=""
                              />
                              <span>
                                <div className="ratingimg">
                                  {starRatingImages.map((i) => {
                                    return (
                                      <img
                                        key={i.key}
                                        src={i.props.src}
                                        alt=""
                                      />
                                    );
                                  })}
                                </div>
                                {i.CreatedUserName}
                              </span>
                            </div>
                            <span>{i.ReviewAt}</span>
                          </div>
                          <div className="prassss">
                            <p>{i.Review}</p>
                          </div>
                          <hr className="lineee2" />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <Downbtn span={"View All Reviews"} />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default JewellerPages;
