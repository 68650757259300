import React from "react";
import Button from "../../Components/Button/Button";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import {
  Dropinput,
  Dropinput2,
  Dropinput3,
} from "../../Components/Custom/Inputs/JInput";
import Upload from "../../Components/Custom/UploadImage/Upload";
import "./JewellerEditprofile.css";
import { useFormik } from "formik";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useState } from "react";
import nullimg from "../../Assets/Icons/usernullimage.png";
import axios from "../../API";
import closeicon from "../../Assets/Images/closearrow.png";
import plusicon from "../../Assets/Icons/plusicon.png";
import { useEffect } from "react";
import { Back } from "../../Components/Button/Backbutton";

const JewelerEditprofile = ({ onClick, profiledata, setshow }) => {
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState("");
  const [Loading, setLoading] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const initialValues = {
    ShopName: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    City: "",
    State: "",
    Email: "",
    Country: "",
  };

  useEffect(() => {
    setFieldValue("Address", profiledata.Address);
    setFieldValue("Email", profiledata.Email);
    setFieldValue("ShopName", profiledata.ShopName);
    setFieldValue("City", profiledata.City);
    setFieldValue("Country", profiledata.Country);
    setFieldValue("State", profiledata.State);
    setmyImgArr(profiledata.ShopPhoto);
  }, []);

  let myimage = myImgArr.map((i) => {
    return i;
  });
  // console.log(myimage)

  // create gellry api......................
  async function creategellery(image) {
    // setLoading(true);
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(res)
      if (res.data.message === "Image Added Successfully") {
        // setLoading(false);
      }
      setmyImgArr((names) => [...names, res.data.data]);
    } catch (error) {
      // console.log("thsiis useeffet error", error.response.data);
      // setLoading(false);
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/update-jeweller-profile",
        {
          ShopName: values.ShopName,
          Address: values.Address,
          City: values.City,
          Latitude: localStorage.getItem("lat"),
          Longitude: localStorage.getItem("lon"),
          State: values.State,
          Email: values.Email,
          Zipcode: "465465",
          Country: values.Country,
          // ShopPhotos: myImgArr
          ShopPhotos: myimage.toString(),
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(response)
      setLoading(false);
      if (response.data.message === "Profile Updated Successfully.") {
        setshow(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  const handledelete = async (index) => {
    try {
      const response = await axios.post("/delete-gallery", {
        img: myImgArr.map((i) => {
          return i;
        }),
      });

      // console.log(response)
      if (response.data.message === "Image Deleted Successfully") {
        setmyImgArr(myImgArr.filter((image, i) => i !== index));
        // console.log(image)
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="jewellereditprofile">
        <Back onClick={() => setshow(false)} />
        <h3>Edit Profile</h3>
        <div className="jewellereditcotent">
          <div className="jewellereditinput">
            <span>Shop Name</span>
            <Input3
              name={"ShopName"}
              value={values.ShopName}
              onChange={handleChange}
            />
          </div>
          <div className="jewellereditinput">
            <span>Address</span>
            <Input3 value={values.Address} name={"Address"} />
          </div>
          <div className="jewellereditinput">
            <span>Email</span>
            <Input3
              type={"email"}
              name={"Email"}
              value={values.Email}
              onChange={handleChange}
            />
          </div>
          {/* <div className="jewellereditinput2">
            <div>
              <span>Zipcode</span>
              <Input3 type={"number"} />
            </div>
          </div> */}
          <div className="jewellereditinput">
            <span>Country</span>
            <Dropinput3
              name={"Country"}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.Country}
            />
          </div>
          <div className="jewellereditinput">
            <span>State</span>
            <Dropinput2
              name={"State"}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.State}
            />
          </div>
          <div>
            <span>City</span>
            <Dropinput
              name={"City"}
              values={values}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.City}
            />
          </div>
        </div>
        <div className="jewellereditinput">
          <span>Shop Photo</span>
          <div className="imagessprev">
            <div className={"inputfile2"}>
              <label htmlFor="file">
                <input
                  onChange={(e) => {
                    setImages({
                      img: URL.createObjectURL(e.target.files[0]),
                    });
                    creategellery(e.target.files[0]);
                  }}
                  id="file"
                  type="file"
                  name="image"
                  accept="image/gif,image/jpeg,image/jpg,image/png"
                  multiple
                  data-original-title="upload photos"
                  // onChange={handleImageChange}
                />
                <img src={plusicon} alt="" />
              </label>
            </div>
            <div className="previewdiv">
              {myImgArr?.map((url, index) => {
                return Loading ? (
                  <Skeleton
                    variant="rounded"
                    width={79}
                    height={79}
                    key={index}
                  />
                ) : (
                  <div className="previewwww" key={index}>
                    <img key={index} src={url ? url : null} alt="" />
                    <div onClick={() => handledelete(index)}>
                      <img src={closeicon} alt="" />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="edditprofilebtnjeweller">
          <Button className={"cancelbtn"} type={"button"} onClick={onClick}>
            Cancel
          </Button>
          <Button className={"savebtn"} type={"submit"}>
            Save
          </Button>
        </div>
      </form>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default JewelerEditprofile;
