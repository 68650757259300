import React, { useEffect, useState } from "react";
import "./List.css";
import searchicon from "../../Assets/Icons/search.png";
import { db } from "../../Firebase";
import usernull from "../../Assets/Icons/usernullimage.png";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  where,
} from "@firebase/firestore";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { useMemo } from "react";
import { Back } from "../../Components/Button/Backbutton";
import { generateChatId } from "../AuthPages/Login/Login";

const List = ({ setshowscreen, toggle, settoggle }) => {
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [personallistdata, setlistdata] = useState([]);
  const [myProductUsers, setMyProductUsers] = useState([]);
  const [productlistdata, setproductlistdata] = useState([]);
  const [isLoadingProductUsers, setIsLoadingProductUsers] = useState(true);
  const [select, setselect] = useState(0);
  const [mediaa, setmedia] = useState(false);
  const [productuser, setproductuser] = useState(false);
  const [selected, setselected] = useState(0);
  const [messageCount, setMessageCount] = useState(0);

  // let unreadcount = 0;

  useMemo(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, "users", token.Id, "mychat"),
          where("unreadcount", "!=", "0")
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          setMessageCount(querySnapshot.size);
        });

        return () => unsubscribe();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // console.log(messageCount);

  // personal chat list data........................................
  useMemo(() => {
    const q = query(
      collection(db, "users", token.Id, "mychat"),
      orderBy("lastmessagetime", "desc")
    );
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const arr = [];
      snapshot.forEach((docs) => {
        const doref = doc(collection(db, "users"), docs.id);
        getDoc(doref)
          .then((docSnapshot) => {
            if (docSnapshot.exists()) {
              const data = docSnapshot.data();
              arr.push({ a: docs.data(), b: data });
              setlistdata(arr);
            } else {
              console.log("Document does not exist");
            }
          })
          .catch((error) => {
            console.error("Error getting document:", error);
          });
      });
    });
    return unsubscribe;
  }, []);


  // product list data............................................
  useMemo(() => {
    const fetchData = async () => {
      // setIsLoadingProductUsers(true)
      try {
        const q = query(collection(db, "users", token.Id, "myproduct"));
        const querySnapshot = await getDocs(q);
        const arr = [];
        querySnapshot.forEach((doc) => {
          arr.push(doc.data());
          setproductlistdata(arr);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setIsLoadingProductUsers(false)
      }
    };
    fetchData();
  }, []);

  // product chat list users data
  useMemo(() => {
    const fetchData = async () => {
      try {
        setIsLoadingProductUsers(true);
        const q = query(collection(db, "users", token.Id, "myproduct"));
        const querySnapshot = await getDocs(q);
        const arr = [];
        // Product List [1=bmw 2=thar]
        querySnapshot.forEach(async (doc) => {
          const productChatQuerySnapshot = await getDocs(
            collection(doc.ref, "productchat")
          );

          let productUsersIds = [];

          productChatQuerySnapshot.forEach((doc) => {
            productUsersIds.push(doc.id);
            arr.push(doc.data());
            // setproductlistdata(arr);
          });

          setMyProductUsers((pre) => [...pre, productUsersIds]);

          // productChatQuerySnapshot.forEach((productChatDoc) => {
          //   arr.push(productChatDoc.data());
          // });

          // setproductchatdata(arr);
        });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoadingProductUsers(false);
      }
    };

    fetchData();
  }, []);

  const navigate = useNavigate();

  useMemo(() => {
    window.addEventListener("resize", (e) => {
      // console.log(e.currentTarget.innerWidth)
      if (e.currentTarget.innerWidth !== 1026) {
        setmedia(true);
      } else {
        setmedia(false);
      }
    });
  }, []);

  const handlechat = (item, index) => {
    // console.log(item)
    localStorage.setItem("IsScreen", true);
    setselect(index);
    setshowscreen(true);
    if (personallistdata) {
      navigate("/Profile/Chat", {
        state: {
          Myname: token.FullName,
          Myid: token.Id,
          OppName: item.b.name,
          OppId: item.b.id,
          Roomid: item.a.chatid,
          Oppimg: item.b.image,
          RoomAvail: true,
        },
      });
    }
  };

  const handlechat2 = (item, index) => {
    localStorage.setItem("IsScreen", true);
    setselect(index);
    setTimeout(() => {
      settoggle(true);
    }, 1000);
    setshowscreen(true);
    if (personallistdata) {
      navigate("/Profile/Chat", {
        state: {
          Myname: token.FullName,
          Myid: token.Id,
          OppName: item.b.name,
          OppId: item.b.id,
          Roomid: item.a.chatid,
          Oppimg: item.b.image,
          RoomAvail: true,
        },
      });
    }
  };

  const productchat2 = () => {
    setproductuser(true);
  };

  const productchat = () => {
    setproductuser(true);
  };

  const ppbuttons = [
    {
      id: 1,
      name: "Personal",
      counts: messageCount,
    },
    {
      id: 2,
      name: "Products",
      counts: "4",
    },
  ];

  function convertLastTime(timestamp) {
    const currentTimestamp = moment().valueOf();
    const diffMilliseconds = currentTimestamp - timestamp;

    if (diffMilliseconds < 24 * 60 * 60 * 1000) {
      return moment(timestamp).format("hh:mm A");
    }
    if (diffMilliseconds < 2 * 24 * 60 * 60 * 1000) {
      return "Yesterday";
    }
    if (diffMilliseconds < 7 * 24 * 60 * 60 * 1000) {
      const daysAgo = Math.floor(diffMilliseconds / (24 * 60 * 60 * 1000));
      return `${daysAgo} ${daysAgo === 1 ? "day" : "days"} ago`;
    }

    return moment(timestamp).format("MM/DD/YYYY");
  }

  return (
    <>
      <div
        className="mainlistdiv"
        style={{ display: toggle ? "none" : "block" }}
      >
        <div className="searchlist">
          <img src={searchicon} alt="" />
          <input type="search" placeholder="Search" />
        </div>
        {productuser ? (
          <div className="backbtnforlist">
            <Back onClick={() => setproductuser(false)} />
          </div>
        ) : (
          <div className="ppchatbutton">
            {ppbuttons.map((item, index) => {
              return (
                <button
                  onClick={() => setselected(index)}
                  className={selected === index ? "activebtn " : "unactivebtn"}
                  key={item.id}
                >
                  {item.name}{" "}
                  {messageCount !== 0 && (
                    <span
                      style={{
                        backgroundColor: index === 0 ? "#E6B437" : "#3d3737",
                      }}
                    >
                      {item.counts}
                    </span>
                  )}
                </button>
              );
            })}
          </div>
        )}

        {selected === 0 ? (
          <div className="chatslist">
            {personallistdata.map((item, index) => {
              const details = item.a;
              const userdetails = item.b;
              const itemTimeMicroseconds = details.lastmessagetime;
              const itemTimeMilliseconds = Math.floor(
                itemTimeMicroseconds / 1000
              );
              const createdTime = new Date(itemTimeMilliseconds);
              const formattedTime = createdTime.toLocaleTimeString("en-US", {
                hour: "numeric",
                minute: "numeric",
              });
              return (
                <div
                  className={
                    select === index ? "listmaindiv activelist" : "listmaindiv"
                  }
                  key={index}
                  onClick={
                    mediaa
                      ? () => handlechat2(item, index)
                      : () => handlechat(item, index)
                  }
                >
                  <div>
                    <div className="useravtar useravtar2">
                      <img
                        src={
                          userdetails?.image === ""
                            ? usernull
                            : userdetails.image
                        }
                        alt=""
                      />
                    </div>
                    <div className="listusername">
                      <h3>{userdetails.name}</h3>
                      <p>{details.lastmessage.slice(0, 20)}</p>
                    </div>
                  </div>
                  <div className="chattime">
                    <span>{formattedTime}</span>
                    <span
                      className={details.unreadcount === "0" ? "" : "countspan"}
                    >
                      {details.unreadcount !== "0" ? details.unreadcount : ""}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <>
            <div className="chatslist">
              {!isLoadingProductUsers &&
                productlistdata.map((item, index) => {
                  const itemTimeMicroseconds = item.seconds;
                  const formattedTime = convertLastTime(itemTimeMicroseconds);
                  return (
                    <div key={index}>
                    <ChatCard
                      {...{
                        select,
                        mediaa,
                        productchat2,
                        item,
                        index,
                        productlistdata,
                        setproductuser,
                        productuser,
                        productchat,
                        myProductUsers,
                        formattedTime,
                      }}
                    />
                    </div>
                  );
                })}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default List;

function ChatCard({
  select,
  index,
  mediaa,
  productchat2,
  productlistdata,
  item,
  productchat,
  setproductuser,
  productuser,
  myProductUsers = [],
  formattedTime,
}) {
  const [list, setlist] = useState([]);
  const [userId, setuserId] = useState("");
  const token = JSON.parse(localStorage.getItem("enpralData"));

  useEffect(() => {
    const fetchData = async () => {
      if (myProductUsers.length !== 0) {
        try {
          const promises = myProductUsers[index].map(async (_item) => {
            const q = doc(db, "users", _item);
            const querySnapshot = await getDoc(q);
            return querySnapshot.data();
          });
          
          // Wait for all the promises to resolve
          const results = await Promise.all(promises);
          
          // Update the list state with the fetched data
          setlist(results);
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchData();
  }, [myProductUsers, index]);


  const chatid = generateChatId(token.Id,)

  const navigate = useNavigate()  
  const handlechat =(item) => {
    console.log(item)
    if(!userId){
      setuserId(item.id) 
    }else{
      navigate("/Profile/Chat", {
       state: {
         Myname: token.FullName,
         Myid: token.Id,
         OppName: item.name,
         OppId: item.id,
         Oppimg: item.UserImages,
         Roomid: chatid,
         RoomAvail: false,
       },
     });
    }
  }


  return (
    <>
      {productuser ? (
        <div className="chatslist">
          {list?.map((item, index) => {
            {/* console.log(item); */}
            return (
              <div
                className={
                  select === index ? "listmaindiv activelist" : "listmaindiv"
                }
                key={index}
                onClick={()=> handlechat(item)}
              >
                <div>
                  <div className="useravtar useravtar2">
                    <img className="" src={item.image} alt="" />
                  </div>
                  <div className="listusername">
                    <h3>{item.name}</h3>
                    {/* <p>{details.lastmessage.slice(0, 20)}</p> */}
                  </div>
                </div>
                <div className="chattime">
                  {/* <span>{formattedTime}</span> */}
                  {/* <span
                      className={details.unreadcount === "0" ? "" : "countspan"}
                    >
                      {details.unreadcount !== "0" ? details.unreadcount : ""}
                    </span> */}
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className={
            select === index ? "listmaindiv activelist" : "listmaindiv"
          }
          key={index}
          onClick={
            mediaa
              ? () => productchat2(item, index)
              : () => productchat(item, index)
          }
        >
          <div>
            <div className="useravtar">
              <img className="" src={item.productimages} alt="" />
            </div>
            <div className="listusername">
              <h3>{item.productname}</h3>
              <p>
                {item.productpricecurrency}
                {item.productprice}
              </p>
              <div>
                {productlistdata &&
                  list?.map((item, index) => {
                    return (
                      <div>
                        <img key={index} src={item.image || usernull} alt="" />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="chattime">
            <span>{formattedTime}</span>
            {/* <span
        className={details.unreadcount === "0" ? "" : "countspan"}
      >
        {details.unreadcount !== "0" ? details.unreadcount : ""}
      </span> */}
          </div>
        </div>
      )}
    </>
  );
}
