import React, { useRef, useState, useEffect } from "react";
import Container from "../Container/Container";
import logo from "../../Assets/Logos/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import Button from "../Button/Button";
import "./Navbar.css";
import { Spiral as Hamburger } from "hamburger-react";
import avtar from "../../Assets/Images/avtar.png";
import Model from "../Custom/Model/Profilemodel";
import downarrow from "../../Assets/Icons/downarrowicon.png";

const Navbar = () => {
  const [open, setopen] = useState(false);
  const [anima, setanima] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  // console.log(data);
  const menuref = useRef();
  const navigate = useNavigate();
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setopen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });

  const handlehide = () => {
    setopen(!open);
  };

  const handleclick = () => {
    navigate("/");
  };

  useEffect(() => {
    const setting = setInterval(() => {
      setanima((prevState) => !prevState);
    }, 4000);
    return () => {
      clearInterval(setting);
    };
  }, []);

  return (
    <>
      <nav className="navbarr" ref={menuref}>
        <Container>
          <div className="content">
            <div className="logoNavbar" onClick={handleclick}>
              <img src={logo} alt="" />
            </div>
            {/* <div className="search">
                <img src={searchicon} alt="" />
                <input type="search" placeholder='Find Jewelers' />
            </div> */}
            <ul className={open ? "list open" : "list"}>
              {!data ? (
                <ul className="links">
                  <li>
                    <NavLink activeclassname={"active"} to={"/"}>
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <NavLink activeclassname={"active"} to={"/KnowMore"}>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    {/* <NavLink>Contact Us</NavLink> */}
                    <a href="mailto:m.abbad@enpral.com">Contact Us</a>
                  </li>
                </ul>
              ) : (
                <div className="noti">
                  {/* <div className="notification">
                    <div className="num">2</div>
                    <motion.img
                    initial={{scale: 0.5, }}
                    whileInView={{scale: 1}}
                    transition={{duration: 0.4, ease: 1}}
                    viewport={{once: true, amount: 0.5}}
                     src={notification} alt="" />
                  </div> */}
                  <div className="avtar">
                    <div className="avtarmain" onClick={() => setopen(!open)}>
                      <img src={avtar} alt="" />
                      <span>
                        {data.FullName} <img src={downarrow} alt="" />
                      </span>
                    </div>
                    {open ? <Model /> : null}
                  </div>
                </div>
              )}

              {/* {!data ? (
                <div className="loginbtn">
                  <Button
                    className={anima ? "animate btn" : "btn"}
                    onClick={() => navigate("/Login")}
                  >
                    Login
                  </Button>
                </div>
              ) : null} */}
            </ul>
            <div className="menubtn">
              <Hamburger onToggle={handlehide} toggled={open} />
            </div>
          </div>
        </Container>
      </nav>
    </>
  );
};

export default Navbar;
