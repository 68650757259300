import * as Yup from 'yup';

export const LoginValidation = Yup.object({
    Email: Yup.string().email().required("Please enter email"),
    Password: Yup.string().required("Please enter password")
});

export const RegisterValidation = Yup.object({
    Firstname: Yup.string().required("Please enter your firstname"),
    mobile: Yup.string().required("Please enter your number"),
    Email: Yup.string().email().required("Please enter your email"),
    Password: Yup.string().required("Please enter your password")
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
})
export const AffiliateValidation = Yup.object({
    Firstname: Yup.string().required("Please enter your firstname"),
    affimobile: Yup.string().required("Please enter your number"),
    Email: Yup.string().email().required("Please enter your email"),
    Password: Yup.string().required("Please enter your password")
    .min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
    Country: Yup.string().required("Please select your Country"),
    RegisterDocNo: Yup.string().required("Please enter your Document Number").matches(/[^\s*].*[^\s*]/g, '* This field cannot contain only blankspaces'),

})

export const JewellerRegisterValidation = Yup.object({
    ShopName: Yup.string().required("Please enter your shopname").matches(/[^\s*].*[^\s*]/g, '* This field cannot contain only blankspaces'),
    Address: Yup.string().required("Please enter your Address").matches(/[^\s*].*[^\s*]/g, '* This field cannot contain only blankspaces'),
    Zipcode: Yup.number().required("Please enter your Zipcode"),
    City: Yup.string().required("Please select your city"),
    State: Yup.string().required("Please select your state"),
    Email: Yup.string().email().required("Please enter your email"),
    Password: Yup.string().required("Please enter your password").min(8, 'Password must be 8 characters long')
    .matches(/[0-9]/, 'Password requires a number')
    .matches(/[a-z]/, 'Password requires a lowercase letter')
    .matches(/[A-Z]/, 'Password requires an uppercase letter')
    .matches(/[^\w]/, 'Password requires a symbol'),
    Confirmpassword: Yup.string().required("Please enter your confirm password").oneOf([Yup.ref("Password"), null],
    "Confirm password must be same"
    ),
    RegisterDocNo: Yup.string().required("Please enter your Document Number").matches(/[^\s*].*[^\s*]/g, '* This field cannot contain only blankspaces'),
    GovermentDocCountry: Yup.string().required("Please select your Country"),
    Country: Yup.string().required("Please select your Country"),
    // Upload: Yup.string().required("Upload Shop Photos field is required"),   
})

export const forgotvalidation = Yup.object({
    Email: Yup.string().email().required("Please enter your email")
})

export const Addcardschema = Yup.object({
    CardName: Yup.string().required(
      "required field cannot be empty"
    ),
  });



  export const Addaddressschema = Yup.object({
    Name: Yup.string().required(
        "required field cannot be empty"
      ),
      Number: Yup.number().required("required field cannot be empty"),
      Address: Yup.string().required("required field cannot be empty"),
      Country: Yup.string().required("required field cannot be empty"),
      State: Yup.string().required("required field cannot be empty"),
      City: Yup.string().required("required field cannot be empty"),
      Landmark: Yup.string().required("required field cannot be empty"),
      Zipcode: Yup.number().required("required field cannot be empty"),
  })
