import axios from "../../../API";
import React, { useEffect, useState } from "react";
import "./Input.css";

export const JInput = ({
  type,
  onChange,
  placeholder,
  name,
  onBlur,
  className,
}) => {
  return (
    <div className="inputdiv">
      <input
        className={className}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        name={name}
        onBlur={onBlur}
      />
    </div>
  );
};

export const Dropinput = ({
  onChange,
  name,
  onBlur,
  className,
  value,
  values,
  Stateid,
}) => {
  const [citydata, setcitydata] = useState([]);
  const [loading, setloading] = useState(false);
  const [khainiid, setkhainiid] = useState();
  const stateID = localStorage.getItem("stateID");

  useEffect(() => {
    if (values?.State === undefined) {
      setkhainiid(Stateid?.State);
    } else if (values?.State) {
      setkhainiid(values.State);
    } else if (Stateid?.State === undefined) {
      setkhainiid(values.Country);
    } else if (Stateid?.State) {
      setkhainiid(Stateid.State);
    }
  }, [Stateid, values, khainiid]);

  const getcity = async () => {
    setloading(true);
    try {
      const response = await axios.post("/get-city-data", {
        StateId: khainiid,
      });
      if (response) {
        setloading(false);
      }
      if (response.data.data) {
        setcitydata(response.data.data);
      }
      // console.log(response)
    } catch (error) {
      // console.log(error)
      setloading(false);
    }
  };

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        onClick={getcity}
        defaultValue="City"
        value={value}
      >
        <option disabled defaultValue="City">
          {loading ? "Loading..." : "City"}
        </option>
        {citydata.map((item, index) => {
          return (
            <option value={item.StateId} key={index}>
              {item.StateName}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export const Dropinput2 = ({
  onChange,
  name,
  onBlur,
  className,
  value,
  values,
  Cid,
}) => {
  const [states, setstates] = useState([]);
  const [loading, setloading] = useState(false);
  const [khainiid, setkhainiid] = useState(null);

  useEffect(() => {
    if (values?.Country === undefined) {
      setkhainiid(Cid?.Country);
    } else if (values?.Country) {
      setkhainiid(values.Country);
    } else if (Cid?.Country === undefined) {
      setkhainiid(values.Country);
    } else if (Cid?.Country) {
      setkhainiid(Cid.Country);
    }
  }, [Cid, values, khainiid]);

  const getstate = async () => {
    setloading(true);
    try {
      const response = await axios.post("/get-state-data", {
        CountryId: khainiid,
      });
      if (response) {
        setloading(false);
      }
      if (response.data.data) {
        setstates(response.data.data);
      }
      // console.log(response)
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        onClick={getstate}
        defaultValue="State"
        value={value}
      >
        <option disabled defaultValue={"State"}>
          {loading ? "Loading..." : "State"}
        </option>
        {states.map((item, index) => {
          return (
            <option value={item.StateId} key={index}>
              {item.StateName}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export const Dropinput3 = ({ onChange, name, onBlur, className, value }) => {
  const [getcountrydata, setgetcountrydata] = useState([]);

  const getcountry = async () => {
    try {
      const response = await axios.get("/get-country-data", {});
      if (response.data.data) {
        setgetcountrydata(response.data.data);
      }
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(getcountrydata)

  useEffect(() => {
    getcountry();
  }, []);

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue="Country"
        value={value}
      >
        <option disabled defaultChecked defaultValue={"Country"}>
          Country
        </option>
        {getcountrydata.map((item, index) => {
          {
            /* console.log(item) */
          }
          return (
            <option value={item.CountryId} key={index}>
              {item.CountryName}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export const CategoryDrop = ({ onChange, name, onBlur, className, value }) => {
  const [categorydata, setcategurydata] = useState([]);
  const [loading, setloading] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getcategury = async () => {
    try {
      const response = await axios.get("/get-product-category", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      if (response.data.data) {
        setcategurydata(response.data.data);
        setloading(false);
      }
    } catch (error) {
      // console.log("categuryerror", error)
    }
  };

  // console.log(getcountrydata)

  useEffect(() => {
    getcategury();
  }, []);

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue="Select"
        value={value}
      >
        {/* <option disabled defaultChecked defaultValue={"Select"} onClick={() => setloading(true)}>
          {loading ? "Loading..." : "Select"}
        </option> */}
        {categorydata.map((item, index) => {
          {
            /* console.log(item) */
          }
          return (
            <option value={item.cat_id} key={index}>
              {item.cat_title}
            </option>
          );
        })}
      </select>
    </div>
  );
};

export const Storeget = ({
  onChange,
  name,
  onBlur,
  className,
  setFieldValue,
  // setstoredata,
  // storedata
}) => {
  const [loading, setloading] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getstorelist = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-my-store", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      if (response.data.data) {
        // setstoredata(response.data.data)
        setstoredata(response.data.data);
      }
      setloading(false);
      // console.log(response)
    } catch (error) {
      console.log("store", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getstorelist();
  }, []);

  useEffect(() => {
    if (storedata.length > 0) {
      const firstItem = storedata[0];
      setFieldValue("store", firstItem.Id);
    }
  }, [storedata]);

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue="Store"
      >
        <option disabled defaultChecked defaultValue={"Store"}>
          {loading ? "Loading..." : "Select Store"}
        </option>
        {storedata?.map((item, index) => {
          return (
            <option value={item.Id} key={index}>
              {item.Name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
// export const Storegetlist = ({
//   onChange,
//   name,
//   onBlur,
//   className,
//   setFieldValue
//   // setstoredata,
//   // storedata
//  }) => {

//   const [loading, setloading] = useState(false)
//   const [storedata, setstoredata] = useState([])
//   const data = JSON.parse(localStorage.getItem("enpralData"))
//   const getstorelist = async () => {
//     setloading(true)
//     try {
//       const response = await axios.post("/get-all-store", {
//           Keyword: "",
//       },{
//         headers: {
//           authkey: `Bearer ${data.Token}`
//         }
//       })
//       if (response.data.data) {
//         // setstoredata(response.data.data)
//         setstoredata(response.data.data)
//       }
//       setloading(false)
//       console.log(response)
//     } catch (error) {
//       console.log("store", error)
//       setloading(false)
//     }
//   }

//   useEffect(() => {
//     getstorelist()
//   }, [])

//   useEffect(() => {
//     if (storedata.length > 0) {
//       const firstItem = storedata[0];
//       setFieldValue("store", firstItem.Id);
//     }
//   }, [storedata]);

//   return (
//     <div className="inputdivselect">
//       <select
//         name={name}
//         className={className}
//         onChange={onChange}
//         onBlur={onBlur}
//         defaultValue="Store"
//       >
//         <option disabled defaultChecked defaultValue={"Store"} >
//           {loading ? "Loading..." : "Select Store"}
//         </option>
//         {
//           storedata?.map((item, index) => {

//             return (
//               <option value={item.Id} key={index} >{item.Name}</option>
//             )
//           })
//         }
//       </select>
//     </div>
//   );

// }
export const Storegetselected = ({
  onChange,
  name,
  onBlur,
  className,
  storedata,
  setstoredata,
}) => {
  const [loading, setloading] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getstorelist = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-my-store", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      if (response.data.data) {
        setstoredata(response.data.data);
      }
      setloading(false);
      // console.log(response)
    } catch (error) {
      // console.log("store", error)
      setloading(false);
    }
  };

  useEffect(() => {
    getstorelist();
  }, []);

  // console.log(storedata)

  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue={storedata.map((i) => {
          // console.log(i.Id)
          return i.Id;
        })}
      >
        {/* <option disabled defaultChecked defaultValue={"Store"} >
          {loading ? "Loading..." : "Select Store"}
        </option> */}
        {storedata.map((item, index) => {
          {
            /* console.log(item) */
          }
          return (
            <option defaultValue={item.Id} value={item.Id} key={index}>
              {item.Name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export const MaterialType = ({ onChange, name, onBlur, className, value }) => {
  const [loading, setloading] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getmetarialtype = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-material-type", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      if (response.data.data) {
        setstoredata(response.data.data);
      }
      setloading(false);
      // console.log(response)
    } catch (error) {
      console.log("store", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getmetarialtype();
  }, []);
  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue="Material"
      >
        <option disabled defaultChecked defaultValue={"Material"}>
          {loading ? "Loading..." : "Material"}
        </option>
        {storedata.map((item, index) => {
          return (
            <option value={item.type_name} key={index}>
              {item.type_name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export const Styletype = ({ onChange, name, onBlur, className, value }) => {
  const [loading, setloading] = useState(false);
  const [styletype, setstyletype] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const getmetarialtype = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-product-style", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      if (response.data.data) {
        setstyletype(response.data.data);
      }
      setloading(false);
      // console.log(response)
    } catch (error) {
      console.log("store", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getmetarialtype();
  }, []);
  return (
    <div className="inputdivselect">
      <select
        name={name}
        className={className}
        onChange={onChange}
        onBlur={onBlur}
        defaultValue="Styletype"
        value={value}
      >
        <option disabled defaultChecked defaultValue={"Styletype"}>
          {loading ? "Loading..." : "Styletype"}
        </option>
        {styletype?.map((item, index) => {
          return (
            <option value={item.style_name} key={index}>
              {item.style_name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
