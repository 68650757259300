import React, { useState } from "react";
import "./ProductModel.css";
import closeicon from "../../../Assets/Images/closearrow.png";
import Button from "../../Button/Button";
import { motion } from "framer-motion";
// import Upload from "../UploadImage/Upload";
import plusicon from "../../../Assets/Icons/plusicon.png";
import axios from "../../../API";
import { useFormik } from "formik";
// import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";
import { Input2 } from "../Inputs/Input";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Thumbs } from "swiper";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

const ProductModel = ({ onClick, maindata, imagess, setopen }) => {
  const [load, setload] = useState(false);
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState([]);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    message: "",
    itemname: "",
  };

  async function creategellery(image) {
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // console.log(res.data)

      setmyImgArr((name) => [...name, res.data.data]);
    } catch (error) {
      // console.log("thsiis useeffet error", error.response.data);
    }
  }

  // delete img api.......
  const handleDelete = async (index) => {
    try {
      const response = await axios.post("/delete-gallery", {
        img: myImgArr.map((i) => {
          return i;
        }),
      });

      // console.log(response)
      if (response.data.message === "Image Deleted Successfully") {
        setmyImgArr(myImgArr.filter((image, i) => i !== index));
        // console.log(image)
      }
    } catch (error) {
      // console.log(error)
    }
  };

  const onSubmit = async () => {
    setload(true);
    try {
      const response = await axios.post(
        "/create-customer-workboard",
        {
          Type: "SendToStore",
          Title: values.itemname,
          Description: values.message,
          Images: myImgArr.toString(),
          StoreId: maindata[0]?.ProductData.StoreId,
          Latitude: localStorage.getItem("lat"),
          Longitude: localStorage.getItem("lon"),
        },
        {
          headers: {
            "constent-type": "application/json",
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      setload(false);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }

      if (response.data.message === "WorkBoard Request Send Successfully") {
        setopen(false);
      }
      // console.log(response)
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const {
    values,
    // errors,
    // touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  useEffect(() => {
    setmyImgArr(imagess);
    setFieldValue("itemname", maindata[0].ProductData.Title);
  }, [maindata]);

  return (
    <>
      <form className="modelssss" onSubmit={handleSubmit}>
        <motion.div
          key={"model"}
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="Pcustomizemodel"
        >
          <div className="overflowscroll">
            <div className="closebtndiv">
              <div className="closebtn" onClick={onClick}>
                <img src={closeicon} alt="" />
              </div>
            </div>
            <div className="modelcontent">
              <h2>Send Customize Request</h2>
              <div className="mainmodelc">
                <div className="itemname">
                  <span>Item Name</span>
                  <Input2
                    placeholder={"item name"}
                    name={"itemname"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.itemname}
                  />
                  {/* <span>Gold diamond ring</span> */}
                </div>
                <div className="aboutjewellery">
                  <span>About Jewellery</span>
                  <textarea
                    placeholder="Write here"
                    name="message"
                    id=""
                    cols="30"
                    rows="15  "
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                <div className="uploadimg">
                  <span>Upload Photo (If you need)</span>
                  {/* <Upload setImages={setImages} images={images}  className={"inputfile"} /> */}
                  <div className="imagessprev">
                    <div className={"inputfile2"}>
                      <label htmlFor="file">
                        <input
                          onChange={(e) => {
                            setImages(e.target.files[0]);
                            creategellery(e.target.files[0]);
                          }}
                          id="file"
                          type="file"
                          name="image"
                          accept="image/gif,image/jpeg,image/jpg,image/png"
                          multiple
                          data-original-title="upload photos"
                          // onChange={handleImageChange}
                        />
                        <img src={plusicon} alt="" />
                      </label>
                    </div>
                    <div className="previewdivparent">
                      {/* <Swiper
                        spaceBetween={10}
                        slidesPerView={4}
                        freeMode={true}
                        watchSlidesProgress={true}
                        modules={[FreeMode, Thumbs]}
                        className="mySwiper"
                      > */}
                      <div className="previewdiv">
                        {myImgArr?.map((url, index) => {
                          return (
                            <div className="previewwww" key={index}>
                              {/* <div className="loadingdiv"></div> */}
                              <img key={index} src={url} alt="" />
                              {myImgArr.length > imagess.length &&
                                index === myImgArr.length - 1 && (
                                  <div onClick={() => handleDelete(index)}>
                                    <img src={closeicon} alt="" />
                                  </div>
                                )}
                            </div>
                          );
                        })}
                      </div>
                      {/* </Swiper> */}
                    </div>
                  </div>
                </div>
                <div className="sendbtnss">
                  <Button className={"btn6"}>Send request to Jeweller</Button>
                  {/* <Button className={"btn7"}>Send request to Workboard</Button> */}
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </form>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ProductModel;
