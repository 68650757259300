import React, { useEffect, useState } from "react";
import "./ManageProduct.css";
import search from "../../Assets/Icons/search.png";
import { CategoryDrop } from "../../Components/Custom/Inputs/JInput";
import axios from "../../API";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import loca from "../../Assets/Icons/locationicon.png";
import { toast } from "react-toastify";
import editicon from "../../Assets/Icons/editiconblack.png";
import deleteicon from "../../Assets/Icons/deleteicon.png";
import backarrow from "../../Assets/Icons/backrightbtn.png";
import plusicon from "../../Assets/Icons/plusicon.png";
import { useFormik } from "formik";
import Editproduct from "../JewellerPage/Editproduct";

const ManageProduct = () => {
  const [Loading, setLoading] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [productdata, setproductdata] = useState([]);
  const [load, setload] = useState(false);
  const [show, setshow] = useState(false);
  const [editproductdata, seteditproductdata] = useState([]);
  const [editproduct, seteditproduct] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const navigate = useNavigate();

  // Store get API..............
  // const getmystore = async () => {
  //   setLoading(true)
  //   try {
  //     const res = await axios.get("/get-my-store", {
  //       headers: {
  //         authkey: `Barear ${token.Token}`
  //       }
  //     });
  //     if (res.data.message === "Record Found.") {
  //       setstoredata(res.data.data)
  //     }
  //     setLoading(false)
  //     if (res.data.message === "No Record Found.") {
  //       // seterror("You have No Store Yet")
  //       // setshow(true)
  //       setLoading(false)
  //     } else {
  //       // setshow(false)
  //     }
  //     // console.log(res)
  //   } catch (error) {
  //     console.log(error)
  //     setLoading(false)
  //   }
  // }
  // useEffect(() => {
  //   getmystore()
  // }, [])

  const initialValues = {
    Category: "1",
    Keyword: "",
  };

  //  get product with store id Api..........
  const handlestoreclick = async (id, index) => {
    setload(true);
    try {
      const response = await axios.post(
        "/my-products",
        {
          // StoreId: id.Id
          Keyword: "",
          Category: values.Category,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      console.log(response);
      setload(false);
      setproductdata(response.data.data);
      if (response.data.message === "Record Found.") {
        setshow(true);
      }
      // setproductdata(productdata.filter((image, i) => i !== index))
      // toast.success(response.data.message, {
      //   position: "top-right",
      //   theme: "colored",
      // });
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  useEffect(() => {
    handlestoreclick();
  }, []);

  // delete product API.............
  const handledelete = async (id, index) => {
    try {
      const response = await axios.post(
        "/delete-product",
        {
          ProdId: id.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      if (response.data.message === "Product Deleted Successfully") {
        setproductdata(productdata.filter((image, i) => i !== index));
      }
      toast.success(response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const { values, handleChange, handleBlur, touched } = useFormik({
    initialValues: initialValues,
  });

  useEffect(() => {
    handlestoreclick();
  }, [values.Category]);

  useEffect(() => {
    handlestoreclick();
  }, [values.Keyword]);

  const handleedit = (item) => {
    seteditproductdata(item);
    seteditproduct(true);
  };

  return (
    <>
      {editproduct ? (
        <Editproduct
          seteditproduct={seteditproduct}
          editproductdata={editproductdata}
        />
      ) : (
        <div className="manageproductdiv">
          <div className="manageproduct">
            <div className="managepromaindiv">
              <h3>Manage Products</h3>
              <div
                className="createstorediv"
                onClick={() => navigate("/Addproduct")}
              >
                <img src={plusicon} alt="" />
                <span>Create Product</span>
              </div>
            </div>
            <div className="category">
              <div className="categorysection">
                <span>Category</span>
                <CategoryDrop
                  name={"Category"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  onClick={handlestoreclick}
                />
              </div>
              <div className="search2">
                <img src={search} alt="" />
                <input
                  type="search"
                  name="Keyword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="Search Products"
                />
              </div>
            </div>
          </div>
          {Loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <div className="myproducts">
              <img src={""} alt="" onClick={() => setshow(false)} />
              <div className="nameofproducts">
                <h4>
                  {productdata.map((i) => {
                    return i.Title;
                  })}
                </h4>
                <span>
                  {" "}
                  <b> {productdata.length} </b>Products
                </span>
              </div>
              {productdata.length === 0 ? (
                <h1 style={{ textAlign: "center" }}>No Data</h1>
              ) : (
                <div className="myproductcarddiv">
                  {productdata.map((item, index) => {
                    return (
                      <div className="myproductcard" key={index}>
                        <div className="mystoreimgdiv">
                          <img src={item.Images} alt="" />
                        </div>
                        <div className="mystoreheading2">
                          <div className="mystoreloca">
                            <h4>{item.Title}</h4>
                            <span>
                              {item.Currency}
                              {item.Price}
                            </span>
                          </div>
                          <div className="myproductedit">
                            <img
                              onClick={() => handleedit(item)}
                              src={editicon}
                              alt=""
                            />
                            <img
                              src={deleteicon}
                              alt=""
                              onClick={() => handledelete(item, index)}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
      )}
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default ManageProduct;

{
  /* <div className="mystoreboxdiv">
                {storedata.map((item, index) => {
                  return (
                    Loading ? <div className="loadindiv">
                      <Skeleton variant="rounded" />
                    </div> :
                      <div className="mystorebox" key={index} onClick={() => handlestoreclick(item, index)}>
                        <div className="mystoreimgdiv">
                          <img src={item.Images} alt="" />
                        </div>
                        <div className="storenames">
                          <h4>{item.Name}</h4>
                          <div>
                            <img src={loca} alt="" />
                            <span>{item.Address}</span>
                          </div>
                        </div>
                      </div>
                  );
                })}
              </div>  */
}
