import "./Aboutus.css";
import Container from "../../../Components/Container/Container";
import Button from "../../../Components/Button/Button";
// import img1 from "../../../Assets/Images/whatwedoimg.png";
// import image11 from "../../../Assets/Images/image11.png";
import img88888 from "../../../Assets/Images/img88888.jpg";
import img77777 from "../../../Assets/Images/img77777.png";

// import img2 from "../../../Assets/Images/whatwedoimg2.png";
// import loginicon from "../../../Assets/Icons/loginicon.png";
// import jewellleryicon from "../../../Assets/Icons/jewelleryicon.png";
// import findicon from "../../../Assets/Icons/findicon.png";
// import recievedicon from "../../../Assets/Icons/recievedicon.png";
import slideimg1 from "../../../Assets/Images/slideimg1.png";
import slideimg2 from "../../../Assets/Images/slideimg2.png";
import slideimg3 from "../../../Assets/Images/slideimg3.png";
import slideimg4 from "../../../Assets/Images/slideimg4.png";
import locoicon from "../../../Assets/Icons/locationicon.png";
import { motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
// import required modules
import { Pagination } from "swiper";
import { useNavigate } from "react-router-dom";

const Aboutus = () => {
  const navigate = useNavigate();

  const carddata = [
    {
      cardimg: slideimg1,
      heading: "Williams Jewellers",
      location: locoicon,
      spa: "3.5 km away",
      id: 1,
    },
    {
      cardimg: slideimg2,
      heading: "Williams Jewellers",
      location: locoicon,
      spa: "3.5 km away",
      id: 2,
    },
    {
      cardimg: slideimg3,
      heading: "Williams Jewellers",
      location: locoicon,
      spa: "3.5 km away",
      id: 3,
    },
    {
      cardimg: slideimg4,
      heading: "Williams Jewellers",
      location: locoicon,
      spa: "3.5 km away",
      id: 4,
    },
  ];

  return (
    <>
      <Container>
        <div className="aboutcontent">
          <motion.div
            initial={{ opacity: 0, y: 100 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true, amount: 0.5 }}
            className="about"
          >
            <span>About Us</span>
            <p>
              {/* Enpral Inc. is a new Canadian based tech company that plans to
              provide an invaluable service to the global jewelry industry by
              bringing */}
              The company is registered in Toronto, Canada, coinciding with my
              current residence. Our project entails the development of a
              specialized marketplace app designed to address specific
              challenges within the global jewelry industry, providing
              businesses of all scales with a platform to enhance sales and
              foster brand growth.
            </p>
            <Button className={"btn2"} onClick={() => navigate("/KnowMore")}>
              Learn more
            </Button>
          </motion.div>
          <div className="howits">
            {/* <span>Lorem Ipsum</span> */}
            <motion.h2
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true, amount: 0.5 }}
            >
              How it Works
            </motion.h2>
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true, amount: 0.5 }}
              className="how-it-work-text-div"
            >
              <span className="how-it-work-text">
                A customer looking to purchase a necklace can simply open the
                Enpral app and scroll through the inventory of local jewelry
                stores, if not satisfied the customer can widen the radius and
                view further away jewelry stores. (even in different countries).
                If the customer is looking to have something custom made,
              </span>
            </motion.div>
            <div className="know-more-button-div">
              <Button className="btn2" onClick={() => navigate("/HowWork")}>
                Learn more
              </Button>
            </div>
          </div>

          <div className="whatwedo">
            <div className="leftside">
              <div className="whatwe">
                <span>What we do</span>
                <motion.h2
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.5 }}
                  viewport={{ once: true, amount: 0.5 }}
                >
                  We connect the <br /> jewellry industry.
                </motion.h2>
                <motion.img
                  initial={{ opacity: 0, y: 100 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 }}
                  viewport={{ once: true, amount: 0.5 }}
                  src={img88888}
                  alt=""
                />
              </div>
            </div>
            <div className="rightside">
              <motion.img
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.5 }}
                viewport={{ once: true, amount: 0.5 }}
                src={img77777}
                alt=""
              />
              <div className="test-div-5555">
                <motion.p
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1, delay: 0.2 }}
                  viewport={{ once: true, amount: 0.5 }}
                  className="ghjhgfhfghghgh"
                >
                  Enpral Inc. will provide a platform that will make the process
                  easier by allowing customers looking for a custom piece of
                  jewelry to place a post a project on a “work board” that would
                  be seen by businesses in areas specified by the customer, and
                  if any business is interested in creating the custom piece of
                  jewelry, they can simply get in contact with the customer
                  through the platform’s communication channels to gather more
                  information from the customer and send an offer. The customer
                  can then see all the offers and select the one they desire.
                </motion.p>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <div className="howitwork">
        <div className="nearyou">
          <span>Near you</span>
          <h2>Find Jewelers</h2>
          <Container>
            <div className="slidercard">
              <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{
                  clickable: true,
                }}
                breakpoints={{
                  "@0.00": {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  "@0.75": {
                    slidesPerView: 2,
                    spaceBetween: 20,
                  },
                  "@1.00": {
                    slidesPerView: 3,
                    spaceBetween: 40,
                  },
                  "@1.50": {
                    slidesPerView: 4,
                    spaceBetween: 50,
                  },
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                {carddata.map((curelem, index) => {
                  return (
                    <SwiperSlide key={curelem.id}>
                      <div className="card">
                        <img src={curelem.cardimg} alt="" />
                        <span>{curelem.heading}</span>
                        <div className="loco">
                          <img src={curelem.location} alt="" />
                          <span>{curelem.spa}</span>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </Container>
        </div>
        <Container>
          {/* <div className="client">
            <span>Client Testimonials</span>
            <h2>Our happy Clients</h2>
            <div className="testimonial">
              <Swiper
                loop={true}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                <SwiperSlide>
                  {" "}
                  <div className="sliderpra">
                    <p>
                      Lorem ipsum dolor sit amet consectetur. In tellus accumsan
                      sed id et lectus purus posuere. Facilisis blandit pulvinar
                      malesuada sit eget metus nam faucibus. Vitae risus
                      ultricies vel arcu augue. Ipsum quis vestibulum turpis
                      orci.{" "}
                    </p>
                    <span>- Brooklyn Simmons</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sliderpra">
                    <p>
                      Lorem ipsum dolor sit amet consectetur. In tellus accumsan
                      sed id et lectus purus posuere. Facilisis blandit pulvinar
                      malesuada sit eget metus nam faucibus. Vitae risus
                      ultricies vel arcu augue. Ipsum quis vestibulum turpis
                      orci.{" "}
                    </p>
                    <span>- Brooklyn Simmons</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  {" "}
                  <div className="sliderpra">
                    <p>
                      Lorem ipsum dolor sit amet consectetur. In tellus accumsan
                      sed id et lectus purus posuere. Facilisis blandit pulvinar
                      malesuada sit eget metus nam faucibus. Vitae risus
                      ultricies vel arcu augue. Ipsum quis vestibulum turpis
                      orci.{" "}
                    </p>
                    <span>- Brooklyn Simmons</span>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div> */}
        </Container>
      </div>
    </>
  );
};

export default Aboutus;
