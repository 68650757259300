import React, { useRef, useState, useEffect } from "react";
import "./JewellerPage.css";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import Container from "../../Components/Container/Container";
import { Back, Commonbtn, Downbtn } from "../../Components/Button/Backbutton";
import { useNavigate } from "react-router-dom";
import { Slidersecond } from "../../Components/Slider/Slider";
import editicon from "../../Assets/Icons/editiconblack.png";
import loco from "../../Assets/Icons/locationicon.png";
import ratingimg from "../../Assets/Icons/simplestar.png";
import raingimg2 from "../../Assets/Icons/fillstar.png";
import filtericon from "../../Assets/Icons/filtericon.png";
import FilterModel from "../../Components/Custom/Filter/FilterModel";
import axios from "../../API";
import { CircularProgress } from "@mui/material";
import Footer from "../../Components/Footer/Footer";

const StoreDetail = () => {
  const [read, setread] = useState(false);
  const [open, setopen] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [loading, setloading] = useState(false);
  const [proData, setproductdata] = useState([]);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const navigate = useNavigate();

  const menuref = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (!menuref.current.contains(event.target)) {
        setopen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });
  const id = JSON.parse(localStorage.getItem("storeIDget"));
  const getstoredetail = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/get-store-detail",
        {
          StoreId: id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      setloading(false);
      setstoredata([response.data.data.StoreData]);
      setproductdata(response.data.data.ProductData);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // console.log(storedata)

  useEffect(() => {
    getstoredetail();
  }, []);

  let storeid;
  const handlenavi = (item) => {
    // console.log(item)
    // navigate("/Editstore")
    storeid = item;
  };

  const viewall = (item) => {
    navigate("/viewall", {
      state: {
        item,
      },
    });
  };
  return (
    <>
      <NavbarSecond />
      <div className="backslider">
        <Container>
          <div className="jeweller">
            <Back onClick={() => navigate("/JewellerHome")} />
          </div>
        </Container>
        <Slidersecond />
      </div>
      <Container>
        <div className="maincontnofjewellers">
          {loading ? (
            <div className="manloderdiv">
              <CircularProgress />
            </div>
          ) : (
            storedata.map((item, index) => {
              {
                /* console.log(item); */
              }
              return (
                <div key={index}>
                  <div className="williams">
                    <h2>{item.StoreName}</h2>
                    {/* <div className="chatssbtn" id="editbtn">
                      <button onClick={()=> handlenavi(item)}>
                        <img src={editicon} alt="" />
                        Edit this Store
                      </button>
                    </div> */}
                  </div>
                  <div className="location">
                    <img src={loco} alt="" />
                    <div className="mili">
                      <span>
                        <b>3.5</b> km away
                      </span>
                      <p>{item.StoreAddress}</p>
                    </div>
                  </div>
                  <div className="pragra">
                    <p>
                      {item.StoreDescription}
                      {read ? (
                        <span>
                          {/* Lorem ipsum dolor sit amet consectetur. Sit nunc id
                          dolor tristique aliquet. Morbi mattis tellus lectus
                          odio id lorem lorem nibh. Gravida molestie elementum
                          posuere elementum aliquet platea sed morbi mauris. Sit
                          ullamcorper nunc facilisis parturient nibh. Turpis
                          elementum nullam ultrices ullamcorper sollicitudin
                          convallis ultrices tristique feugiat. Sit et morbi
                          tempus suscipit. Fringilla convallis velit posuere id
                          et. */}
                        </span>
                      ) : null}
                    </p>
                    <Downbtn
                      span={"Read More"}
                      onClick={() => setread(!read)}
                    />
                  </div>
                </div>
              );
            })
          )}

          <hr className="lineee" />
          <div className="productss" ref={menuref}>
            <h2>Products</h2>
            <button onClick={() => setopen(!open)}>
              <img src={filtericon} alt="" />
              Filter
            </button>
            {open ? (
              <>
                <FilterModel ref={menuref} />
              </>
            ) : null}
          </div>

          {proData.map((curelem, i) => {
            const { CategoryName } = curelem;
            if (CategoryName === "Ring") {
              return (
                <Commonbtn
                  key={i}
                  text={"Rings"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}
          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Ring") {
                return ProductData.map((_item, __index) => {
                  {
                    /* console.log(_item)  */
                  }
                  return (
                    <div key={_item.ProductId} className="productcard">
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          {proData.map((curelem, i) => {
            const { CategoryName } = curelem;
            if (CategoryName === "Gold Chain") {
              return (
                <Commonbtn
                  key={i}
                  text={"Gold Chain"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}
          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Gold Chain") {
                return ProductData.map((_item, __index) => {
                  {
                    /* console.log(_item) */
                  }
                  return (
                    <div key={_item.ProductId} className="productcard">
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          {proData.map((curelem, i) => {
            const { CategoryName } = curelem;
            {
              /* console.log(curelem) */
            }
            if (CategoryName === "Bracelet") {
              return (
                <Commonbtn
                  key={i}
                  text={"Bracelet"}
                  span={"View All"}
                  onClick={() => viewall(curelem)}
                />
              );
            }
          })}
          <div className="cardsss">
            {proData.map((curelem) => {
              const { ProductData, CategoryName } = curelem;
              if (CategoryName === "Bracelet") {
                return ProductData.map((_item, __index) => {
                  return (
                    <div key={_item.ProductId} className="productcard">
                      <div className="productimg">
                        <img src={_item.ProductImage} alt="" />
                      </div>
                      <span>{_item.ProductTitle}</span>
                      <div className="loco2">
                        <span>${_item.ProductPrice}</span>
                      </div>
                    </div>
                  );
                });
              }
            })}
          </div>
          <hr className="lineee2" />
          <div className="review">
            <h2>Review</h2>
            <div className="reviewss">
              {storedata.map((curelem, i) => {
                return (
                  <div key={i}>
                    {curelem.StoreReview.map((i, index) => {
                      const ratingCount = i.Rate;
                      const maxRating = 5;
                      const yellowStarCount = Math.min(ratingCount, maxRating);

                      const yellowStarRatingImages = Array.from(
                        { length: yellowStarCount },
                        (_, i) => <img key={i} src={raingimg2} alt="" />
                      );

                      const greyStarCount = maxRating - yellowStarCount;
                      const greyStarRatingImages = Array.from(
                        { length: greyStarCount },
                        (_, i) => (
                          <img
                            key={i + yellowStarCount}
                            src={ratingimg}
                            alt=""
                          />
                        )
                      );

                      const starRatingImages = [
                        ...yellowStarRatingImages,
                        ...greyStarRatingImages,
                      ];
                      return (
                        <div key={index} className="detailes">
                          <div className="namedats">
                            <div className="datesss">
                              <img src={i.CreatedUserPic} alt="" />
                              <span>
                                <div className="reviwstar">
                                  {starRatingImages.map((i) => {
                                    return (
                                      <img
                                        className="ratingimg"
                                        key={i.key}
                                        src={i.props.src}
                                        alt="rating"
                                      />
                                    );
                                  })}
                                </div>
                                {i.CreatedUserName}
                              </span>
                            </div>
                            <span>{i.ReviewAt}</span>
                          </div>
                          <div className="prassss">
                            <p>{i.Review}</p>
                          </div>
                          <hr className="lineee2" />
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
          <Downbtn span={"View All Reviews"} />
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default StoreDetail;
