import React, { useState } from "react";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Addcardschema } from "../../Schema";
import Button from "../../Components/Button/Button";
import { useFormik } from "formik";
import axios from "../../API";
import "./AddCard.css";
import { Backdrop, CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import rightarrow from "../../Assets/Icons/backrightbtn.png";

const AddCard = ({ onClick, updateState }) => {
  const [Loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [IsError, setIsError] = useState("");

  const initialValues = {
    CardName: "",
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#000000",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        iconColor: "#D40000",
        color: "#D40000",
      },
    },
  };
  const stripe = useStripe();
  const element = useElements();

  // addcard API................
  const onSubmit = async () => {
    setLoading(true);
    const card = element.getElement(CardElement);
    const result = await stripe.createToken(card, {
      name: values.CardName,
    });
    if (result.error) {
      setLoading(false);
      setIsError(result.error.message);
      // console.log("error", result.error)
    } else {
      // console.log("result", result)
      setIsError("");
      try {
        const response = await axios.post(
          "/add-card",
          {
            CardToken: result.token.id,
          },
          {
            headers: {
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        if (response.status === 200) {
          updateState();
        }
        setLoading(false);
        // console.log("res.", response);
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } catch (error) {
        console.log("adderror", error);
        toast.error(error.response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        setLoading(false);
      }
    }
  };
  // Cardelement css

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Addcardschema,
      onSubmit,
    });

  return (
    <div>
      <form onSubmit={handleSubmit} className="mainform3">
        <img src={rightarrow} alt="" onClick={onClick} />
        <div className="khaincard">
          <div className="cardelemnt2">
            <Input3
              type={"text"}
              name="CardName"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={"Name"}
              style={{ border: "2px solid #5f666f" }}
            />
          </div>
          {errors.CardName && touched.CardName ? (
            <p className="form-error2"> {errors.CardName} </p>
          ) : null}
          <div className="cardelemnt">
            <CardElement
              options={{ CARD_OPTIONS, hidePostalCode: true }}
              name="CardNumber"
            />
          </div>
          <p className="form-error2">{IsError}</p>
        </div>
        <div className="addbtn">
          <Button className={"savebtn"} type={"submit"}>
            {!Loading ? "Add Card" : "Loading....."}
          </Button>
        </div>
      </form>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </div>
  );
};

export default AddCard;
