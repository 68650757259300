import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Footer from '../../Components/Footer/Footer'
import Navbar from '../../Components/Navbar/Navbar'
import { Slider } from '../../Components/Slider/Slider'
import Aboutus from './Aboutus/Aboutus'
// import { messaging } from "../../Firebase";
// import { getToken } from "firebase/messaging";

const LandingPage = () => {
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const usertype = data?.UserType;

  const navigate = useNavigate()

  useEffect(()=> {
  if(usertype === "Customer"){
      navigate("/Home")
  }else if(usertype === "Jeweller"){
    navigate("/JewellerHome")
  }else if(usertype === "Affiliate"){
    navigate("/AffiliateHome")
  }
  },[usertype])

  return (
    <div>
      <Navbar />
      <Slider />
      <Aboutus />
      <Footer />
    </div>
  )
}

export default LandingPage



// const [permission, setPermission] = useState(Notification.permission);
// const requestPermission = async () => {
//   Notification.requestPermission().then(status => {
//     setPermission(status)
//   })
//   try {
//     if (permission === "granted") {
//       // Generate Token
//       const token = await getToken(messaging, {
//         vapidKey:
//           "BGVah3jOGFoqwhvsl7o0v5bCMaV_2jbGEF-MTFJ2V6-UXu9pFO9O3pODC6aBiMWoZb5aAp4NLLLCGHkmCwE7rvU",
//       });
//       localStorage.setItem("FCM", token)
//       // Send this token  to server ( db)
//       // console.log(token)
//     } else if (permission === "pending") {
//       alert("You denied for the notification");
//     }
//   } catch (error) {
//     console.log(error)
//   }
//   // console.log("afafaaf", permission)
// };
// useEffect(() => {
//   // Req user for notification permission
//   requestPermission();
// }, []);