import React from 'react'
import { Backbtn } from '../../Components/Button/Backbutton'
import Container from '../../Components/Container/Container'
import NavbarSecond from '../../Components/Navbar/NavbarSecond'
import './Successful.css'
import successimg from '../../Assets/Icons/successfultick.png'
import Button from '../../Components/Button/Button'
import { useNavigate } from 'react-router-dom'

const Successful = () => {
   const navigate = useNavigate()
  return (
    <>
    <NavbarSecond/>
    <Container>
        <div className="succesfulmain">
            <Backbtn onClick={()=> navigate(-1)}/>
            <div className="successcontent">
                <img src={successimg} alt="" />
                <div>
                    <h4>Successfully Sent!</h4>
                    <p>Lorem ipsum dolor sit amet consectetur. Turpissed  fermentum praesent tellus.</p>
                </div>
                <Button className={"okbtn"} onClick={()=> navigate("/Profile/Myorders")}>OK</Button>
            </div>
        </div>
    </Container>
    </>
  )
}

export default Successful