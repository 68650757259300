import React, { useEffect, useState } from "react";
import "./MakePayment.css";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import Button from "../../Components/Button/Button";
import { useNavigate } from "react-router-dom";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import axios from "../../API";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Addcardschema } from "../../Schema";
import { Backdrop, CircularProgress } from "@mui/material";
import visa from "../../Assets/Icons/visaicon.png";
import master from "../../Assets/Icons/mastericon.png";
import tick from "../../Assets/Icons/checked.png";
import unselected from "../../Assets/Icons/Radio-Check.jpg";

const MakePayment = ({ step, setstep, addressid, productid }) => {
  const [Loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [IsError, setIsError] = useState("");
  const [componentshow, setcomponentshow] = useState(false);

  const initialValues = {
    CardName: "",
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#000000",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        iconColor: "#D40000",
        color: "#D40000",
      },
    },
  };
  const stripe = useStripe();
  const element = useElements();
  // addcard API................
  const onSubmit = async () => {
    setLoading(true);
    const card = element.getElement(CardElement);
    const result = await stripe.createToken(card, {
      name: values.CardName,
    });
    if (result.error) {
      setLoading(false);
      setIsError(result.error.message);
      setTimeout(() => {
        setIsError();
      }, 5000);
      // console.log("error", result.error)
    } else {
      // console.log("result", result)
      setLoading(false);
      setIsError("");
      try {
        const response = await axios.post(
          "/add-card",
          {
            CardToken: result.token.id,
          },
          {
            headers: {
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        if (response.data.message === "Card Added Successfully") {
          // props.updateState(false)
        }
        // console.log("addres", response)
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } catch (error) {
        console.log("adderror", error);
        toast.error(error.response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
    }
  };
  // Cardelement css
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: Addcardschema,
      onSubmit,
    });

  const navigate = useNavigate();

  return (
    <>
      <div
        className={
          step === 1 || step === 2
            ? "addressboxdiv3notactive"
            : "addressboxdiv3"
        }
      >
        <div className="deleaddress">
          <div>3</div>
          <span>Make Payment</span>
        </div>
        {componentshow ? (
          <form onSubmit={handleSubmit} className="mainform3">
            <div className="khaincard">
              <div className="cardelemnt2">
                <Input3
                  type={"text"}
                  name="CardName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={"Name"}
                />
              </div>
              {errors.CardName && touched.CardName ? (
                <p className="form-error2"> {errors.CardName} </p>
              ) : null}
              <div className="cardelemnt">
                <CardElement
                  options={{ CARD_OPTIONS, hidePostalCode: true }}
                  name="CardNumber"
                />
              </div>
              <p className="form-error2">{IsError}</p>
            </div>
            <div className="addbtn">
              <Button className={"savebtn"} type={"submit"}>
                {!Loading ? "Make Payment" : "Loading....."}
              </Button>
            </div>
          </form>
        ) : (
          <Getcards productid={productid} addressid={addressid} />
        )}
      </div>
    </>
  );
};

export default MakePayment;

export const Getcards = ({ addressid, productid }) => {
  const [loading, setloading] = useState(false);
  const [carddata, setcarddata] = useState([]);
  const [load, setload] = useState(false);
  const [selected, setselected] = useState(null);
  const [cardtoken, setcardtoken] = useState("");
  const [Chargedata, setchargedata] = useState([]);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const navigate = useNavigate();
  // get card API.................
  const getcards = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-card-list", {
        headers: {
          authkey: `Bearer ${token.Token}`,
        },
      });
      // console.log(response)
      setloading(false);
      if (response.data.data) {
        setcarddata(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  // Get Charge API.................
  const Getcharge = async () => {
    try {
      const response = await axios.get("/get-charge-data", {
        headers: {
          authkey: `Bearer ${token.Token}`,
        },
      });
      setchargedata(response.data.data);
      // console.log(response)
    } catch (error) {
      console.log(error);
    }
  };

  const price = +productid.Price;
  const taxRate = +Chargedata.ServiceTax;
  const priceWithTax = price + (price / 100) * taxRate;
  const finalprice = priceWithTax + +Chargedata.ApplicationCharge;

  // Api call useEffect...........
  useEffect(() => {
    getcards();
    Getcharge();
  }, []);

  const handleclick = (index, item) => {
    setselected(index);
    setcardtoken(item?.CardId);
  };

  // create order API..................
  const handlesubmit = async (e) => {
    e.preventDefault();
    if (cardtoken === "") {
      toast.error("Select a Card", {
        position: "top-right",
        theme: "colored",
      });
    } else {
      setload(true);
      try {
        const response = await axios.post(
          "/add-order",
          {
            ProductId: productid.Id,
            AddressId: addressid,
            Subtotal: finalprice,
            ApplicationFees: Chargedata.ApplicationCharge,
            Tax: Chargedata.ServiceTax,
            Total: finalprice,
            CardToken: cardtoken,
          },
          {
            headers: {
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        if (response.data.message === "Order Successfully.") {
          navigate("/Successful");
        }
        setload(false);
        // console.log(response);
      } catch (error) {
        console.log(error);
        setload(false);
      }
    }
  };

  return (
    <>
      {loading ? (
        <div className="circilardiv">
          <CircularProgress />
        </div>
      ) : (
        <form onSubmit={handlesubmit} className="cardshowdiv">
          {carddata.map((item, index) => {
            return (
              <div
                className="savecardone"
                key={index}
                onClick={() => handleclick(index, item)}
                style={{ cursor: "pointer" }}
              >
                <div>
                  <img
                    src={
                      item.CardBrand === "Visa"
                        ? visa
                        : item.CardBrand === "MasterCard"
                        ? master
                        : null
                    }
                    alt=""
                  />
                </div>
                <h4>XXXXXXXXXXXX{item.CardNumber}</h4>
                <img
                  className="dotsimg"
                  src={selected === index ? tick : unselected}
                  alt=""
                />
              </div>
            );
          })}
          <Button className={"savebtn"} type={"submit"}>
            {"Make Payment"}
          </Button>
        </form>
      )}
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};
