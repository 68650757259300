import React, { useEffect, useState } from "react";
import "./Login.css";
import MainPage from "../MainPage";
import messageicon from "../../../Assets/Icons/messageicon.png";
import lockicon from "../../../Assets/Icons/lockicon.png";
import Button from "../../../Components/Button/Button";
import Input from "../../../Components/Custom/Inputs/Input";
import eyeopen from "../../../Assets/Icons/eyeopen.png";
import eyeclose from "../../../Assets/Icons/eyeclose.png";
import Backbutton from "../../../Components/Button/Backbutton";
import { useNavigate } from "react-router-dom";
import { LoginValidation } from "../../../Schema";
import { useFormik } from "formik";
import loginnimg from "../../../Assets/Images/loginimg.png";
import axios from "../../../API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { db } from "../../../Firebase";
import { collection, doc, setDoc } from "@firebase/firestore";

const Login = () => {
  const [open, setopen] = useState(false);
  const [Loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [error, seterror] = useState("")

  const initialValues = {
    Email: "",
    Password: "",
  };

  // const [permission, setPermission] = useState(Notification.permission);
  // const requestPermission = async () => {
  //   Notification.requestPermission().then((status) => {
  //     setPermission(status);
  //   });
  //   try {
  //     if (permission === "granted") {
  //       // Generate Token
  //       const token = await getToken (messaging, {
  //         vapidKey:
  //           "BGVah3jOGFoqwhvsl7o0v5bCMaV_2jbGEF-MTFJ2V6-UXu9pFO9O3pODC6aBiMWoZb5aAp4NLLLCGHkmCwE7rvU",
  //       });
  //       localStorage.setItem("FCM", token);
  //       // Send this token  to server ( db)
  //       // console.log(token)
  //     } else if (permission === "pending") {
  //       alert("You denied for the notification");
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   // console.log("afafaaf", permission)
  // };
  // useEffect(() => {
  //   requestPermission();
  // }, []);

  const onSubmit = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/login",
        {
          Email: values.Email,
          Password: values.Password,
          DeviceTocken: "asadad",
          DeviceType: "Web",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.data.message === "Login Successfully") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
        const data = {
          userrole: response.data.data.UserType,
          name: response.data.data.FullName ?? "",
          email: response.data.data.Email,
          id: response.data.data.Id,
          image:
            response.data.data.ProfilePic !== null
              ? response.data.data.ProfilePic
              : "",
          number: response.data.data.MobileNumber,
          fcmtoken: localStorage.getItem("FCM"),
          countrycode: response.data.data.CountryCode,
          updatedat: new Date(),
        };
        console.log(data);
        const doref = doc(collection(db, "users"), response.data.data.Id);
        setDoc(doref, data)
          .then(() => {
            // console.log("Data successfully set in Firestore");
          })
          .catch((error) => {
            console.error("Error setting data in Firestore:", error);
          });
        localStorage.setItem("enpralData", JSON.stringify(response.data.data));
        // console.log(response.data.data.UserType)
        if (response.data.data.UserType === "Customer") {
          navigate("/Home");
        } else if (response.data.data.UserType === "Jeweller") {
          navigate("/JewellerHome");
        } else if (response.data.data.UserType === "Affiliate") {
          navigate("/AffiliateHome");
        }
      }
      // console.log("loginres....",response)
    } catch (error) {
      console.log("loginerror...", error);
      setLoading(false);
      toast.error(error.response.data.message, {
        position: "top-right",
        theme: "colored",
      });
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: LoginValidation,
      onSubmit,
    });

  return (
    <>
      <MainPage img={loginnimg} heading="Login">
        <form onSubmit={handleSubmit} className="inputssss">
          <div className="inputsdiv">
            <div className="emaildiv">
              <Input
                type={"email"}
                placeholder="Email"
                img={messageicon}
                name="Email"
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.Email && touched.Email ? (
                <p className="form-validation">{errors.Email}</p>
              ) : null}
            </div>
            <div className="pasdiv">
              <Input
                type={open ? "text" : "password"}
                placeholder="Password"
                name={"Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                img={lockicon}
                img2={open ? eyeopen : eyeclose}
                onClick={() => setopen(!open)}
              />
              {errors.Password && touched.Password ? (
                <p className="form-validation">{errors.Password}</p>
              ) : null}
            </div>
          </div>
          <p onClick={() => navigate("/ForgotPassword")} className="forgot">
            Forgot Password?
          </p>
          <Button type={"submit"} className={"btn3"}>
            Login
          </Button>
        </form>
        <p className="regis">
          Don’t have an account?{" "}
          <span onClick={() => navigate("/Register")}>Register.</span>
        </p>
        <Backbutton />
      </MainPage>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default Login;

export function generateChatId(a = "", b = "") {
  return b > a ? `${a}-${b}` : `${b}-${a}`;
}
export function generateProductchatid(a = "", b = "", c = "") {
  return b > a ? `${a}-${b}-${c}` : `${b}-${a}-${c}`;
}
