import React, { useEffect, useState } from "react";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import { useNavigate } from "react-router-dom";
import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import { useFormik } from "formik";
import plusicon from "../../Assets/Icons/plusicon.png";
import closeicon from "../../Assets/Images/closearrow.png";
import axios from "../../API";
import Button from "../../Components/Button/Button";
import { toast } from "react-toastify";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Back } from "../../Components/Button/Backbutton";

const CreateStore = ({ setshow }) => {
  const navigate = useNavigate();
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState("");
  const [address, setAddress] = useState("");
  const [Loading, setLoading] = useState(false);
  const [load, setload] = useState(false);
  const [lat, setlat] = useState(null);
  //   const [lon, setlon] = useState();
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const initialValues = {
    Shopname: "",
    Description: "",
    // Address: "",
  };

  // create gellery API.....................
  async function creategellery(image) {
    setLoading(true);
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(res)
      if (res.data.message === "Image Added Successfully") {
        setLoading(false);
      }
      setmyImgArr((names) => [...names, res.data.data]);
    } catch (error) {
      console.log("thsiis useeffet error", error.response.data);
      setLoading(false);
    }
  }

  // delete gellery API....................
  const handledelete = async (index) => {
    try {
      const res = await axios.post("/delete-gallery", {
        img: myImgArr.map((i) => {
          return i;
        }),
      });
      if (res.data.message === "Image Deleted Successfully") {
        setmyImgArr(myImgArr.filter((image, i) => i !== index));
      }
      // console.log(res)
    } catch (error) {
      console.log(error);
    }
  };

  // ADD STORE API................
  const onSubmit = async () => {
    setload(true);
    // console.log(values)
    try {
      const response = await axios.post(
        "/create-store",
        {
          Name: values.Shopname,
          Address: address,
          Latitude: lat?.lat,
          Longitude: lat?.lng,
          Description: values.Description,
          StoreImage: myImgArr.toString(),
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      if (response.data.status === "failed") {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status === "success") {
        setshow(false);
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      setload(false);
      //   console.log(response)
    } catch (error) {
      console.log(error);
      toast.error(error.data.message, {
        position: "top-right",
        theme: "colored",
      });
      setload(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });
  // console.log(values)
  const handleSelect = async (value, { description }) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setlat(latLng);
    setAddress(value);
  };

  return (
    <>
      <div className="ADDstoremain">
        <Back onClick={() => setshow(false)} />
        <h2>Add Store</h2>
        <form className="addinputs" onSubmit={handleSubmit}>
          <div className="addinputmain">
            <span>Shop Name</span>
            <Input3
              onChange={handleChange}
              name={"Shopname"}
              placeholder={"Shop Name"}
              style={{ border: "2px solid #e2e2e2" }}
            />
          </div>
          <div className="addinputmain">
            <span>Address</span>
            {/* <Input3 onChange={handleChange} name={"Address"} placeholder={"Address Name"} /> */}
            <PlacesAutocomplete
              value={address}
              onChange={(value) => setAddress(value)}
              onSelect={(value, suggestion) => handleSelect(value, suggestion)}
              searchOptions={{ types: ["address"] }}
              apiKey={"AIzaSyBdyGiqApmnKULTzix7VEpMxJUKt15URO8"}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div className="">
                  <input
                    {...getInputProps({
                      placeholder: "Enter an address",
                      className: "location-search-input",
                    })}
                    className="inputdiv3"
                    style={{ border: "2px solid #e2e2e2" }}
                  />
                  <div className="autocomplete-dropdown-container">
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#fafafa",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                          className="addressdropdown"
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
          </div>
          <div className="aboutjewellery">
            <span>Description</span>
            <textarea
              placeholder="Write here"
              name="Description"
              onChange={handleChange}
              id=""
              cols="30"
              rows="15"
            ></textarea>
          </div>
          <div className="uploaddivaddproduct">
            <span>Upload Photos</span>
            <div className="imagessprev">
              <div className={"inputfile2"}>
                <label htmlFor="file">
                  <input
                    onChange={(e) => {
                      setImages({
                        img: URL.createObjectURL(e.target.files[0]),
                      });
                      creategellery(e.target.files[0]);
                    }}
                    id="file"
                    type="file"
                    name="image"
                    accept="image/gif,image/jpeg,image/jpg,image/png"
                    multiple
                    data-original-title="upload photos"
                    // onChange={handleImageChange}
                  />
                  <img src={plusicon} alt="" />
                </label>
              </div>
              <div className="previewdiv">
                {myImgArr.map((url, index) => {
                  return Loading ? (
                    <Skeleton
                      variant="rounded"
                      width={79}
                      height={79}
                      key={index}
                    />
                  ) : (
                    <div className="previewwww" key={index}>
                      <img key={index} src={url ? url : null} alt="" />
                      <div onClick={() => handledelete(index)}>
                        <img src={closeicon} alt="" />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div></div>
          <Button className={"cancelbtn"} type={"submit"}>
            Add Store
          </Button>
        </form>
      </div>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default CreateStore;
