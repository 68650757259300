import React, { useEffect, useState } from "react";
import "./Home.css";
// import Navbar from "../../Components/Navbar/Navbar";
import Container from "../../Components/Container/Container";
import girlimg from "../../Assets/Images/homepagegirl.png";
import Button from "../../Components/Button/Button";
import SortBy from "../../Components/Custom/Sortby/SortBy";
import PaginationSlider from "../../Components/Custom/PaginationSlider/PaginationSlider";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import Footer from "../../Components/Footer/Footer";
import axios from "../../API";
import { useNavigate } from "react-router-dom";
import location from "../../Assets/Icons/locationicon.png";
import { CircularProgress, Skeleton } from "@mui/material";
import { toast } from "react-toastify";

const Home = () => {
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [storedata, setstoredata] = useState([]);
  const [load, setload] = useState(true);
  const [productdata, setproductdata] = useState([]);
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const lat = localStorage.getItem("lat");
  const lon = localStorage.getItem("lon");

  const getcustomerDashboard = async () => {
    // setLoading(true)
    try {
      const res = await axios.post(
        "/get-customer-dashboard",
        {
          Latitude: lat,
          Longitude: lon,
          Keyword: "",
        },
        {
          headers: {
            authkey: `Barear ${token.Token}`,
          },
        }
      );

      if (res.data.message === "Record Found.") {
        setstoredata(res.data.data.StoreData);
        setproductdata(res.data.data.ProductData);
      }
      setLoading(false);
      if (res.data.data.ProductData) {
        setshow(true);
      }
      console.log(res);
    } catch (error) {
      console.log(error);
      setLoading(false);
      if (error?.response.status === 401) {
        toast.error("Authorised failed please login", {
          position: "top-right",
          theme: "colored",
        });
        // localStorage.removeItem("enpralData")
        // navigate("/Login")
      } else {
        navigator.geolocation.getCurrentPosition((position) => {
          localStorage.setItem("lat", position.coords.latitude);
          localStorage.setItem("lon", position.coords.longitude);
        });
        toast.error("Please Allow Location permission for Near Stores");
      }
    }
  };

  useEffect(() => {
    getcustomerDashboard();
  }, []);

  useEffect(() => {
    getcustomerDashboard();
  }, [lat, lon]);

  useEffect(() => {
    setTimeout(() => {
      setload(false);
    }, 3000);
  }, []);

  // console.log(lat,lon)

  const usertype = token?.UserType;

  useEffect(() => {
    if (usertype === "Customer") {
      navigate("/Home");
    }
  }, []);

  const handleclick = async (id) => {
    navigate("/Jeweller", {
      state: {
        id,
      },
    });
  };

  const handleload = () => {
    setload(false);
  };

  return (
    <>
      <NavbarSecond />
      <Container>
        <div className="mainhomepage">
          <div className="homepagemain">
            <div className="tophome">
              <img src={girlimg} alt="" />
              <div className="homecontent">
                <div>
                  <h2>Make your Custome design Jewellery</h2>
                  <p>
                    {/* Lorem ipsum dolor sit amet consectetur cursus sit <br /> dignissim
                    amet fermentum leo. */}
                  </p>
                </div>
                <Button className={"btn4"}>Send Script</Button>
              </div>
            </div>
          </div>
          <div className="sorttt">
            <h3>Jewellers near you</h3>
            <SortBy />
          </div>
          {Loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <div className="cardsssHome">
              {storedata.map((curelem, index) => {
                const imageurls = curelem.StoreImage.split(",");
                const firstimage = imageurls[0];
                return (
                  <div key={curelem.Id}>
                    {load ? (
                      <div className="skeltondiv">
                        <Skeleton variant="rounded" width={250} height={200} />
                        <Skeleton variant="text" width={170} height={10} />
                        <Skeleton variant="text" width={130} height={10} />
                      </div>
                    ) : (
                      <div
                        className="card2"
                        onClick={() => handleclick(curelem)}
                      >
                        <div className="mystoreimgdiv2">
                          <img
                            width={"100%"}
                            height={"200px"}
                            src={curelem.StoreImage}
                            alt=""
                            onLoad={handleload}
                          />
                        </div>
                        <span>{curelem.Title}</span>
                        <div className="loco">
                          <img src={location} alt="" />
                          <span>{curelem.Distance}km</span>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
          {/* <div className="paginationslider">
            <PaginationSlider />
          </div> */}
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default Home;
