import React from 'react'
import usericon from '../../../Assets/Icons/usericon.png'
import logouticon from '../../../Assets/Icons/logouticon.png'
import './ProfileModel.css'
import { useNavigate } from 'react-router-dom'

const ProfileModel = () => {
  
  const navigate = useNavigate()

  const Logouthandler = () => {
    localStorage.removeItem("enpralData")
    localStorage.removeItem("profile")
    navigate("/")
  }

  return (
    <>
        <div className="model">
            <div className="btnone" onClick={()=> navigate("/Profile/Myprofile")}>
                <img src={usericon} alt="" />
                <span>My Profile</span>
            </div>
            <hr className='hrline' />
            <div className="btntwo" onClick={Logouthandler}>
                <img src={logouticon} alt="" />
                <span>Logout</span>
            </div>
        </div>
    </>
  )
}

export default ProfileModel