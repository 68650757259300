import React from "react";
import "./HowWork.css";
import Footer from "../../Components/Footer/Footer";
import Navbar from "../../Components/Navbar/Navbar";
// import isabel from "../../Assets/Images/isabel.jpg";
// import cardmap from "../../Assets/Images/cardmap.jpg";
import img111 from "../../Assets/Images/img111.png";
import img222 from "../../Assets/Images/img222.png";
import img333 from "../../Assets/Images/img333.png";
import img444 from "../../Assets/Images/img444.png";
import img555 from "../../Assets/Images/img555.png";
import img66666 from "../../Assets/Images/img66666.png";
// import img777 from "../../Assets/Images/img777.png";

import { motion } from "framer-motion";

const HowWork = () => {
  return (
    <>
      <Navbar />
      <div>
        <div className="sec1_main">
          <div className="sec1_subMain">
            <div className="heding_text">
              <h1>How it works?</h1>
            </div>
            <div className="sec1_sub">
              <div className="text_box_sec1">
                <motion.p
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  A customer looking to purchase a necklace can simply open the
                  Enpral app and scroll through the inventory of local jewelry
                  stores, if not satisfied the customer can widen the radius and
                  view further away jewelry stores. (even in different
                  countries). If the customer is looking to have something
                  custom made, they can simply click “create custom jewelry” in
                  the app. Details of the specified jewelry are collected
                  through different methods (drop down menus, file attachments,
                  written details). The customer then has Two options.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  1) The customer can choose to send the project to a specific
                  jewelry store and await the approval from the jewelry store.
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                >
                  2) The customer can post the project onto the “Work Board” for
                  local jewelers and beyond to see. The jewelers who are
                  interested in taking on the work can send an offer to the
                  customer and a chat dialog will open between the two parties
                  for better communication. The customer will then have multiple
                  options to choose from. Once the customer finds a jeweler they
                  are satisfied with, and the price is agreed upon, an order is
                  created and sent to the payment page.
                </motion.p>
              </div>
              <div className="img_box_sec1">
                <motion.img
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img111}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sec2_main">
          <div className="sec2_subMain">
            <div className="sec2_sub">
              <div className="img_box_sec2">
                <motion.img
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img222}
                />
              </div>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="text_box_sec2"
              >
                <p>
                  The Custom Jewels work board is a work board that connects
                  jewelry stores to customer looking for custom made jewelry. A
                  customer clicked “create custom jewelry” on the app/website,
                  and a dialog window is opened where information about the
                  custom jewelry is collected, the dialog window lets customers
                  attach files and gives the customer a field to type the
                  description. After the customer has supplied a sufficient
                  amount of information, the customer will have 2 options to
                  proceed with the project.
                </p>
                <p>
                  1) To send the project as a request to a specific jeweler and
                  await the jewelers response. A chat messenger will be provided
                  between the jeweler and the customer to collect more
                  information to get as accurate as possible to the desired
                  description.
                </p>
                <p>
                  2) To post the work on the “Work Board” where it can be viewed
                  by local and global jewelers based on the customers
                  preferences, a jeweler interested in taking on the project can
                  then place a bid on the project and await the customer’s
                  response. Before a jeweler can send an offer, the jeweler can
                  send the customer a message and ask for more information to
                  get an accurate description.
                </p>
                <p>
                  Customer interaction with the work board Customers can place a
                  pin on the map and choose a radius of which jewelers in the
                  selected radius will be able to view the posted project on the
                  work board. Customers can place multiple pins and choose
                  multiple radiuses for each pin across the world. A customer
                  can choose to only have local jewelers in their specific area
                  bid for their work, or they can choose multiple cities with
                  different radiuses, and jewelers in those selected areas will
                  be the only jewelers to see the posted project and bid on it
                </p>
                <p>
                  Jewelry Business (seller) interaction with the work board
                  Jewelers can also use pins and radiuses for places which they
                  wish to provide their services. A jeweler can place a pin in a
                  specific city and choose to only view work on the work board
                  in a specified area.
                </p>
                <p>
                  The workboard will pair jewelers and customers by showing
                  customer’s jewelers from the specific areas where they would
                  like to get their custom jewelry made, and will show Jewelers
                  projects on the work board from the areas that they have
                  selected,
                </p>
                <p>
                  <b>Example:</b> A customer in Toronto has selected the entire
                  Greater Toroton Area, Vancuver, New York, and Los Angeles.
                  Only jewelers in the selected areas will be able to see the
                  work posted by this customer.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="sec3_main">
          <div className="sec3_subMain">
            <div className="sec3_sub">
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="text_box_sec3"
              >
                <p>
                  A Jeweler in New York has selected the Greater Toronto Area as
                  an area they would like to see posted jobs from. (this Jeweler
                  will see the posted work and will be able to bid on it)
                </p>
                <p>
                  A jeweler located in Ottawa has selected to view work
                  available in Toronto will not be able to see the posted work
                  as they are located in an area not selected by the customer.
                </p>
                <p>
                  The customer will be able to view the addresses of jewelry
                  stores that have placed a bid on their project. The jeweler
                  will only see the city of where the customer is located.
                </p>
                <p>
                  In the case of a customer wanting to purchase a ready-made
                  item, the customer can select an item from the inventory and
                  send a request which would be sent to the seller.
                  Communication channels can then be used to come to an
                  agreement around shipping and returns. After an agreement is
                  made, the seller can create an order for the customer which
                  will send them to the payment page.
                </p>
                <p>
                  The order created by the seller should include the return
                  policy if there is one. and the delivery arrangements agreed
                  upon, either in-store pickup, or shipping
                </p>
                <p>
                  If the shipping option is agreed upon, the shipping price
                  agreed upon earlier must be included into the receipt.
                </p>
                <p>
                  The customer must then check a box that they have read and
                  agreed to the return policy for the specific item being
                  purchased. If there is no return policy then the customer must
                  agree to the item being a “non-refundable” item.
                </p>
                <p></p>
              </motion.div>
              <div className="img_box_sec3">
                <motion.img
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img333}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sec4_main">
          <div className="sec4_subMain">
            <div className="sec4_sub">
              <div className="img_box_sec4">
                <motion.img
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img444}
                  alt=""
                />
              </div>

              <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="text_box_sec4"
              >
                <h2>Payment processes & Disputes</h2>
                <p>
                  When a customer and a jeweler come to an agreement on a price
                  and timeline for a custom project. The jeweler will send a
                  final order indicating the price and duration for the
                  completion of the project. Once the customer approves, the
                  customer will be required to pay the amount requested by the
                  jeweler. The payment is made through the Enpral app/website.
                  The customer will be required to pay the full price of the
                  project, and a 5% service fee to Enpral Inc. The payment is
                  then held in escrow until the project is completed and
                  approved by the customer. Enpral Inc. will release the payment
                  to the Jeweler.
                </p>
                <p>
                  When an order is created, jeweler’s must inform the customer
                  of their cancelation fee. The platform will allow each seller
                  to dictate their own cancelation fee. The platform will give
                  customer an initial 24 hours to cancel an order an receive a
                  full refund (service fee included). After the 24 hours, the
                  cancellation process will go as per the agreement between the
                  jeweler and the customer. Jewelers will be given a field while
                  creating an order to dictate how much late cancellation fees
                  will be per order. Jeweler’s will be able to choose either a
                  flat rate ex. $20, or a percentage of the initial transaction.
                  The customer must check a box indicating that they have read
                  and agreed to the cancellation fee of the order before a
                  payment can be made. Service fees are non-refundable after the
                  24 hour period
                </p>
                <p>
                  <b>Service Fees:</b> The platform will charge both parties
                  using the platform a service fee per transaction. There will
                  be NO subscription fees for enrolling a business, or to
                  register an account as a customer. The service fee will be 5%
                  of the transaction from each party.
                </p>
                <p>
                  <b>Example:</b> If a ring costs $100. The service fee will add
                  5% and the price will go to $105. $5 will be taken from the
                  customer And another $5 from the seller, making it a total of
                  10%. = $10 in this case.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="sec5_main">
          <div className="sec5_subMain">
            <div className="sec5_sub">
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="text_box_sec5"
              >
                <p>
                  When a custom order is taken on by a jeweler, before the
                  agreement between the customer and the jeweler can be
                  finalized, the jeweler must provide a duration of time needed
                  to complete the project. This information will be included
                  before the deal is finalized with the customer. If the Jeweler
                  requires additional time, the jeweler can send a request to
                  the customer, and it will be up to the customer the approve or
                  to cancel the order. If the customer chooses to cancel the
                  order, the customer is not charged a cancelation fee.
                </p>
                <p>
                  Jewelry businesses reserve the right to cancel an order at
                  anytime. However, to prevent unwanted behavior, the number of
                  cancellations prompted by a business will appear on their
                  profile for future customers to see.
                </p>
                <p>
                  Customers can request changes to a pending order; the request
                  is sent to the jeweler; the jeweler can then create a branch
                  order attached to the original order. The branch order will
                  include the changes and the additional costs the changes may
                  require. The cost of the original order may be reduced if the
                  change is to downsize or use less material. The new branch
                  order is then sent to the customer for approval. If approved
                  the final invoice will include the original order and display
                  the changes requested and approved by the customer. The final
                  invoice will show the price adjusted in accordance to the
                  changes requested
                </p>
                <p>
                  If the changes result in an increase of the cost of the
                  project, the customer will be required to pay the deference,
                  and the amount is held in escrow along with the original
                  amount.
                </p>
                <p>
                  If the customer does not approve of the adjusted cost of the
                  project after requesting a change, The customer will have Two
                  options.
                </p>
                <p>
                  1) To continue the project based on the original agreement.
                </p>
                <p>
                  2)Cancel the project and pay a small cancelation fee
                  (completely paid to the jewelers for wasted time).
                </p>
                <p>
                  When a change is requested by the customer, the jeweler will
                  also adjust the delivery time needed on the branch order with
                  the price for the customer to approve.
                </p>
                <p>
                  The timeline for each project will be agreed upon in the
                  original order. The jeweler will send the original order with
                  the time required to finish the project, if the jeweler
                  requires additional time to complete, a simple request is sent
                  to the customer and await approval. If the customer does NOT
                  approve and the project remains incomplete on the agreed upon
                  date, the customer will have the option of canceling the order
                  and receiving a full refund. Further disputes can be handled
                  by a skilled customer service team employed by Enpral Inc.
                </p>
              </motion.div>
              <div className="img_box_sec5">
                <motion.img
                  initial={{ opacity: 0, x: 100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img555}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div className="sec6_main">
          <div className="sec6_subMain">
            <div className="sec6_sub">
              <div className="img_box_sec6">
                <motion.img
                  initial={{ opacity: 0, x: -100 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 1 }}
                  src={img66666}
                  alt=""
                />
              </div>
              <motion.div
                initial={{ opacity: 0, x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="text_box_sec6"
              >
                <p>
                  <b>Marketing Plan:</b>
                  Enpral Inc. has come up with a great and innovative marketing
                  strategy to see rapid adoption.The strategy minimizes the
                  marketing costs needed to market the platform in different
                  countries across the globe while remaining extremely
                  effective.
                </p>
                <p>
                  As Enpral acts as a hub and brings together the producer
                  (Jewelers) and the consumers to facilitate deals between them,
                  it will charge a service fee which is charged from both
                  sides.5% from the producer, and 5% from the customer.A total
                  of 10%.
                </p>
                <p>
                  Enpral is willing to share this service fee with marketers who
                  are willing to go out and introduce this platform to jewelry
                  businesses.
                </p>
                <p>
                  The platform will charge the producers 5% on every transaction
                  done through the platform.Ex. If a necklace is sold for $100.
                  Enpral will charge the customer $105.The service fees
                  collected will be a total of $10 of which 5% will go to the
                  marketer in perpetuity.
                </p>
                <p>
                  There is no limit to how many jewelry businesses an affiliate
                  can enroll and benefit from.
                </p>
                <p>
                  This plan takes advantage of the global trend amongst youth
                  everywhere and their desire for passive income.The lucrative
                  opportunity that arises, and the finite amount of jewelry
                  businesses to introduce is limited and thus will have a rapid
                  effect on marketers looking to capitalize off of the
                  opportunity.
                </p>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default HowWork;
