import React, { useContext } from "react";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import { useState } from "react";
import { CircularProgress, Container, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import location from "../../Assets/Icons/locationicon.png";
import { GlobalContext } from "../../Context/GlobalContext";

const Search = () => {
  const [laoding, setloading] = useState(false);
  // const [focus , setfocus] = useState(false)
  const [load, setload] = useState(true);
  const { setsearchhistory, searchdata, setsearchdata } =
    useContext(GlobalContext);

  const navigate = useNavigate();

  const handleclick = async (id) => {
    navigate("/Jeweller", {
      state: {
        id,
      },
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setload(false);
    }, 3000);
  }, []);

  return (
    <>
      <NavbarSecond
      // setsearchdata={setsearchdata}
      // setsearchhistory={setsearchhistory}
      // setloading={setloading}
      />
      {laoding ? (
        <div className="circilardiv">
          <CircularProgress />
        </div>
      ) : (
        <Container>
          {/* {
        focus ? 
        <div className="searchhistory">
          {searchhistory.map((item, index) => {
            return <span>{item.SearchTitle}</span>;
          })}
        </div> : null
      } */}

          <p className="searchresult">Search Result</p>
          <div className="cardsssHome2">
            {searchdata?.Store?.length === 0 ? (
              <h1>No result Found</h1>
            ) : (
              <>
                {searchdata?.Store?.map((curelem, index) => {
                  {
                    /* console.log("Mainhomepage", curelem); */
                  }
                  const imageurls = curelem.StoreImage.split(",");
                  const firstimage = imageurls[0];
                  return (
                    <div key={curelem.Id}>
                      {load ? (
                        <div className="skeltondiv">
                          <Skeleton
                            variant="rounded"
                            width={250}
                            height={200}
                          />
                          <Skeleton variant="text" width={170} height={10} />
                          <Skeleton variant="text" width={130} height={10} />
                        </div>
                      ) : (
                        <div
                          className="card2"
                          onClick={() => handleclick(curelem)}
                        >
                          <div className="mystoreimgdiv2">
                            <img
                              width={"100%"}
                              height={"200px"}
                              src={firstimage}
                              alt=""
                              // onLoad={handleload}
                            />
                          </div>
                          <span>{curelem.Title}</span>
                          <div className="loco">
                            <img src={location} alt="" />
                            <span>{curelem.Distance}km</span>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </Container>
      )}
    </>
  );
};

export default Search;
