import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import girlimg from "../../Assets/Images/logingirlimg.png";
import nullimg from "../../Assets/Icons/usernullimage.png";
import cameraicon from "../../Assets/Icons/cameraicon.png";
import backbtn from "../../Assets/Icons/backrightbtn.png";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import "../MyProfile/MyProfile.css";
import axios from "../../API";
import { useEffect } from "react";

const EditProfile = ({ onClick, profiledata, getprofile, setshow }) => {
  const navigate = useNavigate();
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState("");
  const data = JSON.parse(localStorage.getItem("enpralData"));
  let myimage = myImgArr.map((i) => {
    return i;
  });

  const initialValues = {
    Name: "",
    Email: "",
    Phone: "",
  };

  // console.log(profiledata)

  async function creategellery(image) {
    // setLoading(true);
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(res)
      if (res.data.message === "Image Added Successfully") {
        // setLoading(false);
      }
      setmyImgArr((names) => [...names, res.data.data]);
    } catch (error) {
      // console.log("thsiis useeffet error", error.response.data);
      // setLoading(false);
    }
  }

  useEffect(() => {
    setFieldValue("Name", profiledata.Name);
    setFieldValue("Email", profiledata.Email);
    setImages(profiledata.ProfilePic);
    setFieldValue("Phone", profiledata.MobileNumber);
    // setImages(profiledata.ProfilePic);
  }, []);

  // console.log(profiledata)
  const onSubmit = async () => {
    // const blobData = URL.createObjectURL(image)
    try {
      const res = await axios.post(
        "/update-customer-profile",
        {
          Name: values.Name,
          MobileNumber: values.Phone,
          CountryCode: "+1",
          ProfilePic: myimage.toString(),
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      if (res.data.message === "Profile Updated Successfully.") {
        setshow(false);
        getprofile();
      }
      // console.log(res);
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(image)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    onSubmit,
  });

  return (
    <>
      <form onSubmit={handleSubmit} className="myprofile">
        <h2>Edit Profile</h2>
        <div className="myprofilecontent">
          <img
            src={backbtn}
            alt=""
            onClick={onClick}
            className="backbtnprofile"
          />
          <div className="profilediv">
            <div className="profileavtar">
              <img src={images ? images.img || images : nullimg} alt="" />
            </div>
            <input
              onChange={(e) => {
                setImages({
                  img: URL.createObjectURL(e.target.files[0]),
                });
                creategellery(e.target.files[0]);
              }}
              id="avtar"
              type="file"
              name="image"
              accept="image/gif,image/jpeg,image/jpg,image/png"
              multiple
              data-original-title="upload photos"
            />
            <label htmlFor="avtar">
              <img src={cameraicon} alt="" />
            </label>
          </div>
          <div className="profilenames">
            <div className="namess">
              <span>Name</span>
              <Input3
                name={"Name"}
                type={"text"}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Name"}
                value={values.Name}
              />
            </div>
            <div className="namess">
              <span>Email</span>
              <Input3
                name={"Email"}
                type={"email"}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Email"}
                value={values.Email}
              />
            </div>
            <div className="namess">
              <span>Phone</span>
              <Input3
                name={"Phone"}
                type={"number"}
                onChange={handleChange}
                onBlur={handleBlur}
                placeholder={"Phone"}
                value={values.Phone}
              />
            </div>
          </div>
        </div>
        <div className="editprofilebtn">
          <Button className={"cancelbtn"} onClick={onClick}>
            Cancel
          </Button>
          <Button className={"savebtn"} type={"submit"}>
            Save
          </Button>
        </div>
      </form>
    </>
  );
};

export default EditProfile;
