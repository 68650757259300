import React, { useEffect, useState } from "react";
import "./MyOrder.css";
import staricon from "../../Assets/Icons/star.png";
import RateJewellerModel from "../../Components/Custom/Model/RateJewellerModel";
import axios from "../../API";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";

const MyOrder = () => {
  const [show, setshow] = useState(false);
  const [myorderdata, setmyorderdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [dataforrating, setdataforrating] = useState();
  const navigate = useNavigate();
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const getmyorder = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-customer-order", {
        headers: {
          "content-type": "application/json",
          authkey: `Bearer ${token.Token}`,
        },
      });
      if (response.data.data) {
        setmyorderdata(response.data.data);
        // setimgdata(response.data.data)
      }
      // console.log(response);
      setloading(false);
      if (response.data.message === "No Record Found.") {
        toast.error("No Workboard Found", {
          position: "top-right",
          theme: "colored",
        });
      }
    } catch (error) {
      console.log("error.....", error);
      setloading(false);
    }
  };

  useEffect(() => {
    getmyorder();
  }, []);

  const handleclick = (item) => {
    setdataforrating(item);
    setshow(true);
  };

  const handlenavigate = (item) => {
    // console.log(id)
    navigate("/CompleteOrder", {
      state: {
        item,
      },
    });
  };

  return (
    <>
      <div className="myorderdiv">
        <h3>My Orders</h3>
        {loading ? (
          <div className="manloderdiv">
            <CircularProgress />
          </div>
        ) : (
          <div
            className={
              myorderdata.length > 4 ? "myorderparent" : "myorderparent2"
            }
          >
            <div className="myorderboxdiv">
              {myorderdata.map((item) => {
                return (
                  <div className="myorderbox" key={item.OrderId}>
                    <div className="orderimgmaindiv">
                      <div className="orderimgs">
                        {Array.isArray(item.Images) ? (
                          <img src={item.Images[0]} alt="" />
                        ) : (
                          <img src={item.Images} alt="" />
                        )}
                        <div className="orderhead">
                          <h4>{item.Title}</h4>
                          <span>
                            {item.Currency}
                            {item.Price}
                          </span>
                        </div>
                      </div>
                      <button
                        style={{
                          backgroundColor:
                            item.Status === "Completed" ? "#20a81d" : "red",
                          color:
                            item.Status === "Completed" ? "white" : "white",
                          cursor:
                            item.Status === "CustomerPending"
                              ? "pointer"
                              : "default",
                        }}
                        onClick={
                          item.Status === "CustomerPending"
                            ? () => handlenavigate(item)
                            : null
                        }
                      >
                        {item.Status === "CustomerPending"
                          ? "Pay Now"
                          : item.Status}
                      </button>
                    </div>
                    <p>{item.Description}</p>
                    <div className="orderline"></div>
                    <div className="jewellerdiv">
                      <span>Jewelers</span>
                      <div className="smajhnhiaraha">
                        <div>
                          <img src={item.CreatedUserPic} alt="" />
                          <span>{item.CreatedUserName}</span>
                        </div>
                        {item.Rating === "" ? (
                          <span onClick={() => handleclick(item)}>
                            Rate Now
                          </span>
                        ) : (
                          <div className="ratingdiv">
                            <span>Rated</span>
                            <div>
                              <img src={staricon} alt="" />
                              <span>{item.Rating}.0</span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      {show ? (
        <RateJewellerModel
          setshow={setshow}
          getmyorder={getmyorder}
          dataforrating={dataforrating}
        />
      ) : null}
    </>
  );
};

export default MyOrder;
