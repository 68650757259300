import React, { useEffect, useState } from "react";
import "./Affiliate.css";
import avtar from "../../Assets/Images/affiliateavtar.png";
import deleteicon from "../../Assets/Icons/deleteicon.png";
import chaticon from "../../Assets/Icons/chatsicon.png";
import plus from "../../Assets/Icons/plusicon.png";
import AddAffiliate from "./AddAffiliate";
import axios from "../../API";
import {
  Storeget,
  Storegetselected,
} from "../../Components/Custom/Inputs/JInput";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Skeleton } from "@mui/material";

const Affiliate = () => {
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [showscreen, setshowscreen] = useState(false);
  const [idd, setidd] = useState();
  // const [storedata, setstoredata] = useState([]);
  const [affiliatedata, setaffiliatedata] = useState([]);
  const [loading, setloading] = useState(false);

  const initialValues = {
    store: "",
  };

  const { values, handleChange, handleBlur, setFieldValue } = useFormik({
    initialValues: initialValues,
  });

  //  getAffiliate Api....................
  const getAffiliate = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/get-affiliate-users-for-store",
        {
          StoreId: values.store,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response)
      if (
        response.data.status === "success" &&
        response.data.message === "Record Found."
      ) {
        setaffiliatedata(response.data.data);
      } else if (
        response.data.status === "success" &&
        response.data.message === "No Record."
      ) {
        toast.error("No Affiliate for selected Store", {
          position: "top-right",
          theme: "colored",
        });
        setaffiliatedata([]);
      }
      setloading(false);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getAffiliate();
  }, [values.store]);
  //  getAffiliate Api.................... end

  const handledelete = async (indexx) => {
    try {
      const response = await axios.post(
        "/delete-affiliate-users",
        {
          StoreId: values.store,
          AffId: indexx.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } else if (response.data.status === "failed") {
        toast.error(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      getAffiliate();
      setaffiliatedata(
        affiliatedata.filter((item) => {
          return item.UserId !== indexx;
        })
      );
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(values.store);

  return (
    <>
      {showscreen ? (
        <AddAffiliate setshowscreen={setshowscreen} />
      ) : (
        <div className="affiliatediv">
          <div className="mystoredivtop">
            <h3>Affiliate</h3>
            <div
              className="addaffiliatediv"
              onClick={() => setshowscreen(true)}
            >
              <img src={plus} alt="" />
              <span>Add Affiliate</span>
            </div>
          </div>
          <Storeget
            onChange={handleChange}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            name={"store"}
          />
          <div className="affiliatedatacarddiv">
            {affiliatedata.map((item, index) => {
              return (
                <>
                  {loading ? (
                    <Skeleton />
                  ) : (
                    <div className="affiliatecard" key={index}>
                      <div className="leftsideaffiliate">
                        <div className="affiliateavtar">
                          <img
                            src={item.UserPic === null ? avtar : item.UserPic}
                            alt=""
                          />
                        </div>
                        <div className="affiliatename">
                          <h4>{item.UserName}</h4>
                          <span>
                            PAC No.<b>{item.PacNo}</b>
                          </span>
                        </div>
                      </div>
                      <div className="rightsideaffiliate">
                        <img
                          src={deleteicon}
                          alt=""
                          onClick={() => handledelete(item)}
                        />
                        <img src={chaticon} alt="" />
                      </div>
                    </div>
                  )}
                </>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default Affiliate;
