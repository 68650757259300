import React from 'react'
import './SortBy.css'

const SortBy = () => {
  return (
    <>
        <div className="sortby">
            <span>Sort by</span>
            <div className="dropsort">
            <select name="" id="">
                <option value="Poppular">Poppular</option>
                <option value="Poppular">Poppular</option>
                <option value="Poppular">Poppular</option>
            </select>
            </div>
        </div>
    </>
  )
}

export default SortBy