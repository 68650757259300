import React, { useEffect, useState } from "react";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import editicon from "../../Assets/Icons/editicon.png";
import Container from "../../Components/Container/Container";
import "./ViewOffer.css";
import chaticon from "../../Assets/Icons/chatsicon.png";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../API";
import { CircularProgress } from "@mui/material";
import { generateChatId } from "../AuthPages/Login/Login";
import { collection, doc, getDoc } from "@firebase/firestore";
import { db } from "../../Firebase";

const ViewOffer = () => {
  let route = useLocation();
  const maindata = route.state.item;
  const [Loading, setLoading] = useState(false);
  const [workdata, setworkdata] = useState([]);
  const [idd, setidd] = useState("");
  const token = JSON.parse(localStorage.getItem("enpralData"));

  // console.log(maindata)
  //  iddd = workdata.item.WorkBoardDetail.WorkBoardDetailId

  const getworkboarddetail = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/get-customer-workboard-detail",
        {
          WorkBoardId: maindata.ids,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response);
      setLoading(false);
      if (response.data.message === "Record Found.") {
        setworkdata([response.data.data]);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getworkboarddetail();
  }, []);

  return (
    <>
      <NavbarSecond />
      <Container>
        <form className="viewofferdiv">
          {Loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <>
              {workdata.map((item, index) => {
                return (
                  <>
                    <div key={index} className="handbracelete">
                      <h3>{item.WorkBoard.Title}</h3>
                      <p>{item.WorkBoard.Description}</p>
                      <div className="viewofferimg">
                        {item.WorkBoard.Images.map((item, index) => {
                          return (
                            <div className="imgdivviewoffer" key={index}>
                              <img src={item} alt="" />
                            </div>
                          );
                        })}
                      </div>
                      <div className="vieweditbtn">
                        <img src={editicon} alt="" />
                        <span>Edit Project</span>
                      </div>
                      <div className="viewlinediv"></div>
                      <div className="jewelleroffer">
                        <h3>Jeweller offers</h3>
                        <h4>
                          <span>{item.WorkBoardDetail.length}</span>offers
                        </h4>
                      </div>
                    </div>
                    <div className="viewoffercarddiv">
                      {item.WorkBoardDetail.map((item, index) => {
                        {
                          /* console.log(item); */
                        }
                        return (
                          <KuCH
                            item={item}
                            index={index}
                            getworkboarddetail={getworkboarddetail}
                            key={index}
                          />
                        );
                      })}
                    </div>
                  </>
                );
              })}
            </>
          )}
        </form>
      </Container>
    </>
  );
};

export default ViewOffer;

const KuCH = ({ item, index, getworkboarddetail }) => {
  const imgss = item.StoreImage.split(",");
  let statuss = item.OfferStatus;
  const [anima, setanima] = useState(false);
  const [IsShowData, setIsShowData] = useState(false);
  const [roomId, setroomId] = useState("");
  const [userId, setuserId] = useState("");

  let click1 = "Rejected";
  let click2 = "Accepted";
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const chatid = generateChatId(token.Id, userId);

  useEffect(() => {
    // console.log("kuch")
    if (IsShowData === true) {
      const q = doc(collection(db, chatid));
      getDoc(q).then((snapshot) => {
        if (snapshot._document !== null) {
          // console.log("first")
          setroomId(snapshot.data().roomId);
        } else {
          // setroomId(uuidv4());
        }
      });
    }
  }, [IsShowData]);

  const handlesubmit = async (e) => {
    try {
      const resposne = await axios.post(
        "/update-wordboard-offer-status",
        {
          WorkBoardDetailId: e.WorkBoardDetailId,
          StoreId: e.StoreId,
          Action: click1,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(resposne)
      // console.log(statuss);
      getworkboarddetail();
    } catch (error) {
      console.log(error);
    }
  };
  const handlesubmit2 = async (e) => {
    try {
      const resposne = await axios.post(
        "/update-wordboard-offer-status",
        {
          WorkBoardDetailId: e.WorkBoardDetailId,
          StoreId: e.StoreId,
          Action: click2,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(resposne)
      // console.log(statuss)
      getworkboarddetail();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const setting = setInterval(() => {
      setanima((prevState) => !prevState);
    }, 4000);
    return () => {
      clearInterval(setting);
    };
  }, []);

  const navigate = useNavigate();
  const handlechat = (item) => {
    //  console.log(item)
    if (!userId) {
      setuserId(item.UserId);
    } else {
      navigate("/chat", {
        state: {
          Myname: token.FullName,
          Myid: token.Id,
          OppName: item.StoreName,
          OppId: item.UserId,
          Roomid: chatid,
          Oppimg: item.StoreImage,
          RoomAvail: false,
        },
      });
    }
  };

  return (
    <div className="viewoffercard" key={index}>
      <div className="uperpartdiv">
        <div>
          <div className="upperpartimg">
            <img src={imgss[0]} alt="" />
          </div>
          <div>
            <h4>{item.StoreName}</h4>
            <span>{item.StoreDistance} km away</span>
          </div>
        </div>
        <div className="viewmessagediv" onClick={() => handlechat(item)}>
          <img src={chaticon} alt="" />
        </div>
      </div>
      <p>{item.OfferDescription}</p>
      <div className="offerprice">
        <span>Offer Price:</span>
        <h4>${item.OfferPrice}</h4>
      </div>
      <div className="viewofferbtn">
        {statuss === "Rejected" ? (
          <div
            onClick={() => handlesubmit(item)}
            className={
              statuss === "Pending"
                ? "cancelbtn"
                : statuss === "Rejected"
                ? "cancelbtnred"
                : "cancelbtn"
            }
          >
            {statuss === "Pending"
              ? "Reject"
              : statuss === "Rejected"
              ? "Rejected"
              : "Reject"}
          </div>
        ) : statuss === "Accepted" ? (
          <div
            onClick={() => handlesubmit2(item)}
            className={
              statuss === "Pending"
                ? "savebtn"
                : statuss === "Accepted"
                ? "savebtngreen animate"
                : "savebtn"
            }
          >
            {statuss === "Pending"
              ? "Accept"
              : statuss === "Accepted"
              ? "Accepted"
              : "Accept"}
          </div>
        ) : (
          statuss === "Pending" && (
            <>
              <div
                onClick={() => handlesubmit(item)}
                className={
                  statuss === "Pending"
                    ? "cancelbtn"
                    : statuss === "Rejected"
                    ? "cancelbtnred"
                    : "cancelbtn"
                }
              >
                {statuss === "Pending"
                  ? "Reject"
                  : statuss === "Rejected"
                  ? "Rejected"
                  : "Reject"}
              </div>
              <div
                onClick={() => handlesubmit2(item)}
                className={
                  statuss === "Pending"
                    ? "savebtn"
                    : statuss === "Accepted"
                    ? "savebtngreen animate"
                    : "savebtn"
                }
              >
                {statuss === "Pending"
                  ? "Accept"
                  : statuss === "Accepted"
                  ? "Accepted"
                  : "Accept"}
              </div>
            </>
          )
        )}
      </div>
    </div>
  );
};
