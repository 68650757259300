import React, { useEffect, useState } from "react";
import "./SaveCard.css";
import plusicon from "../../Assets/Icons/plusicon.png";
// import dots from '../../Assets/Icons/threedots.png'
import deleteicon from "../../Assets/Icons/deleteicon.png";
import visa from "../../Assets/Icons/visaicon.png";
import master from "../../Assets/Icons/mastericon.png";
import AddCard from "../../Pages/AddCard/AddCard";
import axios from "../../API";
import { Backdrop, CircularProgress } from "@mui/material";

const SaveCard = () => {
  const [show, setshow] = useState(false);
  const [carddata, setcarddata] = useState([]);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  // get card data API................
  const getcards = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-card-list", {
        headers: {
          authkey: `Bearer ${token.Token}`,
        },
      });
      // console.log(response)
      setloading(false);
      if (response.data.data) {
        setcarddata(response.data.data);
      }
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  useEffect(() => {
    getcards();
  }, []);

  useEffect(() => {
    getcards();
  }, [show]);

  // delete card API..................
  const handledelete = async (id, index) => {
    setloading2(true);
    try {
      const res = await axios.post(
        "/delete-card",
        {
          card_id: id.CardId,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(res)
      setloading2(false);
      if (res.data.message === "Card Delete Suucess.") {
        setcarddata(carddata.filter((card, i) => i !== index));
      }
    } catch (error) {
      console.log(error);
      setloading2(false);
    }
  };

  function updateState(newValue) {
    setshow(newValue);
  }

  return (
    <>
      {!show ? (
        <div className="savedcarddiv">
          <h3>Saved Cards</h3>
          {loading ? (
            <div className="circilardiv">
              <CircularProgress />
            </div>
          ) : (
            <div className="cardshowdiv">
              {carddata.map((item, index) => {
                return (
                  <div className="savecardone" key={index}>
                    <div>
                      <img
                        src={
                          item.CardBrand === "Visa"
                            ? visa
                            : item.CardBrand === "MasterCard"
                            ? master
                            : null
                        }
                        alt=""
                      />
                    </div>
                    <h4>XXXXXXXX{item.CardNumber}</h4>
                    <img
                      src={deleteicon}
                      alt=""
                      className="dotsimg"
                      onClick={() => handledelete(item, index)}
                    />
                  </div>
                );
              })}
            </div>
          )}
          <div className="addnewpaymanet" onClick={() => setshow(true)}>
            <img src={plusicon} alt="" />
            <span>Add New Payment Method</span>
          </div>
        </div>
      ) : (
        <AddCard onClick={() => setshow(false)} updateState={updateState} />
      )}
      {loading2 && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default SaveCard;
