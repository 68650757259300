import React from "react";
import "./Footer.css";
import Container from "../Container/Container";
import Logo from "../../Assets/Logos/logo.png";
import gstore from "../../Assets/Icons/playstoreicon.png";
import appstore from "../../Assets/Icons/appstoreicon.png";
import { Link, NavLink } from "react-router-dom";
import fbicon from "../../Assets/Icons/fbicon.png";
import twittericon from "../../Assets/Icons/twittericon.png";
import linkden from "../../Assets/Icons/linkedinicon.png";
import yticon from "../../Assets/Icons/yticon.png";
import telegram from "../../Assets/Icons/telegram.png";

const Footer = () => {
  const handleTelegramLinkClick = () => {
    window.location.href = "https://t.me/+5YYEKZiWwLUxOTMx";
  };
  const emailAddress = "m.abbad@enpral.com";
  function handleContactClick() {
    // console.log("handleContactClick() called");
    window.location.href = `mailto:${emailAddress}`;
  }

  const currentyear = new Date().getFullYear();

  return (
    <>
      <div className="footer">
        <Container>
          <div className="mainfooterdiv">
            <div className="mainfooter">
              <div className="firstdiv">
                <div className="footerlogo">
                  <NavLink to="/">
                    <img src={Logo} alt="" />
                  </NavLink>
                </div>
                <div className="storelink">
                  <span>Download Our App</span>
                  <div className="storebtn">
                    <Link>
                      <img src={gstore} alt="" />
                    </Link>
                    <Link>
                      <img src={appstore} alt="" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="alllinks">
                <div className="onediv">
                  <span>Shopping Online</span>
                  <div className="linkss">
                    <Link>Jewellery Materials</Link>
                    <Link>Find Jewellers near you</Link>
                    <Link>Delivery & Returns</Link>
                    <Link>Order Tracking</Link>
                    <Link>FAQs</Link>
                  </div>
                </div>
                <div className="twodiv">
                  <span>Support</span>
                  <div className="linkss">
                    <Link>Get help</Link>
                    <a
                      href={`mailto:${emailAddress}`}
                      onClick={handleContactClick}
                    >
                      Contact us
                    </a>
                    <Link>Support</Link>
                  </div>
                </div>
                <div className="threediv">
                  <span>Account</span>
                  <div className="linkss">
                    {/* <Link>
                      Head Office: 785 15h Street, Office <br /> 478 Berlin, De
                      81566
                    </Link> */}
                    {/* <Link>Tel: +14378818566</Link> */}
                    <a
                      href={`mailto:${emailAddress}`}
                      onClick={handleContactClick}
                    >
                      {/* Email: enpralinc@gmail.com{" "} */}
                      Email: m.abbad@enpral.com
                    </a>
                    {/* <span
                      className="ghjkljhghj"
                      onClick={handleTelegramLinkClick}
                    >
                      {" "}
                      Telegram community group: https://t.me/+5YYEKZiWwLUxOTMx
                    </span> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="copyright">
              <hr className="line" />
              <div className="sociallinks">
                <p>© Copyright {currentyear}. All Rights Reserved.</p>
                <div className="social">
                  <Link onClick={handleTelegramLinkClick}>
                    <img src={telegram} alt="" />
                  </Link>
                  <Link>
                    <img src={fbicon} alt="" />
                  </Link>
                  <Link>
                    <img src={linkden} alt="" />
                  </Link>
                  <Link>
                    <img src={twittericon} alt="" />
                  </Link>
                  <Link>
                    <img src={yticon} alt="" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Footer;
