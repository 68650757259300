
import { getFirestore } from "@firebase/firestore";
import { initializeApp } from "firebase/app";



// const firebaseConfig = {
//   // apiKey: "AIzaSyCr7e7Uf6U8yEXKRYQJN4YeEdzWIZKkfmg",
//   // authDomain: "devicetokken.firebaseapp.com",
//   // projectId: "devicetokken",
//   // storageBucket: "devicetokken.appspot.com",
//   // messagingSenderId: "744474876149",
//   // appId: "1:744474876149:web:732b74a7bd9f2ad64ccdbc"
//   apiKey: "AIzaSyDqEdoXrly0AeX5h8VJ9u_2c74zAv6eWow",
//   authDomain: "enpraljeweller.firebaseapp.com",
//   projectId: "enpraljeweller",
//   storageBucket: "enpraljeweller.appspot.com",
//   messagingSenderId: "510673109873",
//   appId: "1:510673109873:web:d4b6c412e0fba148742cd0",
//   measurementId: "G-7YFMLJ64HG"
// };

const firebaseConfig = {
  apiKey: "AIzaSyBdyGiqApmnKULTzix7VEpMxJUKt15URO8",
  authDomain: "enpral.firebaseapp.com",
  projectId: "enpral",
  storageBucket: "enpral.appspot.com",
  messagingSenderId: "418641314215",
  appId: "1:418641314215:web:b5dfdf4675fc18efd5f682",
  measurementId: "G-5XGXK6C3VG"
};

export const app = initializeApp(firebaseConfig); 
export const db = getFirestore(app);


