import React, { useEffect, useState } from "react";
import "./Addnewaddress.css";
import rightarrow from "../../Assets/Icons/backrightbtn.png";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import {
  Dropinput,
  Dropinput2,
  Dropinput3,
} from "../../Components/Custom/Inputs/JInput";
import { useFormik } from "formik";
import axios from "../../API";
import { Backdrop, CircularProgress } from "@mui/material";

const EditAddress = ({ idd, seteditshow, Getmyaddresss }) => {
  const [lat, setlat] = useState(null);
  const [address, setAddress] = useState("");
  // const [addressdata, setaddressdata] = useState([]);
  const [laoding, setloading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const initialValues = {
    Name: "",
    Number: "",
    Address: "",
    Country: "",
    State: "",
    City: "",
    Zipcode: "",
    Latitude: "",
    Longitude: "",
  };

  const handleSelect = async (value, { description }) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setlat(latLng);
    setAddress(value);
  };

  // const addressData = addressdata.find((item) => item.Id === idd);
  // console.log(idd)
  useEffect(() => {
    setAddress(idd?.Landmark);
    setFieldValue("Name", idd?.Name);
    setFieldValue("Number", idd?.HouseNo);
    setFieldValue("Zipcode", idd?.Zipcode);
    setFieldValue("Address", idd?.Location);
    setFieldValue("Longitude", idd?.Longitude);
    setFieldValue("Latitude", idd?.Latitude);
    setFieldValue("Country", idd?.CountryId);
    setFieldValue("State", idd?.StateId);
    setFieldValue("City", idd?.CityId);
  }, [idd]);
  //  console.log(addressData)

  const onSubmit = async () => {
    setloading(true);
    try {
      const response = await axios.post(
        "/edit-address",
        {
          AddressId: idd.Id,
          Name: values.Name,
          HouseNo: values.Number,
          Address: values.Address,
          City: values.City,
          State: values.State,
          Country: values.Country,
          Zipcode: values.Zipcode,
          Landmark: address,
          Latitude: values.Latitude,
          Longitude: values.Longitude,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.message === "Address Updated Successfully") {
        seteditshow(false);
        Getmyaddresss();
      }
      setloading(false);
      // console.log(response);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const { values, handleBlur, handleChange, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  // console.log(values.City, values.State)
  // console.log(values.Country)

  return (
    <>
      <form className="inputmainparent" onSubmit={handleSubmit}>
        <img src={rightarrow} alt="" onClick={() => seteditshow(false)} />
        <div className="addinputparent">
          <div className="addinputtext">
            <div className="addinputmain">
              <span>Name</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Name"}
                placeholder={"Name"}
                value={values.Name}
              />
            </div>
            <div className="addinputmain">
              <span>House Number</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Number"}
                type={"number"}
                placeholder={"House Number"}
                value={values.Number}
              />
            </div>
            <div className="addinputmain">
              <span>Address</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Address"}
                placeholder={"Address"}
                value={values.Address}
              />
            </div>
            <div className="addinputmain">
              <span>Landmark</span>
              <PlacesAutocomplete
                value={address}
                onChange={(value) => setAddress(value)}
                onSelect={(value, suggestion) =>
                  handleSelect(value, suggestion)
                }
                searchOptions={{ types: ["address"] }}
                apiKey={"AIzaSyBdyGiqApmnKULTzix7VEpMxJUKt15URO8"}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div className="">
                    <input
                      {...getInputProps({
                        placeholder: "Landmark",
                        className: "location-search-input",
                      })}
                      className="inputdiv3"
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "suggestion-item";
                        const style = suggestion.active
                          ? {
                              backgroundColor: "#fafafa",
                              cursor: "pointer",
                            }
                          : {
                              backgroundColor: "#ffffff",
                              cursor: "pointer",
                            };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                            className="addressdropdown"
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
            </div>
            <div className="addinputmain">
              <span>Country</span>
              <Dropinput3
                onChange={handleChange}
                name={"Country"}
                value={values.Country}
              />
            </div>
            <div className="addinputmain">
              <span>State</span>
              <Dropinput2
                onChange={handleChange}
                name={"State"}
                value={values.State}
                Cid={values}
              />
            </div>
            <div className="addinputmain">
              <span>City</span>
              <Dropinput
                onChange={handleChange}
                name={"City"}
                value={values.City}
                Stateid={values}
              />
            </div>
            <div className="addinputmain">
              <span>Zipcode</span>
              <Input3
                onBlur={handleBlur}
                onChange={handleChange}
                name={"Zipcode"}
                type={"number"}
                placeholder={"Zipcode"}
                value={values.Zipcode}
              />
            </div>
          </div>
        </div>
        <button type="submit">Edit</button>
      </form>
      {!laoding && <div></div>}
      {laoding && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default EditAddress;
