import React, { useState } from "react";
import closeicon from "../../../Assets/Images/closearrow.png";
import Button from "../../Button/Button";
import { motion, AnimatePresence } from "framer-motion";
import "./ChangePassModel.css";
import { Input2 } from "../Inputs/Input";
import lockblack from "../../../Assets/Icons/lockblackicon.png";
import eyeopen from "../../../Assets/Icons/eyeopenblack.png";
import eyeclose from "../../../Assets/Icons/eyeblackclose.png";
import { useFormik } from "formik";
import axios from "../../../API";
import { toast } from "react-toastify";

const ChangePassModel = ({ onClick, setshow }) => {
  const [open, setopen] = useState(false);
  const [open2, setopen2] = useState(false);
  const [open3, setopen3] = useState(false);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const initialValues = {
    OldPassword: "",
    NewPassword: "",
    ConfirmPassword: "",
  };

  const Modelanimation = {
    hidden: {
      y: -100,
      opacity: 0,
    },
    show: {
      y: 0,
      opacity: 1,
    },
  };

  const onSubmit = async () => {
    try {
      const res = await axios.post(
        "/change-password",
        {
          OldPassword: values.OldPassword,
          NewPassword: values.NewPassword,
          ConfirmPassword: values.ConfirmPassword,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(res);
      toast.success(res.data.message, {
        position: "top-right",
        theme: "colored",
      });
      if (res.data.message === "Password Successfully Updated.") {
        setshow(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  return (
    <>
      <AnimatePresence>
        <div className="modelssss">
          <motion.div
            key={"model"}
            // initial={{ y: -100, opacity: 0 }}
            // whileInView={{ y: 0, opacity: 1 }}
            initial="hidden"
            animate="show"
            variants={Modelanimation}
            exit="hidden"
            transition={{ duration: 0.5, ease: "easeOut" }}
            className="Pcustomizemodel"
          >
            <div className="overflowscroll">
              <div className="closebtndiv">
                <div className="closebtn" onClick={onClick}>
                  <img src={closeicon} alt="" />
                </div>
              </div>
              <form onSubmit={handleSubmit} className="modelcontent">
                <h2>Change Password</h2>

                <div className="mainmodelc">
                  <div className="changeinput">
                    <span>Current Password</span>
                    <Input2
                      type={open ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"OldPassword"}
                      img={lockblack}
                      img2={open ? eyeopen : eyeclose}
                      onClick={() => setopen(!open)}
                    />
                  </div>
                  <div className="changeinput">
                    <span>New Password</span>
                    <Input2
                      type={open2 ? "text" : "password"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      img={lockblack}
                      name={"NewPassword"}
                      img2={open2 ? eyeopen : eyeclose}
                      onClick={() => setopen2(!open2)}
                    />
                  </div>
                  <div className="changeinput">
                    <span>Confirm New Password</span>
                    <Input2
                      type={open3 ? "text" : "password"}
                      img={lockblack}
                      placeholder={"ConfirmPassword"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name={"ConfirmPassword"}
                      img2={open3 ? eyeopen : eyeclose}
                      onClick={() => setopen3(!open3)}
                    />
                  </div>
                  <div className="aboutjewellery"></div>
                  <div className="sendbtnss">
                    <Button className={"btn7"} type={"submit"}>
                      Reset Password
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </motion.div>
        </div>
      </AnimatePresence>
    </>
  );
};

export default ChangePassModel;
