import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { HashRouter } from "react-router-dom";
import "./Fonts/PlayfairDisplaySC-Regular.otf";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { GlobalContextProvider } from "./Context/GlobalContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <GlobalContextProvider>
          <App />
        </GlobalContextProvider>
      </QueryClientProvider>
    </HashRouter>
  </React.StrictMode>
);
