import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import search from "../../Assets/Icons/search.png";
import "./AddAffiliate.css";
import backarrow from "../../Assets/Icons/backrightbtn.png";
import axios from "../../API";
import closeicon from "../../Assets/Images/closearrow.png";
import usernull from "../../Assets/Icons/usernullimage.png";
import { Storeget } from "../../Components/Custom/Inputs/JInput";
import { toast } from "react-toastify";
import { Backdrop, CircularProgress } from "@mui/material";

const AddAffiliate = ({ setshowscreen }) => {
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [seacrhdata, setsearchdata] = useState([]);
  // const [storedata, setstoredata] = useState([])
  const [pacnumber, setpecnumber] = useState("");
  const [Loading, setloading] = useState(false);
  const initialValues = {
    Keyword: "",
    PACNO: "",
    // store: "",
  };

  //   add-affiliate-users api call
  const onSubmit = async () => {
    if (values.PACNO === "") {
      toast.error("Add PAC NO", {
        position: "top-right",
        theme: "colored",
      });
      // }else if(values.store === ""){
      //     toast.error("Select a Store", {
      //         position: "top-right",
      //         theme: "colored",
      //       });
    } else if (values.PACNO !== "") {
      setloading(true);
      try {
        const res = await axios.post(
          "/add-affiliate-users",
          {
            PacNo: values.PACNO,
            // StoreId: values.store,
          },
          {
            headers: {
              authkey: `Bearer ${token.Token}`,
            },
          }
        );

        if (res.data.message === "Affiliate Added Successfully.") {
          setshowscreen(false);
          toast.success(res.data.message, {
            position: "top-right",
            theme: "colored",
          });
        }
        // console.log(res);
        setloading(false);
      } catch (error) {
        console.log(error);
        setloading(false);
      }
    }
  };

  const { values, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });

  //   search-affiliate-users api call
  const searchaffiliate = async () => {
    try {
      const response = await axios.post(
        "/search-affiliate-users",
        {
          Keyword: values.Keyword,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      // console.log(response);
      if (
        response.data.status === "success" &&
        response.data.message === "Record Found."
      ) {
        setsearchdata(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // console.log(values.store)

  useEffect(() => {
    searchaffiliate();
  }, [values.Keyword]);

  // const handledelete = () => {};
  const handleclick = (item) => {
    setpecnumber(item.UserPacNo);
  };

  useEffect(() => {
    setFieldValue("PACNO", pacnumber);
  }, [pacnumber]);

  // console.log(pacnumber, )

  return (
    <>
      <form onSubmit={handleSubmit} className="searchaffiliate">
        <img src={backarrow} alt="" onClick={() => setshowscreen(false)} />
        <div className="mystoredivtop">
          <h3>Affiliate Add</h3>
        </div>
        <div className="search2">
          <img src={search} alt="" />
          <input
            type="search"
            name="Keyword"
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder=""
            className="searchinputaffi"
          />
        </div>
        <div className="affiliatess">
          {seacrhdata.map((item, indexx) => {
            return (
              <div
                className="parentofstorecard"
                key={indexx}
                onClick={() => handleclick(item)}
              >
                {/* <div onClick={() => handledelete(item.Id)}>
                  <img src={closeicon} alt="" />
                </div> */}
                <div className="storegetdiv" key={indexx}>
                  <div className="storeimgdiv">
                    <img
                      src={
                        item.CreatedUserPic === null
                          ? usernull
                          : item.CreatedUserPic
                      }
                      alt=""
                    />
                  </div>
                  <div className="storetext">
                    <h4>{item.Username}</h4>
                    <p>PAC NO: {item.UserPacNo}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <hr />
        <div className="emaildi">
          <span>Add PAC No.</span>
          <input
            type={"number"}
            placeholder="PAC NO."
            onChange={handleChange}
            onBlur={handleBlur}
            name={"PACNO"}
            value={values.PACNO}
            autoComplete="off"
          />
          {/* <Storeget
            name={"store"}
            onChange={handleChange}
            setFieldValue={setFieldValue}
            // setstoredata={setstoredata}
            // storedata={storedata}
            onBlur={handleBlur}
          /> */}
        </div>
        <button type="submit" className="addbtnaffiliate">
          Add
        </button>
      </form>
      {!Loading && <div></div>}
      {Loading && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default AddAffiliate;
