import { createContext, useState } from "react";

export const GlobalContext = createContext();

export const GlobalContextProvider = ({ children }) => {
  const [searchhistory, setsearchhistory] = useState([]);
  const [searchdata, setsearchdata] = useState([]);

  return (
    <GlobalContext.Provider
      value={{ searchhistory, setsearchhistory, searchdata, setsearchdata }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
