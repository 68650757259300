import React from "react";
import "./Rating.css";

const Rating = ({ setratingdata, ratingdata }) => {
  // const [rating, setRating] = useState(0);

  return (
    <div>
      <div className="ratingstar">
        {[...Array(5)].map((star, i) => {
          const ratingValue = i + 1;

          return (
            <label className="rating">
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => setratingdata(ratingValue)}
              />
              <svg
                width="40"
                height="38"
                viewBox="0 0 40 38"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.6272 29.7084L7.62627 37.0379L10.8889 23.3588L0.210938 14.2121L14.227 13.0892L19.6272 0.104187L25.0274 13.0892L39.0455 14.2121L28.3655 23.3588L31.6281 37.0379L19.6272 29.7084Z"
                  fill={ratingValue <= ratingdata ? "#E6B437" : "#e4e5e9"}
                />
              </svg>
            </label>
          );
        })}
      </div>
    </div>
  );
};

export default Rating;
