import React, { useContext, useEffect, useRef, useState } from "react";
import Container from "../Container/Container";
import logo from "../../Assets/Logos/logo.png";
import searchicon from "../../Assets/Icons/search.png";
import notification from "../../Assets/Icons/notificationicon.png";
import Model from "../Custom/Model/Profilemodel";
import "./NavbarSecond.css";
import downarrow from "../../Assets/Icons/downarrowicon.png";
import { NavLink, useNavigate } from "react-router-dom";
import usernull from "../../Assets/Icons/usernullimage.png";
import axios from "../../API";
import { Skeleton } from "@mui/material";
import { GlobalContext } from "../../Context/GlobalContext";

const NavbarSecond = () => {
  // const navigate = useNavigate;
  const [open, setopen] = useState(false);
  const [anima, setanima] = useState(false);
  const [load, setlaod] = useState(true);
  const [search, setsearch] = useState("");
  const [noticount, setnoticount] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const profile = JSON.parse(localStorage.getItem("profile"));
  const { setsearchdata, setsearchhistory } = useContext(GlobalContext);

  const getnotificationlist = async () => {
    try {
      const response = await axios.get("/notification-count", {
        headers: {
          authkey: `Bearer ${data.Token}`,
        },
      });
      //  console.log(response)
      // setnoticount(response.data.data);
      localStorage.setItem("counts", JSON.stringify(response.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  const countts = JSON.parse(localStorage.getItem("counts"));
  // console.log(countts)

  const getprofile = async () => {
    try {
      const res = await axios.post(
        "/get-user-profile-by-id",
        {
          UserId: data.Id,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      localStorage.setItem("profile", JSON.stringify(res.data.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getnotificationlist();
  }, []);

  useEffect(() => {
    getprofile();
  }, []);

  const menuref = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (menuref && menuref.current) {
        if (!menuref?.current.contains(event.target)) {
          setopen(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  });

  const navigate = useNavigate();
  const handleclick = () => {
    if (data.UserType === "Affiliate") {
      navigate("/AffiliateHome");
    } else if (data.UserType === "Jeweller") {
      navigate("/JewellerHome");
    } else if (data.UserType === "Customer") {
      navigate("/Home");
    }
  };

  useEffect(() => {
    if (noticount > 0) {
      const setting = setInterval(() => {
        setanima((prevState) => !prevState);
      }, 3000);
      return () => {
        clearInterval(setting);
      };
    }
  }, []);

  const lat = localStorage.getItem("lat");
  const long = localStorage.getItem("lon");
  const getsearch = async () => {
    if (data.UserType === "Customer") {
      // setloading(true)
      try {
        const res = await axios.post(
          "/search-store-product",
          {
            Keyword: search,
            Latitude: lat,
            Longitude: long,
          },
          {
            headers: {
              authkey: `Bearer ${data?.Token}`,
            },
          }
        );
        // console.log(res);
        setsearchhistory(res.data.data.SearchData);
        // setloading(false)
        if (res.status === 200 && res.data.message === "Record Found.") {
          if (search !== "") {
            setsearchdata(res.data.data);
          } else if (search === "") {
            setsearchdata();
          }
        }
      } catch (error) {
        console.log(error);
        // setloading(false)
      }
    }
  };

  const myBounce = (call, d) => {
    let timer;
    return function (...arg) {
      if (timer) clearTimeout(timer);
      setTimeout(() => {
        call();
      }, d);
    };
  };

  const Betterfunction = myBounce(getsearch, 1000);

  useEffect(() => {
    Betterfunction();
  }, [search]);

  const handleofocus = () => {
    navigate("/search");
  };

  useEffect(() => {
    setTimeout(() => {
      setlaod(false);
    }, 3000);
  }, []);

  return (
    <>
      <nav className="navbarr" ref={menuref}>
        <Container>
          <div className="content2">
            <div className="logo" onClick={handleclick}>
              <img width={""} height={""} src={logo} alt="" />
            </div>
            <ul className="kuchbhi">
              <div className="khainichize">
                {data?.UserType !== "Jeweller" && (
                  <div className="searchparent">
                    <div className="search">
                      <input
                        type="search"
                        placeholder="Find Jewelers"
                        onChange={(e) => setsearch(e.target.value)}
                        // onKeyUp={getsearch}
                        onFocus={handleofocus}
                      />
                      <img
                        width={"30px"}
                        height={"30px"}
                        src={searchicon}
                        alt=""
                        onClick={getprofile}
                      />
                    </div>
                  </div>
                )}
                <NavLink to={"/Notification"} className="notification">
                  {countts > "0" && (
                    <div className={countts !== 0 && "num"}>{countts}</div>
                  )}
                  <img
                    className={anima ? "anima" : ""}
                    src={notification}
                    alt=""
                  />
                </NavLink>
              </div>
              <div className="noti">
                <div className="avtar">
                  <div className="avtarmain" onClick={() => setopen(!open)}>
                    <div className="avtardivmain">
                      <img
                        src={
                          profile &&
                          profile.ShopPhoto &&
                          profile.ShopPhoto[0] !== null
                            ? profile.ShopPhoto[0]
                            : profile && profile.ProfilePic
                            ? profile.ProfilePic
                            : usernull
                        }
                        alt=""
                      />
                    </div>
                    {profile?.length === 0 ? (
                      <Skeleton variant="text" width={100} />
                    ) : (
                      <span>
                        {profile &&
                        profile.Name === null &&
                        profile.ShopName === null
                          ? "User"
                          : profile?.Name || profile?.ShopName}
                        <img src={downarrow} alt="" />
                      </span>
                    )}
                  </div>
                  {open ? <Model /> : null}
                </div>
              </div>
            </ul>
          </div>
        </Container>
      </nav>
    </>
  );
};

export default NavbarSecond;
