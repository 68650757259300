import { Backdrop, CircularProgress, Skeleton } from "@mui/material";
import React, { useState } from "react";
import plusicon from "../../Assets/Icons/plusicon.png";
import closeicon from "../../Assets/Images/closearrow.png";
import Button from "../../Components/Button/Button";
import { Input3 } from "../../Components/Custom/Inputs/Input";
import axios from "../../API";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Sendrequestjeweller from "../JewellerPage/Sendrequestjeweller";
import rightarrow from "../../Assets/Icons/backrightbtn.png";

const CreateWorkboard = ({ mainshow, setmainshow }) => {
  const [myImgArr, setmyImgArr] = useState([]);
  const [images, setImages] = useState("");
  const [load, setload] = useState(false);
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  // create gellery AP.....................
  async function creategellery(image) {
    setLoading(true);
    try {
      const res = await axios.post(
        "/create-gallery",
        {
          img: image,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // console.log(res.data)
      // )
      if (res.data.message === "Image Added Successfully") {
        setLoading(false);
      }
      setmyImgArr((names) => [...names, res.data.data]);
      // setmyImgArr(prevState => {
      //   // Object.assign would also work
      //   return { ...prevState, ...res.data.data };
      // });
    } catch (error) {
      // console.log("thsiis useeffet error", error.response.data);
      setLoading(false);
    }
  }
  // delete gellery API.............
  const handledelete = async (index) => {
    try {
      const response = await axios.post("/delete-gallery", {
        img: myImgArr.map((i) => {
          return i;
        }),
      });

      // console.log(response)
      if (response.data.message === "Image Deleted Successfully") {
        setmyImgArr(myImgArr.filter((image, i) => i !== index));
        // console.log(image)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const initialValues = {
    ItemName: "",
    Description: "",
  };

  const onSubmit = async () => {
    if (values.ItemName === "") {
      toast.error("Item name field is required", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Description === "") {
      toast.error("Description field is required", {
        position: "top-right",
        theme: "colored",
      });
    } else if (myImgArr.length === 0) {
      toast.error("please add  image", {
        position: "top-right",
        theme: "colored",
      });
    } else if (
      myImgArr.length &&
      values.Description !== "" &&
      values.ItemName !== ""
    ) {
      setload(true);
      try {
        const response = await axios.post(
          "/create-customer-workboard",
          {
            Type: "SendToWorkboard",
            Title: values.ItemName,
            Description: values.Description,
            Images: myImgArr.toString(),
            StoreId: "",
            Latitude: localStorage.getItem("lat"),
            Longitude: localStorage.getItem("lon"),
          },
          {
            headers: {
              authkey: `Bearer ${token.Token}`,
            },
          }
        );
        // console.log(response)
        if (response.data.message === "WorkBoard Request Send Successfully") {
          setmainshow(false);
        }
        setload(false);
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      } catch (error) {
        setload(false);
        console.log(error);
      }
    }
  };

  const [reduxdata, setreduxdata] = useState([]);
  const arry = [];
  const handleclick = () => {
    if (values.ItemName === "") {
      toast.error("Item name field is required", {
        position: "top-right",
        theme: "colored",
      });
    } else if (values.Description === "") {
      toast.error("Description field is required", {
        position: "top-right",
        theme: "colored",
      });
    } else if (myImgArr.length === 0) {
      toast.error("please add image", {
        position: "top-right",
        theme: "colored",
      });
    }
    if (
      myImgArr.length &&
      values.Description !== "" &&
      values.ItemName !== ""
    ) {
      setreduxdata([values, myImgArr]);
      if (reduxdata.includes(values, myImgArr)) {
        localStorage.setItem("redux", JSON.stringify(reduxdata));
        setshow(true);
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: "",
      onSubmit,
    });

  // console.log(values.Description, values.ItemName)

  return (
    <>
      {show ? (
        <Sendrequestjeweller setshow={setshow} setmainshow={setmainshow} />
      ) : (
        <div className="ADDstoremain">
          <img src={rightarrow} alt="" onClick={() => setmainshow(false)} />
          <h3>Create Jewellery Request</h3>
          <form className="addinputs" onSubmit={handleSubmit}>
            <div className="addinputtext">
              <div className="addinputmain">
                <span>Item Name</span>
                <Input3
                  onChange={handleChange}
                  name={"ItemName"}
                  placeholder={"Item Name"}
                />
              </div>
              <div className="addinputmain">
                <span>About Jewellery</span>
                <textarea
                  name="Description"
                  id=""
                  cols="30"
                  rows="10"
                  placeholder="Write here..."
                  onChange={handleChange}
                ></textarea>
              </div>
              <div className="uploaddivaddproduct">
                <span>Upload Photos</span>
                <div className="imagessprev">
                  <div className={"inputfile2"}>
                    <label htmlFor="file">
                      <input
                        onChange={(e) => {
                          setImages({
                            img: URL.createObjectURL(e.target.files[0]),
                          });
                          creategellery(e.target.files[0]);
                        }}
                        id="file"
                        type="file"
                        name="image"
                        accept="image/gif,image/jpeg,image/jpg,image/png"
                        multiple
                        data-original-title="upload photos"
                        // onChange={handleImageChange}
                      />
                      <img src={plusicon} alt="" />
                    </label>
                  </div>
                  <div className="previewdiv">
                    {myImgArr.map((url, index) => {
                      return Loading ? (
                        <Skeleton
                          variant="rounded"
                          width={79}
                          height={79}
                          key={index}
                        />
                      ) : (
                        <div className="previewwww" key={index}>
                          <img key={index} src={url ? url : null} alt="" />
                          <div onClick={() => handledelete(index)}>
                            <img src={closeicon} alt="" />
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="createworkbtn">
                <Button
                  className={"cancelbtn2"}
                  type={"button"}
                  onClick={handleclick}
                >
                  Send Request To Jeweller
                </Button>
                <Button className={"savebtn2"} type={"submit"}>
                  Send Request To Workboard
                </Button>
              </div>
            </div>
          </form>
        </div>
      )}
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default CreateWorkboard;
