import React from "react";
import "./Viewallproduct.css";
import axios from "../../API";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import NavbarSecond from "../../Components/Navbar/NavbarSecond";
import { CircularProgress, Container } from "@mui/material";
import { Back } from "../../Components/Button/Backbutton";
import PaginationSlider from "../../Components/Custom/PaginationSlider/PaginationSlider";

const Viewallproduct = () => {
  const [productdata, setproductdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [pageid, setpageid] = useState(1);
  const data = JSON.parse(localStorage.getItem("enpralData"));
  const route = useLocation();
  const idd = route.state.item.CategoryId;

  const getallproduct = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        "/all-product-list",
        {
          CategoryId: idd,
          StoreId: "",
          PageId: pageid,
          PerPage: "4",
          ByMaterial: "",
          MinPrice: "",
          MaxPrice: "",
          ProductType: "",
          Styling: "",
          MinWeight: "",
          MaxWeight: "",
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      setproductdata(res.data.data);
      setloading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  //   console.log(productdata);

  useEffect(() => {
    getallproduct();
  }, []);

  const navigate = useNavigate();

  const handleclick = async (id) => {
    navigate("/Product", {
      state: {
        id,
      },
    });
  };

  const handlenext = () => {
    setpageid((prev) => prev + 1);
    getallproduct();
  };
  const handleprev = () => {
    setpageid((prev) => prev - 1);
    getallproduct();
  };

  // console.log(productdata)
  return (
    <>
      <NavbarSecond />
      <Container>
        <div className="viewallcontainer">
          <div className="jeweller">
            <Back onClick={() => navigate(-1)} />
            {/* <div>
              {pageid > 1 && <button onClick={handleprev}>Prev</button>}
                <button onClick={handlenext}>Next</button>
            </div> */}
          </div>
          {loading ? (
            <div className="viewallloader">
              <CircularProgress />
            </div>
          ) : (
            <div className="cardsss">
              {productdata?.Product?.map((_item) => {
                return (
                  <div
                    key={_item.ProductId}
                    className="productcard"
                    onClick={() => handleclick(_item)}
                  >
                    <div className="productimg">
                      <img src={_item.ProductImage} alt="" />
                    </div>
                    <span>{_item.ProductTitle}</span>
                    <div className="loco2">
                      <span>${_item.ProductPrice}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div>
          <PaginationSlider
            productdata={productdata}
            handlenext={handlenext}
            handleprev={handleprev}
          />
        </div>
      </Container>
    </>
  );
};

export default Viewallproduct;
