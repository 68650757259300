import React, { useEffect, useRef, useState } from "react";
import "./MessageBox.css";
// import userimg from "../../Assets/Images/avtar.png";
import { db } from "../../Firebase";
import usernull from "../../Assets/Icons/usernullimage.png";
import { v4 as uuidv4 } from "uuid";
import {
  addDoc,
  collection,
  query,
  orderBy,
  onSnapshot,
  setDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import sendicon from "../../Assets/Icons/send.png";
import { useLocation } from "react-router-dom";

const MessageBox = ({ toggle, settoggle, setlistmessagedata }) => {
  const [message, setmessage] = useState("");
  const [messagedata, setmessagedata] = useState([]);
  const [meData, setMeData] = useState(null);
  const [load, setlaod] = useState(false);
  const divforscroll = useRef(null);
  let route = useLocation();
  let Maindata = route.state;
  const data = JSON.parse(localStorage.getItem("enpralData"));

  // console.log(Maindata)

  // chat data set.............................
  useEffect(() => {
    if (Maindata) {
      const q = query(
        collection(db, "chatrooms", Maindata?.Roomid, "mychat"),
        orderBy("time", "asc")
      );
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const arr = [];
        snapshot.forEach((doc) => {
          arr.push(doc.data());
        });
        setmessagedata(arr);
        setlaod(false);
      });
      return unsubscribe;
    }
  }, [Maindata]);

  const updateMessageCount = async () => {
    if (Maindata) {
      try {
        const userRef = doc(db, "users", data.Id, "mychat", Maindata?.OppId);
        await updateDoc(userRef, { unreadcount: "0" });
        console.log("Message count updated successfully.");
      } catch (error) {
        console.error("Error updating message count:", error);
      }
    }
  };

  useEffect(() => {
    updateMessageCount();
  }, [Maindata]);

  // console.log(meData)

  const fetchMeData = async () => {
    try {
      const meRef = doc(db, "users", Maindata.OppId, "mychat", data.Id);
      const meDoc = await getDoc(meRef);
      if (meDoc.exists()) {
        setMeData(meDoc.data());
        // console.log(meDoc.data())
      } else {
        console.log("Document does not exist");
      }
    } catch (error) {
      console.error("Error fetching document:", error);
    }
  };

  // useEffect(() => {
  //   const q = query(collection(db, "chatrooms", Maindata.Roomid, "mychat"), orderBy("time", 'asc')) ;
  //   const unsubscribe = onSnapshot(q, (snapshot) => {
  //     const arr = [];
  //     snapshot.forEach((doc) => {
  //       arr.push(doc.data());
  //     });
  //     setmessagedata(arr);
  //   });
  //   return unsubscribe;
  // }, [Maindata]);

  // const updatePreviousMessages = async () => {
  //   const collectionRef = collection("4b3f1973-0ff6-458d-b88f-e24144a63473");
  //   const querySnapshot = await collectionRef.where('to', '==', Maindata.OppId) // Assuming OppId is the recipient's ID
  //     .where('seen', '==', false) // Fetch only unseen messages
  //     .get();

  //   const batch = db.batch();
  //   querySnapshot.forEach((doc) => {
  //     console.log(doc)
  //     const messageRef = collectionRef.doc(doc.id);
  //     batch.update(messageRef, { seen: true });
  //   });

  //   await batch.commit();
  // };

  // onsend message function.................................
  const OnsendMessages = async (e) => {
    var sentMsg = message;
    e.preventDefault();
    setmessage("");
    const messdata = {
      message: message,
      fromid: Maindata.Myid,
      time: Date.now() * 1000,
      seen: false,
    };
    fetchMeData();
    const otherdata = {
      chatid: Maindata.Roomid,
      lastmessageid: data.Id,
      lastmessage: sentMsg,
      lastmessagetime: Date.now() * 1000,
      unreadcount: "0",
    };
    const mydata = {
      chatid: Maindata.Roomid,
      lastmessageid: data.Id,
      lastmessage: sentMsg,
      lastmessagetime: Date.now() * 1000,
      unreadcount: (parseInt(meData?.unreadcount, 10) || 0) + 1,
    };
    sendMessage(messdata);
    // for save last message in my
    const doref = doc(collection(db, "users"), data.Id);
    const ndoref = doc(collection(doref, "mychat"), Maindata.OppId);
    setDoc(ndoref, otherdata);

    // for save last message in ohter
    const oppdoref = doc(collection(db, "users"), Maindata.OppId);
    const oppndoref = doc(collection(oppdoref, "mychat"), data.Id);
    setDoc(oppndoref, mydata);
  };

  // sendmessage............................
  const sendMessage = async (mess) => {
    const docRef = await addDoc(
      collection(db, "chatrooms", Maindata.Roomid, "mychat"),
      mess
    );
  };

  function splitTextIntoLines(message) {
    const maxLength = 25;
    const chunks = [];

    for (let i = 0; i < message.length; i += maxLength) {
      chunks.push(message.substr(i, maxLength));
    }

    return chunks.map((chunk, index) => (
      <React.Fragment key={index}>
        {chunk}
        <br />
      </React.Fragment>
    ));
  }

  return (
    <>
      <div className={toggle ? "boxmaindivactive" : "boxmaindiv"}>
        <div className="boxheader">
          <div>
            <div className="chatboxheader">
              <img src={Maindata?.Oppimg || usernull} alt="" />
              <h3>{Maindata?.OppName === null ? "User" : Maindata?.OppName}</h3>
            </div>
            <h4
              className="listbtn"
              style={{ textAlign: "end" }}
              onClick={() => settoggle(false)}
            >
              List
            </h4>
          </div>
        </div>
        <div className="chatmessages">
          {messagedata?.map((item, index) => {
            const itemTimeMicroseconds = item.time;
            const itemTimeMilliseconds = Math.floor(
              itemTimeMicroseconds / 1000
            );
            const createdTime = new Date(itemTimeMilliseconds);
            const formattedTime = createdTime.toLocaleTimeString("en-US", {
              hour: "numeric",
              minute: "numeric",
            });
            return (
              <div key={uuidv4()}>
                <p
                  className={item.fromid === data.Id ? "chatp2" : "chatp"}
                  style={{
                    alignSelf:
                      item.fromid === data.Id ? "flex-end" : "flex-start",
                  }}
                  id="targetMessage"
                >
                  {/* {item.message} */}
                  {splitTextIntoLines(item.message)}
                </p>
                {/* <span>{item.seen ? "seen" : "unseen"}</span> */}
                <span
                  style={{
                    alignSelf:
                      item.fromid === data.Id ? "flex-end" : "flex-start",
                  }}
                >
                  {formattedTime === "Invalid Date" ? "" : formattedTime}
                </span>
                <pre ref={divforscroll}></pre>
              </div>
            );
          })}
        </div>
        <div className="sendmessage">
          <textarea
            value={message}
            onChange={(e) => setmessage(e.target.value)}
            type="text"
            placeholder="Write here"
          />
          <button
            type="submit"
            disabled={message === "" ? "disable" : ""}
            onClick={OnsendMessages}
          >
            <img src={sendicon} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};

export default MessageBox;
