import React, { useState } from "react";
import "./Checkbox.css";
import checkboxicon from "../../../Assets/Icons/checkboxicon.png";
import uncheck from "../../../Assets/Icons/unchecked.png";

const Checkbox = ({ checked, onChange }) => {
  return (
    <>
      <div className="checkbox" onClick={onChange}>
        <img src={checked ? checkboxicon : uncheck} alt="" />
      </div>
    </>
  );
};

export default Checkbox;
