import React, { useState } from "react";
import { motion } from "framer-motion";
import "./RateJewellerModel.css";
import closeicon from "../../../Assets/Images/closearrow.png";
import Button from "../../Button/Button";
import Rating from "../Rating/Rating";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import axios from "../../../API";
import { Backdrop, CircularProgress } from "@mui/material";

const RateJewellerModel = ({ setshow, getmyorder, dataforrating }) => {
  const [load, setload] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));
  const [ratingdata, setratingdata] = useState(1);

  const initialValues = {
    Review: "",
  };

  const onSubmit = async () => {
    setload(true);
    try {
      const response = await axios.post(
        "/send-rating",
        {
          OrderId: dataforrating?.OrderId,
          Rate: ratingdata,
          Review: values.Review,
        },
        {
          headers: {
            "constent-type": "application/json",
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      setload(false);
      if (response.data.status === "success") {
        toast.success(response.data.message, {
          position: "top-right",
          theme: "colored",
        });
      }
      if (response.data.message === "Rating Add Successfully") {
        setshow(false);
        getmyorder();
      }
      // console.log(response)
    } catch (error) {
      console.log(error);
      setload(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      onSubmit,
    });
  return (
    <>
      <form onSubmit={handleSubmit} className="modelssss">
        <motion.div
          key={"model"}
          initial={{ y: -100, opacity: 0 }}
          whileInView={{ y: 0, opacity: 1 }}
          exit={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.5, ease: "easeOut" }}
          className="Pcustomizemodel"
        >
          <div className="overflowscroll">
            <div className="closebtndiv">
              <div className="closebtn" onClick={() => setshow(false)}>
                <img src={closeicon} alt="" />
              </div>
            </div>
            <div className="modelcontent">
              <h2>Rate this jeweller</h2>
              <div className="williamsdiv">
                <img src={dataforrating?.CreatedUserPic} alt="" />
                <h4>{dataforrating?.CreatedUserName}</h4>
              </div>
              <div className="mainmodelc">
                <Rating setratingdata={setratingdata} ratingdata={ratingdata} />
                <div className="aboutjewellery">
                  <span>Write Your review</span>
                  <textarea
                    placeholder="Write here"
                    name="Review"
                    id=""
                    cols="30"
                    rows="15  "
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                </div>
                <div className="sendbtnss">
                  <Button type={"submit"} className={"btn7"}>
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </form>
      {!load && <div></div>}
      {load && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default RateJewellerModel;
