import React, { useEffect, useState } from "react";
import "./Chat.css";
import List from "./List";
import MessageBox from "./MessageBox";
import { useLocation } from "react-router-dom";
import ProductchatBox from '../../Pages/Message/ProductchatBox'
// import Messagesecond from "./Messagesecond";

const Chat = () => {
  const [showscreen, setshowscreen] = useState(false);
  const [toggle, settoggle] = useState(false)

  const isdata = localStorage.getItem("IsScreen");
  // console.log(showscreen);
  const { pathname } = useLocation();

  // console.log(listmessagedata)

  useEffect(() => {
    if (pathname !== "/Profile/Chat") {
      setshowscreen(false);
    }
  }, [pathname]);
  return (
    <>
      <div className="chatsdiv">
        <List setshowscreen={setshowscreen} settoggle={settoggle} toggle={toggle} />
        {isdata ? <MessageBox toggle={toggle} settoggle={settoggle} /> : null}
        {/* <Messagesecond/> */}
      </div>
    </>
  );
};

export default Chat;
