import React from "react";
import "./KnowMore.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/Footer/Footer";
// import jesus from "../../Assets/Images/jesus.jpg";
import image88 from "../../Assets/Images/image88.png";
import { motion } from "framer-motion";
import Container from "../../Components/Container/Container";

const KnowMore = () => {
  return (
    <>
      <Navbar />
      <div className="know-more-main-div">
        <Container>
          <div className="child-div-of-about">
            <div className="two-three-more-div">
              <div>
                <span className="about-us-span-span111">About us</span>
              </div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="child-of-about-sapn">
                  {/* Enpral Inc. is a new Canadian based tech company that plans to
                provide an invaluable service to the global jewelry industry by
                bringing jewelry businesses together in a single hub. The
                platform will give jewelry businesses of all sizes anywhere in
                the world the ability to showcase inventory and to take on
                requests for custom made jewelry. */}
                  The company is registered in Toronto, Canada, Our project
                  entails the development of a specialized marketplace app
                  designed to address specific challenges within the global
                  jewelry industry, providing businesses of all scales with a
                  platform to enhance sales and foster brand growth.
                </span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="child-of-about-sapn">
                  {/* Small jewelry businesses in obscure locations that don’t have
                  a solid marketing plan will have a platform to build their
                  brand and showcase their inventory. And for jewelers who have
                  the skills to create jewelry, it would allow them to showcase
                  their skills and to gain popularity to expand their business.
                  The platform development is nearly completed and has been
                  fully funded. The platform is expected to go live as early as
                  the summer of 2023. */}
                  For comprehensive details, please visit our website enpral.com
                  and navigate to the "how it works" section. Currently, the
                  website serves as an informational resource, as the platform
                  remains in development. A pivotal component of the platform,
                  known as the work board, embodies a unique approach for which
                  I have filed a Utility patent in the US to establish legal
                  ownership and safeguard the associated concepts for a maximum
                  of 20 years. The patent application underwent rigorous
                  drafting by adept legal professionals to ensure its watertight
                  integrity. I am willing to share the application for your
                  review upon request.
                </span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="child-of-about-sapn">
                  {/* You probably have many questions going through your head after
                  reading the introduction above. This article aims to be as
                  descriptive as possible, and hopefully by the end of this
                  article you will have all the answers to the questions going
                  through your head. */}
                  Funding for the project has primarily stemmed from personal
                  investment, supplemented by contributions from family and
                  friends. The app's development progress stands at
                  approximately 95% completion, if not higher. To progress to
                  the next phase, I am in the process of recruiting
                  professionals to oversee various departments of the company,
                  encompassing accounting, business operations, and technical
                  aspects. Securing capital is imperative to facilitate the
                  hiring of full-time staff and sustain operational needs.
                </span>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
              >
                <span className="child-of-about-sapn">
                  To get custom jewelry made in today’s age, you would have to
                  go through the hassle of contacting jewelry businesses one by
                  one and explaining your needs to each one, and getting quotes
                  from each one, and in the end you may or may not get the
                  results you’re looking for.
                </span>
              </motion.div>
            </div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 1 }}
              className="margin-of-div"
            >
              <img src={image88} alt="" className="isbel-one-one-image" />
            </motion.div>
          </div>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default KnowMore;
