import React, { useEffect, useState } from "react";
import plusicon from "../../Assets/Icons/plusicon.png";
import axios from "../../API";
import "./Addnewaddress.css";
import AddnewAddress from "./AddnewAddress";
import { Backdrop, CircularProgress } from "@mui/material";
import deleteicon from "../../Assets/Icons/deleteicon.png";
import EditAddress from "./EditAddress";
import { useLocation } from "react-router-dom";

const NewAddress = () => {
  const [show, setshow] = useState(false);
  const [editshow, seteditshow] = useState(false);
  const [address, setaddress] = useState([]);
  const [idd, setidd] = useState([]);
  const [loading, setloading] = useState(false);
  const [loading2, setloading2] = useState(false);
  const token = JSON.parse(localStorage.getItem("enpralData"));

  const Getmyaddresss = async () => {
    setloading(true);
    try {
      const response = await axios.get("/get-my-address", {
        headers: {
          "content-type": "application/json",
          authkey: `Bearer ${token.Token}`,
        },
      });
      if (response.data.data) {
        setaddress(response.data.data);
      }
      // console.log(response);
      setloading(false);
      if (response.data.message === "No Record Found.") {
      }
    } catch (error) {
      console.log("error.....", error);
      setloading(false);
    }
  };

  useEffect(() => {
    Getmyaddresss();
  }, []);

  //   address delete API............
  const handledelete = async (item) => {
    setloading2(true);
    try {
      const response = await axios.post(
        "/delete-address",
        {
          AddressId: item.Id,
        },
        {
          headers: {
            authkey: `Bearer ${token.Token}`,
          },
        }
      );
      if (response.data.message === "Addess Deleted Successfully.") {
        Getmyaddresss();
      }
      setloading2(false);
      // console.log(response);
    } catch (error) {
      console.log(error);
      setloading2(false);
    }
  };

  const handledit = (item) => {
    setidd(item);
    seteditshow(true);
  };

  const route = useLocation();
  const route2 = useLocation();
  let iddd = route?.state?.item;
  let i = route2?.state?.h;
  // console.log(iddd.Id)

  useEffect(() => {
    if (iddd) {
      setidd(iddd);
      seteditshow(true);
    }
  }, [iddd]);

  useEffect(() => {
    if (i) {
      setshow(true);
    }
  }, [i]);

  //  console.log(i)

  return (
    <>
      {editshow ? (
        <EditAddress
          idd={idd}
          seteditshow={seteditshow}
          Getmyaddresss={Getmyaddresss}
        />
      ) : (
        <>
          {show ? (
            <AddnewAddress setshow={setshow} Getmyaddresss={Getmyaddresss} />
          ) : (
            <div className="newaddressparent">
              <div className="mystoredivtop">
                <h3>My Address</h3>
                <div onClick={() => setshow(true)}>
                  <img src={plusicon} alt="" />
                  <span>Add Address</span>
                </div>
              </div>

              <div className="showaddressparent">
                {loading ? (
                  <CircularProgress />
                ) : (
                  <>
                    {address.map((item, index) => {
                      return (
                        <div className="addreschild" key={index}>
                          <div>
                            <h3>{item.Name}</h3>
                            <img
                              src={deleteicon}
                              alt=""
                              onClick={() => handledelete(item)}
                            />
                          </div>
                          <p>
                            {item.HouseNo} {item.Location} {item.Zipcode}
                          </p>
                          <button onClick={() => handledit(item)}>
                            Edit Address
                          </button>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
      {!loading2 && <div></div>}
      {loading2 && (
        <div>
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={true}
            // onClick={handleClose}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
      )}
    </>
  );
};

export default NewAddress;
