import React, { useState } from "react";
import Button from "../../Components/Button/Button";
import Upload from "../../Components/Custom/UploadImage/Upload";
import JewelerEditprofile from "./JewelerEditprofile";
import "./JewellerProfile.css";
import axios from "../../API";
import { useEffect } from "react";
import { Skeleton } from "@mui/material";

const JewellerProfile = () => {
  const [show, setshow] = useState(false);
  const [load, setlaod] = useState(true);
  const [profiledata, setprofiledata] = useState([]);
  const data = JSON.parse(localStorage.getItem("enpralData"));

  const getprofile = async () => {
    setlaod(true);
    try {
      const res = await axios.post(
        "/get-user-profile-by-id",
        {
          UserId: data.Id,
        },
        {
          headers: {
            authkey: `Bearer ${data.Token}`,
          },
        }
      );
      // console.log(res)
      setlaod(false);
      setprofiledata(res.data.data);
      localStorage.setItem("profile", JSON.stringify(res.data.data));
    } catch (error) {
      console.log(error);
      setlaod(false);
    }
  };

  useEffect(() => {
    getprofile();
  }, []);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setlaod(false);
  //   }, 3000);
  // }, []);

  // console.log(JSON.parse(profiledata.ShopPhoto[0]))
  return (
    <>
      {!show ? (
        <div className="myprofile">
          <h3>My Profile</h3>
          <div className="jewellerprofile">
            <div className="jewellerprofiledata">
              <span>Shop Name</span>
              {load ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <h4>{profiledata.ShopName}</h4>
              )}
            </div>
            <div className="jewellerprofiledata">
              <span>Email</span>
              {load ? (
                <Skeleton variant="text" width={150} />
              ) : (
                <h4>{profiledata.Email}</h4>
              )}
            </div>
            <div className="jewellerprofiledata">
              <span>Phone</span>
              {load ? (
                <Skeleton variant="text" width={120} />
              ) : (
                <h4>{profiledata.MobileNumber}</h4>
              )}
            </div>
            <div className="jewellerprofiledata">
              <span>Address</span>
              {load ? (
                <Skeleton variant="text" width={180} />
              ) : (
                <h4>{profiledata.Address}</h4>
              )}
            </div>
            <div className="jewellerprofiledata">
              <span>State</span>
              {load ? (
                <Skeleton variant="text" width={100} />
              ) : (
                <h4>{profiledata?.StateName}</h4>
              )}
            </div>
          </div>
          <div className="myprofileuploaddiv">
            <span>Shop Photos</span>
            {profiledata &&
              profiledata.ShopPhoto &&
              profiledata.ShopPhoto.length > 0 && (
                <>
                  {load ? (
                    <Skeleton variant="circular" width={150} height={150} />
                  ) : (
                    <img
                      src={profiledata && profiledata?.ShopPhoto[0]}
                      alt=""
                    />
                  )}
                </>
              )}
          </div>
          <Button className={"btn2"} onClick={() => setshow(true)}>
            Edit Profile
          </Button>
        </div>
      ) : (
        <JewelerEditprofile
          profiledata={profiledata}
          setshow={setshow}
          onClick={() => setshow(false)}
        />
      )}
    </>
  );
};

export default JewellerProfile;
