import React from 'react'
import './Button.css'
import backarrow from '../../Assets/Icons/leftarrowicon.png'
import { useNavigate } from 'react-router-dom'
import backrightbtn from '../../Assets/Icons/backrightbtn.png'
import downbtn from '../../Assets/Icons/downarrow.png'

const Backbutton = () => {
  const  navigate = useNavigate()
  return (
    <div className='backbtn' onClick={()=> navigate("/")}>
        <img src={backarrow} alt="" />
        <span className='backto'>Back to Home</span>
    </div>
  )
}

export default Backbutton

 export const Backbtn = ({onClick}) => {
  return (
    <div className='backbtn' onClick={onClick}>
        <img src={backrightbtn} alt="" />
        <span className='backto2'>Back</span>
    </div>
  )
}




export const  Back = ({onClick}) => {
  
  return(
    <div className='backbtn' onClick={onClick}>
        <img src={backrightbtn} alt="" />
        <span className='backtooo'>Back</span>
    </div>
  )
}


export const Downbtn = ({onClick, span})=> {
      return(
        <div className='backbtn3' onClick={onClick}>
        <span className='backtoooo'>{span}</span>
        <img src={downbtn} alt="" />
    </div>
      )
}


export const Commonbtn = ({text, span, onClick})=> {
  return(
    <div className="commonbtn">
      <h3>{text}</h3>
      <span onClick={onClick}>{span}</span>
    </div>
  )
}